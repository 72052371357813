import React, { useEffect, useState } from "react";
import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import WalletInfoCard from "./WalletInfoCard";
import { useEmployerStore } from "src/Routing/useProfileStore";
import { useTranslation } from "react-i18next";
import FilterCredits from "src/Pages/Employer/Credits/FilterCredits";
import { useQuery } from "react-query";
import PaginationCredits from "src/Pages/Employer/Credits/PaginationCredits";
import CreditSkeleton from "../ui/skeleton/CreditSkeleton";
import { useAtom } from "jotai";
import { filterActionType } from "src/Atoms/jotaiAtoms";
import { TiCancel } from "react-icons/ti";
import { useClientCredits } from "src/Hooks/useClientCredits";
import { useClientData } from "src/Hooks/useClientData";
import { useNavigate } from "react-router-dom";
export interface Wallet {
  id: string;
  date_created: string;
  credits: string;
  action_id: string;
  action_name: string;
}

const WalletInfo = () => {
  const { t } = useTranslation();
  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const { employerData, refetchEmployer } = useClientData();
  const navigate = useNavigate();

  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [walletInfo, setWalletInfo] = useState<Wallet[]>([]);
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", []);
  const userSubscribed = userInfo?.is_subscribed;

  const [finalData, setFinalData] = useState<Wallet[]>([]);

  const [selectedActionType, setSelectedActionType] = useAtom(filterActionType);
  const { clientCreditsData, refetchClientCredits } = useClientCredits();

  const [pagination, setPagination] = useState({
    totalPages: 1,
    limit: 10,
    currentPage: 1,
    pageNumbers: [],
  });

  useEffect(() => {
    setUserInfo(employerData);
  }, [employerData]);

  useEffect(() => {
    if (pagination.totalPages) {
      setPagination((prev: any) => ({
        ...prev,

        pageNumbers: [...Array(pagination.totalPages + 1).keys()]?.slice(1),
      }));
    }
  }, [pagination.totalPages]);

  const getWalletInfo = async () => {
    const data = await fetch(
      `${BASE_URL}clients.wallet?client_id=${userID}&mode=detail&limit=${
        pagination.limit
      }&page=${pagination.currentPage}${
        selectedActionType.value !== ""
          ? `&action_id=${selectedActionType.value}`
          : ""
      }`,
      {
        headers: {
          Authorization: "Bearer" + loggedInUserUid,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data;
  };

  const { data: walletInfoData, isLoading: isLoadingWalletInfo } = useQuery(
    ["wallet_info", pagination.currentPage, selectedActionType?.value],
    getWalletInfo
  );

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      totalPages: walletInfoData?.paginate.pages,
    }));
    setWalletInfo(walletInfoData?.result);
    setFinalData(walletInfoData?.result);
  }, [walletInfoData]);

  useEffect(() => {
    getWalletInfo();
  }, []);

  useEffect(() => {
    setCreditsNumber(clientCreditsData);
  }, [clientCreditsData]);

  return (
    <div className=" px-4 relative mt-4 lg:mt-0">
      <div className="bg-white rounded-lg border dark:bg-background dark:text-whiteish dark:border-border dark:border ">
        <div
          onClick={() => {
            console.log(userSubscribed);
            console.log(userSubscribed === "0");
          }}
          className="flex flex-col
        "
        >
          <div className="flex items-center justify-between p-8">
            <div className="flex flex-col items-start">
              <p className="text-xl font-semibold mb-4   ">
                {t("employer.wallet_info.title")}
              </p>
              <p className="text-sm">
                <span className="font-medium">{creditsNumber}</span>{" "}
                {t("employer.applicants.view_jobpost.modal.clone.credits")}{" "}
              </p>
            </div>

            {/* <div className="flex items-center">
              {userSubscribed === "0" ? (
                <>
                  <div className="bg-black text-white rounded px-2 py-1 text-[13px] cursor-default dark:bg-neutral-400/30">
                    {t("credits.title.free_plan")}
                  </div>
                  <div
                    className=" rounded px-2 py-1 text-[13px] ml-2 bg-azulBonico text-white  transition cursor-pointer "
                    onClick={() => navigate("/subscriptions")}
                  >
                    {t("employer.wallet_info.ascend_plan")}
                  </div>
                </>
              ) : (
                <div className=" rounded px-2 py-1 text-[13px] ml-2 bg-azulBonico text-white  transition cursor-pointer ">
                  {t("credits.title.pro_plan")}
                </div>
              )}
            </div> */}
          </div>
          <PaginationCredits
            pagination={pagination}
            setPagination={setPagination}
            refetch={getWalletInfo}
          />
          <FilterCredits setFinalData={setFinalData} walletInfo={walletInfo} />
          {isLoadingWalletInfo && (
            <>
              <CreditSkeleton />
              <CreditSkeleton />
            </>
          )}
        </div>

        <>
          <div className="flex items-center justify-between bg-white dark:bg-background dark:border-border py-2   border-t border-b px-8">
            {/* <div className="w-1/4">
              <p className="text-left text-xs">
                {t("credits.header_title.id")}
              </p>
            </div> */}
            <div className="w-1/3">
              <p className="text-left text-xs">
                {t("credits.header_title.date")}
              </p>
            </div>
            <div className="w-1/3 ">
              <p className="text-left text-xs">
                {t("credits.header_title.type")}
              </p>
            </div>
            <div className="w-1/3">
              <p className="text-right text-xs">
                Flexicoins
                {/* {t("credits.header_title.amount")} */}
              </p>
            </div>
          </div>
          {finalData?.length > 0 &&
            finalData?.map((wall) => (
              <WalletInfoCard key={wall.id} wallet={wall} />
            ))}
          {finalData?.length === 0 && (
            <div className="flex flex-col items-center justify-center p-8 text-center bg-white">
              {/* </> */}
              <TiCancel className="h-20 w-20 text-gray-400" />
              {/* <img src={NoCredits} alt="postings" className="mb-4 w-40"></img> */}

              {/* <Button className="mt-12  bg-azulBonico px-6 py-2  text-white hover:bg-azulOscuro"> */}
              <p className="text-sm text-gray-400">
                {t("wallet.no_credits_transactions")}
              </p>
              {/* </Button> */}
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default WalletInfo;
