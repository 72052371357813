import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BASE_URL,
  SUBSCRIPTION_CHECKOUT,
  SUBSCRIPTIONS,
} from "src/Config/api.config";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { SubscriptionData } from "../../types/types";
import { toast } from "react-hot-toast"; // Import toast
import { SubscriptionPackage } from "../../types/types";
import { AiOutlineLoading } from "react-icons/ai";
const SubscriptionPlan = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", []);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const userSubscribed = userInfo?.is_subscribed;
  const [subscriptionData, setSubscriptionData] =
    useState<SubscriptionData | null>(null);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [loading, setLoading] = useState(true); // Loading state
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [subscriptions, setSubscriptions] =
    useState<SubscriptionPackage | null>(null);

  const getSubscriptions = () => {
    fetch(`${BASE_URL}${SUBSCRIPTIONS}?id=${subscriptionData?.package_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setSubscriptions(data.result);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (subscriptionData?.package_id !== undefined) {
      getSubscriptions();
      setIsLoading(false);
    }
  }, [subscriptionData]);

  const handleGetStarted = async () => {
    setIsLoading(true);

    const packageObject = subscriptions && subscriptions;

    if (!packageObject) {
      console.error("No subscription package found.");
      return;
    }

    const packageId = packageObject.id; // Get the package ID

    try {
      const response = await fetch(`${BASE_URL}${SUBSCRIPTION_CHECKOUT}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
        body: JSON.stringify({ package_id: packageId, token: loggedInUserUid }), // Send only the package ID
      });

      if (!response.ok) {
        throw new Error("Failed to start subscription checkout.");
      }

      const data = await response.json();

      // Check if checkout_url is returned in the response
      if (data.checkout_url) {
        // Redirect the user to the Stripe checkout page
        window.location.href = data.checkout_url;
      } else {
        console.error("No checkout URL found.");
      }
    } catch (err) {
      console.error("Error during subscription checkout:", err);
    }
  };

  // Function to retrieve subscription data
  const retrieveSubscription = (id: string) => {
    setLoading(true); // Set loading to true when starting to fetch data
    fetch(`${BASE_URL}/retrieveSubscription?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          if (data.result.data.subscription === null) {
            setSubscriptionData(null); // No subscription found
          } else {
            setSubscriptionData(data.result.data); // Set the subscription data
          }
        } else {
          console.error("Failed to retrieve subscription data");
          toast.error("Failed to retrieve subscription data."); // Show error toast
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        toast.error("An error occurred while fetching subscription data."); // Show error toast
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the data is fetched
      });
  };

  // Retrieve subscription data when userInfo is available
  useEffect(() => {
    if (userInfo?.id) {
      retrieveSubscription(userInfo.id);
    }
  }, [userInfo]);

  return (
    <div className="px-4 py-2">
      <div className="bg-white rounded-lg border pb-6 dark:bg-background dark:text-whiteish dark:border-border dark:border">
        <div className="flex flex-col">
          <div className="flex items-center justify-between p-8">
            <h1 className="text-2xl font-semibold mb-4">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan"
              )}
            </h1>
            <div className="flex items-center">
              {userSubscribed === "0" ? (
                <>
                  <div className="bg-black text-white rounded px-2 py-1 text-[13px] cursor-default dark:bg-neutral-400/30">
                    {t("credits.title.free_plan")}
                  </div>
                  <div
                    className="rounded px-2 py-1 text-[13px] ml-2 bg-azulBonico text-white  transition cursor-pointer "
                    onClick={() => navigate("/subscriptions")}
                  >
                    {t("employer.wallet_info.ascend_plan")}
                  </div>
                </>
              ) : (
                <>
                  <div className=" rounded px-2 py-1 text-[13px] ml-2 bg-azulBonico text-white  transition cursor-default">
                    {t("credits.title.pro_plan")}
                  </div>
                  {isLoading ? (
                    <div
                      onClick={() => handleGetStarted()}
                      className=" rounded px-2 py-1 text-[13px] ml-2 flex gap-2 bg-azulOscuro text-white  transition cursor-pointer "
                    >
                      {t(
                        "worker.subscriptions.pricing.body.rightcard.proplan.title2.subtitle2.alreadySubscribedBtn"
                      )}
                      <AiOutlineLoading className="h-5 w-5 text-white animate-spin" />
                    </div>
                  ) : (
                    <div
                      onClick={() => handleGetStarted()}
                      className=" rounded px-2 py-1 text-[13px] ml-2 flex gap-2 bg-azulOscuro text-white  transition cursor-pointer "
                    >
                      {t(
                        "worker.subscriptions.pricing.body.rightcard.proplan.title2.subtitle2.alreadySubscribedBtn"
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          {/* Show loader until subscription data is fetched */}
          {loading ? (
            <div className="flex justify-center items-center py-4">
              <div className="spinner"></div>{" "}
              {/* Add your loader component here */}
            </div>
          ) : (
            <>
              {/* Subscription information section */}
              {subscriptionData === null ? (
                <div className="px-8 py-4">
                  <h1 className="text-lg font-medium">
                    {t(
                      "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.no_subscription"
                    )}
                  </h1>
                  <p className="text-sm text-gray-500">
                    {t(
                      "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.no_subscription_message"
                    )}
                  </p>
                </div>
              ) : (
                <>
                  <div className="border-b px-8 py-4">
                    <h1 className="font-medium text-sm lg:text-lg">
                      {t(
                        "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_1_Title1"
                      )}
                    </h1>
                    <p className="text-sm text-gray-500">
                      {t("credits.title.pro_plan")}
                    </p>
                  </div>

                  <div className="border-t px-8 py-4">
                    <h1 className="font-medium text-sm lg:text-lg">
                      {t(
                        "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_5_Title1"
                      )}
                    </h1>
                    <p className="text-sm text-gray-500">
                      {subscriptionData?.status}
                    </p>
                  </div>
                  {subscriptionData?.status === "active" ? (
                    <>
                      <div className="border-t px-8 py-4">
                        <h1 className="font-medium text-sm lg:text-lg">
                          {t(
                            "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_5_Title2"
                          )}
                        </h1>
                        <p className="text-sm text-gray-500">
                          {subscriptionData?.next_payment_date}
                        </p>
                        <small className="text-sm text-gray-500">
                          {t(
                            "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_5_message"
                          )}
                        </small>
                      </div>
                    </>
                  ) : (
                    <div className="border-t px-8 py-4">
                      <h1 className="font-medium text-sm lg:text-lg">
                        {t(
                          "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_5_Title3"
                        )}
                      </h1>
                      <p className="text-sm text-gray-500">
                        {subscriptionData?.next_payment_date}
                      </p>
                      <small className="text-sm text-gray-500">
                        {t(
                          "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_6_message"
                        )}
                      </small>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
