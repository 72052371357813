import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as InPerson } from "../../assets/img/in-person.svg";
import { ReactComponent as Edit } from "../../assets/img/edit.svg";
import { ReactComponent as Private } from "../../assets/img/private.svg";

import AvailableDays from "../Time/AvailableDays";
import ModalCloseJob from "../Modals/ModalCloseJob";
import { jobFeatured, jobStatus } from "../../Atoms/jotaiAtoms";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import {
  HoursJobTypes,
  JobInfoTypes,
  JobTypes,
  SkillsTypes,
} from "src/types/types";
import { HandleMonthName, HandleMonthNameES } from "src/Utils/HandleMonthName";
import HandleDayEnding from "src/Utils/HandleDayEnding";
import ModalCloneJob from "../Modals/ModalCloneJob";
import {
  DocumentDuplicateIcon,
  FireIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { cn } from "src/lib/utils";
import { useModalStore } from "src/Hooks/useModalStore";
import ModalEditFeaturedJob from "../Modals/ModalEditFeaturedJob";
import { formatJobTitle } from "src/Utils/formatters";
import { Skeleton } from "../ui/skeleton";
import ModalDeleteCloseJob from "../Modals/ModalDeleteCloseJob";

type BothJobTypes = JobTypes & JobInfoTypes;

interface JobDetailsProps {
  jobData: BothJobTypes;
  loadingTime: boolean;
  allChosenTimes: HoursJobTypes[];
  skills: SkillsTypes[];
  counter: any;
  setActivateConfetti: any;
  isJobLoading: boolean;
  refetchJob: () => void;
}

export default function JobDetails({
  allChosenTimes,
  jobData,
  skills,
  counter,
  isJobLoading,
  refetchJob,
}: JobDetailsProps) {
  const [showCloseModal, setShowCloseModal] = useState(false);

  // const [showCloneModal, setShowCloneModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useModalStore((state) => [
    state.showCloneModal,
    state.setShowCloneModal,
  ]);
  const [showDeleteModal, setShowDeleteModal] = useModalStore((state) => [
    state.showDeleteJob,
    state.setShowDeleteJob,
  ]);

  const [paramsJob, setParamsJob] = useAtom(jobStatus);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [showModalFeatured, setShowModalFeatured] = useModalStore((state) => [
    state.showModalFeatured,
    state.setShowModalFeatured,
  ]);
  const [editFeatureJob, setEditFeatureJob] = useAtom(jobFeatured);
  useEffect(() => {
    if (jobData?.job_status === "Closed") setParamsJob("Closed");
  }, [jobData]);

  return (
    <div>
      <div className="flex flex-wrap mt-8 border ">
        <div className="flex-[0_0_75%] max-w-[75%] bg-white dark:bg-background dark:border dark:border-border rounded dark:text-whiteish">
          <div className="flex flex-col border-b border-blue-100 dark:border-border p-6 px-8 text-sm">
            {isJobLoading ? (
              <Skeleton className="h-4 w-32 mt-4 mb-8" />
            ) : (
              <p className="text-2xl mt-4  font-semibold mb-8">
                {jobData?.data
                  ? formatJobTitle(jobData?.data[0]?.title)
                  : formatJobTitle(jobData?.title)}
              </p>
            )}
            <p className="text-azulBonico font-semibold cursor-pointer hover:underline hover:text-azulOscuro">
              {jobData?.category_name}
            </p>
            <div className="flex flex-col gap-2 my-4">
              <p>
                {t("employer.job_details.posted_on")}{" "}
                {i18n.language === "en" ? (
                  <>
                    {HandleMonthName(jobData?.date_created?.slice(5, 7))}{" "}
                    {jobData?.date_created?.slice(8)}
                    {HandleDayEnding(jobData?.date_created?.slice(8))}
                  </>
                ) : (
                  <>
                    {jobData?.date_created?.slice(8)} de{" "}
                    {HandleMonthNameES(jobData?.date_created?.slice(5, 7))}
                  </>
                )}
              </p>
              <p>
                {t("employer.job_details.expires_on")}{" "}
                {i18n.language === "en" ? (
                  <>
                    {HandleMonthName(jobData?.expire_date?.slice(5, 7))}{" "}
                    {jobData?.expire_date?.slice(8)}
                    {HandleDayEnding(jobData?.expire_date?.slice(8))}
                  </>
                ) : (
                  <>
                    {jobData?.expire_date?.slice(8)} de{" "}
                    {HandleMonthNameES(jobData?.expire_date?.slice(5, 7))}
                  </>
                )}
              </p>
            </div>
            <div className="flex items-end justify-between">
              <div className="flex flex-col items-start ">
                <p className="text-gray-500">
                  {t("worker.job_search.sort.budget")}
                </p>
                <p>
                  <span className="font-medium">{jobData?.budget}€</span> /h
                </p>
              </div>
              {jobData?.job_type === "1" && (
                <div className="flex items-center">
                  <InPerson fill={"#2abeeb"} className="h-3 mr-1" />
                  <p>{jobData?.location !== null && ` ${jobData?.location}`}</p>
                </div>
              )}
            </div>
          </div>
          <div className="flex border-b border-blue-100 dark:border-border p-6 px-8 text-sm">
            <p className=" text-justify text-gray-500 ">
              {jobData?.details ?? "No description"}
            </p>
          </div>
          {allChosenTimes?.length > 0 && (
            <div className=" border-b border-blue-100 dark:border-border p-6 px-8 text-sm">
              <AvailableDays allChosenTimes={allChosenTimes} />
            </div>
          )}

          <div className="flex flex-col border-b border-blue-100 dark:border-border p-4 px-8 text-sm">
            <p className="text-lg font-medium mb-4">
              {t("employer.job_details.skills_expertise")}
            </p>
            <div className="flex justify-between">
              <div className="flex-[0_0_65%] max-w-[65%] flex-col">
                <p>
                  {t("employer.job_details.skills_related")}{" "}
                  <span className="text-azulBonico">
                    {jobData?.category_name}
                  </span>
                  &nbsp;{t("employer.job_details.category")}
                </p>
                <div className="flex flex-wrap text-xs overflow-auto w-[22rem] my-4 mt-6">
                  {skills?.length > 0 ||
                    (skills?.[0].skill_id !== null &&
                      skills?.map((skill) => {
                        return (
                          <div key={skill.skill_id}>
                            <button className="px-2 text-xs cursor-default py-0.5 mx-0.5 text-black bg-blancoGrisClaro my-0.5 flex items-center border rounded-xl  hover:bg-[#bdbdbd] ">
                              {skill.skill_name}
                            </button>
                          </div>
                        );
                      }))}
                  {skills?.length === 0 ||
                    (skills?.[0].skill_id === null && (
                      <p className="text-sm text-gray-500">
                        {t("skills.no_skills.empty_container")}
                      </p>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col border-b border-blue-100 dark:border-border p-8 pt-4 px-8 text-sm">
            <p className="text-lg font-medium mb-4">
              {t("employer.job_details.activity_job")}
            </p>
            <p className="mt-2">
              <span className="text-slate-500 ">
                {t("employer.job_details.workers_needed")}:
              </span>{" "}
              {jobData?.workers_needed}
            </p>
            <p className="mt-2">
              <span className="text-slate-500 ">
                {t("employer.job_details.hired")}:
              </span>{" "}
              {counter.hired !== "" ? counter.hired : "0"}
            </p>
            <p className="mt-2">
              <span className="text-slate-500 ">
                {t("employer.job_details.applicants")}:
              </span>{" "}
              {counter.applicants??"0"}
            </p>
          </div>
        </div>
        <div className="flex-[0_0_25%] max-w-[25%] border rounded bg-white dark:bg-background dark:border-border  border-blue-100 ">
          <div className="flex flex-col border-b border-blue-100 dark:border-border text-sm py-4 text-azulBonico gap-3">
            {paramsJob !== "Closed" && (
              <>
                <div
                  className="flex items-center px-8  cursor-pointer hover:text-azulOscuro hover:underline"
                  onClick={() => navigate(`/job-post-edit/${jobData?.job_id}`)}
                >
                  <Edit fill={"#2abeeb"} className="h-3 mr-4" />
                  <p>{t("employer.job_details.edit_posting")}</p>
                </div>
              </>
            )}
            {(jobData?.job_status === "Closed" || paramsJob === "Closed") && (
              <div
                className={cn(
                  `flex items-center px-[28px] cursor-pointer hover:text-azulOscuro hover:underline transition space-x-[13px] `,
                  showCloneModal && "opacity-100 scale-110"
                )}
                onClick={() => setShowCloneModal(showCloneModal)}
              >
                <DocumentDuplicateIcon className="h-4 " />
                <p>{t("employer.job_details.clone_job")}</p>
              </div>
            )}

            {(jobData?.job_status === "Closed" || paramsJob === "Closed") && (
              <div
                className={cn(
                  `flex items-center px-[28px] cursor-pointer text-gray-400 hover:underline transition space-x-[13px] `
                )}
                onClick={() => setShowDeleteModal(showDeleteModal)}
              >
                <TrashIcon className="h-4 text-red-500" />
                <p className="text-red-500">
                  {t("employer.job_details.delete_job")}
                </p>
              </div>
            )}

            {paramsJob !== "Closed" && (
              <div
                className={cn(
                  `flex items-center px-[28px] cursor-pointer hover:text-azulOscuro hover:underline`
                )}
                onClick={() => setShowCloseModal(true)}
              >
                <Private fill={"#2abeeb"} className="h-5 mr-[13px] w-[20px]" />
                <p>{t("employer.job_details.close_job")}</p>
              </div>
            )}
          </div>
          {jobData?.featured === "0" && (
            <button
              onClick={() => setShowModalFeatured(showModalFeatured)}
              className="w-fit bg-azulOscuro px-10 flex text-white p-1 py-2 rounded-full ml-8 items-center space-x-2 justify-center  my-4 "
            >
              <FireIcon className={cn("fill-gray-300 h-5 w-5")} />
              <p className="text-sm">
                {t("employer.applicants.job_post.featured_modal.not_featured")}
              </p>
            </button>
          )}
          {jobData?.featured === "1" && (
            <button className="w-fit flex px-10 bg-azulOscuro text-white p-1 py-2 rounded-full ml-8 items-center space-x-2 justify-center  my-4">
              <FireIcon className={cn("fill-orange-500  h-5 w-5")} />{" "}
              <p className="text-sm">
                {t("employer.applicants.job_post.featured_modal.featured")}
              </p>
            </button>
          )}
        </div>
      </div>
      {showCloseModal && (
        <ModalCloseJob
          job={jobData?.data ? jobData?.data[0] : jobData}
          setShowModal={setShowCloseModal}
          showModal={showCloseModal}
        />
      )}
      {showCloneModal && (
        <ModalCloneJob
          job={jobData?.data ? jobData?.data[0] : jobData}
          showModal={showCloneModal}
        />
      )}

      {showModalFeatured && (
        <ModalEditFeaturedJob
          refetchJob={refetchJob}
          jobData={jobData}
          skills={skills}
          allChosenTimes={allChosenTimes}
        />
      )}

      {showDeleteModal && (
        <ModalDeleteCloseJob job={jobData?.data ? jobData?.data[0] : jobData} />
      )}
    </div>
  );
}
