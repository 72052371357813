import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "./UseLocalStorage";
import { useQuery } from "react-query";

export const useWorkerData = () => {
  const [userID, setUserId] = useLocalStorage("IDUser", "");

  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const getClientData = async () => {
    const data = await fetch(`${BASE_URL}workers?client_id=${userID}`, {
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
    }).then((res) => res.json());

    return data.result.data[0];
  };

  const { data: workerData, refetch: refetchWorker } = useQuery(
    ["worker", userID],
    getClientData
  );

  return { workerData, refetchWorker };
};
