import React, { useState } from "react";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { useNavigate } from "react-router-dom";

import { AiOutlineArrowDown as ArrowDown } from "react-icons/ai";

import { ReactComponent as Question } from "../../assets/img/question-solid.svg";
import { ReactComponent as Notification } from "../../assets/img/bell-blank.svg";
import { ReactComponent as Settings } from "../../assets/img/settings.svg";
import { ReactComponent as Logout } from "../../assets/img/logout.svg";
import { BASE_URL, BASE_URL_IMG } from "../../Config/api.config";
import { useAtom } from "jotai";
import {
  jobOfferAtom,
  savedSearchesData,
  selectedTabHeaderEmployer,
  selectedTabHeaderWorker,
} from "../../Atoms/jotaiAtoms";
import { useTranslation } from "react-i18next";
import { userProfileStore } from "src/Routing/useProfileStore";

interface NavBarBurgerProps {
  setOnlineStatusOff: any;
  setOpen: any;
}

const NavBarBurger = ({ setOnlineStatusOff, setOpen }: NavBarBurgerProps) => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [showFindWork, setShowFindWork] = useState(false);
  const [showMyJobs, setShowMyJobs] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [headerWorker, setHeaderWorker] = useAtom(selectedTabHeaderWorker);
  const [savedSearches, setSavedSearches] = useAtom(savedSearchesData);
  const [headerEmployer, setHeaderEmployer] = useAtom(
    selectedTabHeaderEmployer
  );
  const [jobOffers, setJobOffers] = useAtom(jobOfferAtom);
  const setProfileTypesLocal = userProfileStore((s) => s.setProfile);
  const profileType = userProfileStore((s) => s.profileTypeToken);

  const navigate = useNavigate();

  const data = new FormData();
  data.append("id", userID);

  const logoutUser = () => {
    fetch(`${BASE_URL}logout`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then(() => handleLogout())
      .catch((err) => console.log(err));
  };

  const resetAtoms = () => {
    if (profileType === "worker") {
      setHeaderWorker("dashboard");
      setSavedSearches([]);
      setJobOffers([]);
    } else if (profileType === "employer") {
      setJobOffers([]);
      setHeaderEmployer("dashboard");
    }
  };

  const handleLogout = () => {
    // localStorage.clear();
    resetAtoms();
    setLoggedInUserUid("");
    setUserId("");
    navigate("/login");
    setProfileTypesLocal(null);
    // setTimeout(() => {
    //   navigate("/login");
    // }, 100);
  };

  return (
    <div className="absolute top-[80px] left-0  w-full h-screen bg-white px-8 z-40">
      {/* <div className="border-t-2 border-blue-100"> */}
      <div className="flex gap-1 border-y-2 border-blue-100 px-2 py-6 items-center">
        {userInfo?.profile_image ? (
          <img
            src={`${BASE_URL_IMG}${userInfo?.profile_image}`}
            alt="profile"
            className="h-9  w-9 object-cover rounded-full cursor-default mr-2"
          ></img>
        ) : (
          <p className="border font-bold rounded-full border-azulBonico text-azulBonico h-[44px] w-[44px] p-1 flex mr-2 justify-center items-center">
            {userInfo.first_name?.slice(0, 1)}
            {userInfo?.last_name?.slice(0, 1)}
          </p>
        )}
        <div className="flex flex-col ">
          <div className="flex gap-1 font-bold">
            <p>{userInfo.first_name}</p>
            <p>{userInfo?.last_name}</p>
          </div>
          <p className="text-xs">
            {profileType === "worker" ? "Worker" : "Employer"}
          </p>
        </div>
      </div>
      <div className="flex flex-col text-sm tracking-wide">
        <div className="flex flex-col border-b border-blue-100">
          {profileType === "worker" ? (
            <>
              <div
                className="flex justify-between font-bold items-center  py-4 px-2 cursor-pointer hover:text-azulBonico"
                onClick={() => setShowFindWork(!showFindWork)}
              >
                <p>{t("worker.leftbar.menu.item1.subitem1.title")}</p>

                <ArrowDown
                  className={`h-3 fill-black ${
                    showFindWork
                      ? "animate-toArrowUp rotate-180"
                      : "animate-toArrowDown"
                  } `}
                />
              </div>
              {showFindWork && (
                <div className=" flex flex-col gap-2 font-bold pb-4">
                  <p
                    className="hover:bg-[#f7f4f4] px-4 py-1 cursor-pointer"
                    onClick={() => {
                      setOpen(false);
                      navigate("/dashboard-worker");
                    }}
                  >
                    {t("worker.leftbar.menu.item1.subitem1.title")}
                  </p>
                  <p
                    className="hover:bg-[#f7f4f4] px-4 py-1 cursor-pointer"
                    onClick={() => {
                      setOpen(false);
                      navigate("/jobs-search");
                    }}
                  >
                    {t("worker.leftbar.menu.item1.subitem2.title")}
                  </p>

                  <p
                    className="hover:bg-[#f7f4f4] px-4 py-1 cursor-pointer"
                    onClick={() => navigate("/profile")}
                  >
                    {t("worker.leftbar.menu.item1.subitem3.title")}
                  </p>
                </div>
              )}
              <div className="flex flex-col border-t border-blue-100">
                <div
                  className="flex justify-between font-bold items-center  py-4 px-2 cursor-pointer hover:text-azulBonico"
                  onClick={() => setShowMyJobs(!showMyJobs)}
                >
                  <p>{t("worker.leftbar.menu.item2.subitem1.title")}</p>

                  <ArrowDown
                    className={`h-3 fill-black ${
                      showMyJobs
                        ? "animate-toArrowUp rotate-180"
                        : "animate-toArrowDown"
                    } `}
                  />
                </div>
                {showMyJobs && (
                  <div className=" flex flex-col gap-2 font-bold pb-4">
                    <p
                      className="hover:bg-[#f7f4f4] px-4 py-1 cursor-pointer"
                      onClick={() => {
                        if (profileType === "worker") {
                          setOpen(false);

                          navigate("/my-jobs");
                        }
                      }}
                    >
                      {t("worker.leftbar.menu.item2.subitem1.title")}
                    </p>
                    <p
                      className="hover:bg-[#f7f4f4] px-4 py-1 cursor-pointer"
                      onClick={() => {
                        setOpen(false);
                        navigate("/applied-jobs");
                      }}
                    >
                      {t("worker.leftbar.menu.item2.title")}
                    </p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div
                className="flex justify-between font-bold items-center  py-4 px-2 cursor-pointer hover:text-azulBonico"
                onClick={() => setShowFindWork(!showFindWork)}
              >
                <p>Jobs</p>
                <ArrowDown
                  className={`h-3 fill-black ${
                    showFindWork
                      ? "animate-toArrowUp rotate-180"
                      : "animate-toArrowDown"
                  } `}
                />
              </div>
              {showFindWork && (
                <>
                  <div className=" flex flex-col gap-2 font-bold pb-4">
                    <p
                      className="hover:bg-[#f7f4f4] px-4 py-1 cursor-pointer"
                      onClick={() => {
                        if (profileType === "employer") {
                          setOpen(false);

                          navigate("/dashboard");
                        } else {
                          setOpen(false);

                          navigate("/dashboard-worker");
                        }
                      }}
                    >
                      {t("worker.leftbar.menu.item2.subitem1.title")}
                    </p>
                    <p
                      className="hover:bg-[#f7f4f4] px-4 py-1 cursor-pointer"
                      onClick={() => navigate("/all-jobs")}
                    >
                      {t("worker.headeer.all_job_posts")}
                    </p>
                    {/* <p
                    className="hover:bg-[#f7f4f4] px-4 py-1 cursor-pointer"
                    // onClick={() => navigate("/applied-jobs")}
                  >
                    All Contracts
                  </p> */}
                    <p
                      className="hover:bg-[#f7f4f4] px-4 py-1 cursor-pointer"
                      onClick={() => {
                        setOpen(false);
                        navigate("/welcome-client");
                      }}
                    >
                      {t("employer.allJobs.post_a_jobs")}
                    </p>
                    {/* <p className="hover:bg-[#f7f4f4] px-4 py-1 cursor-pointer">
                    My Stats
                  </p> */}
                  </div>
                  <div className="flex flex-col border-b border-blue-100">
                    <div
                      className="flex justify-between font-bold items-center  py-4 px-2 cursor-pointer hover:text-azulBonico"
                      onClick={() => setShowMyJobs(!showMyJobs)}
                    >
                      <p>{t("employer.alljobs.HiresMessagesIcon")}</p>

                      {/* <ArrowDown
                            className={`h-3 fill-black ${
                              showMyJobs
                                ? "animate-toArrowUp rotate-180"
                                : "animate-toArrowDown"
                            } `}
                          /> */}
                    </div>
                    {/* {showMyJobs && (
                          <div className=" flex flex-col gap-2 font-bold pb-4">
                            <p
                              className="hover:bg-[#f7f4f4] px-4 py-1 cursor-pointer"
                              onClick={() => navigate("/talent-search")}
                            >
                              Discover
                            </p>
                            <p
                              className="hover:bg-[#f7f4f4] px-4 py-1 cursor-pointer"
                              onClick={() => navigate("/my-hires")}
                            >
                              Your hires
                            </p>
                            <p className="hover:bg-[#f7f4f4] px-4 py-1 cursor-pointer">
                              Company hires
                            </p>
                            <p className="hover:bg-[#f7f4f4] px-4 py-1 cursor-pointer">
                              Saved Talent
                            </p>
                          </div>
                        )} */}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div
          className="flex justify-between font-bold items-center border-b border-blue-100 py-4 px-2 cursor-pointer hover:text-azulBonico"
          onClick={() => {
            if (profileType === "worker") {
              setOpen(false);

              navigate("/my-jobs");
            } else {
              setOpen(false);

              navigate("/my-hires");
            }
          }}
        >
          <p className="text-[14px]">
            {t("employer.leftbar.menu.item2.title")}
          </p>
        </div>
        {/* <div
          className="flex justify-between font-bold items-center border-b border-blue-100 py-4 px-2 cursor-pointer hover:text-azulBonico"
          onClick={() => {
            if (profileType === "worker") {
              navigate("/find-work");
              setOpen(false);
            } else {
              setOpen(false);

              navigate("/find-workers");
            }
          }}
        >
          {profileType !== "worker" ? (
            <p className="text-[14px]">
              {t("employer.leftbar.menu.item8.Findworkers.title")}
            </p>
          ) : (
            <p className="text-[14px]">
              {t("worker.leftbar.menu.findwork.item7.title")}
            </p>
          )}
        </div> */}

        <div
          className="flex justify-between font-bold items-center border-b border-blue-100 py-4 px-2 cursor-pointer hover:text-azulBonico"
          onClick={() => {
            if (profileType === "worker") {
              setOpen(false);

              navigate("/messages");
            } else {
              setOpen(false);

              navigate("/messages-em");
            }
          }}
        >
          <p>{t("employer.leftbar.menu.item3.title")}</p>
        </div>
        <div onClick={()=>navigate("/subscriptions")} className="flex justify-between font-bold items-center border-b border-blue-100 py-4 px-2 cursor-pointer hover:text-azulBonico">
          <p>{t("employer.leftbar.menu.item6.title")}</p>
        </div>

        <div
          onClick={() => {
            setOpen(false);
            navigate("/contact");
          }}
          className="flex gap-5 font-bold items-center border-b border-blue-100 py-4 px-2 cursor-pointer hover:text-azulBonico"
        >
          <Question className="h-4 w-4 fill-black" />
          <p>{t("employer.leftbar.usermenu.link1")}</p>
        </div>
        <div
          className="flex gap-5 font-bold items-center border-b border-blue-100 py-4 px-2 cursor-pointer hover:text-azulBonico"
          onClick={() => {
            setOpen(false);
            navigate("/notifications");
          }}
        >
          <Notification className="h-4 w-4 fill-black" />
          <p>{t("employer.notifications.title")}</p>
        </div>
        <div
          className="flex gap-5 font-bold items-center border-b border-blue-100 py-4 px-2 cursor-pointer hover:text-azulBonico"
          onClick={() => {
            setOpen(false);
            navigate("/settings");
          }}
        >
          <Settings className="h-4 w-4 fill-black" />
          <p>{t("employer.leftbar.usermenu.link2")}</p>
        </div>
        <div
          className="flex gap-5 font-bold items-center border-b border-blue-100 py-4 px-2 cursor-pointer hover:text-azulBonico"
          onClick={() => {
            setOnlineStatusOff();
            logoutUser();
            // window.localStorage.clear();
          }}
        >
          <Logout className="h-4 w-4 fill-black" />
          <p>{t("employer.leftbar.usermenu.link3")}</p>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default NavBarBurger;
