import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ProgressRegistration from "../../../Components/Worker/Registration/ProgressRegistration";
import ModalAddEmployment from "../../../Components/Modals/ModalAddEmployment";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import ModalDelete from "../../../Components/InitialClientForm/ModalDelete";
import ModalEditEmployment from "../../../Components/Modals/ModalEditEmployment";
import { BASE_URL } from "../../../Config/api.config";

import { ReactComponent as Plus } from "../../../assets/img/plus.svg";
import edit from "../../../assets/img/edit.png";
import trash from "../../../assets/img/trash-can.png";
import talent from "../../../assets/img/talent-proposal.png";
import RegistrationHeader from "../../../Components/Worker/Registration/Header/RegistrationHeader";
import { useTranslation } from "react-i18next";
import { ExperienceTypes } from "src/types/types";
import { useAllCountries } from "src/Hooks/useCountries";

const options = [
  {
    value: "January",
    label: "January",
    id: "01",
  },
  {
    value: "February",
    label: "February",
    id: "02",
  },
  {
    value: "March",
    label: "March",
    id: "03",
  },
  {
    value: "April",
    label: "April",
    id: "04",
  },
  {
    value: "May",
    label: "May",
    id: "05",
  },
  {
    value: "June",
    label: "June",
    id: "06",
  },
  {
    value: "July",
    label: "July",
    id: "07",
  },
  {
    value: "August",
    label: "August",
    id: "08",
  },
  {
    value: "September",
    label: "September",
    id: "09",
  },
  {
    value: "October",
    label: "October",
    id: "10",
  },
  {
    value: "November",
    label: "November",
    id: "11",
  },
  {
    value: "December",
    label: "December",
    id: "12",
  },
];
function AddEmployment() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [allEmployments, setAllEmployments] = useState([]);
  const [job, setJob] = useLocalStorage("dataJobEdit", []);
  const [countries, setCountries] = useState([]);
  const [checked, setChecked] = useState(false);
  const { countries: countriesData, refetchCountries } = useAllCountries();

  const navigate = useNavigate();

  useEffect(() => {
    setCountries(countriesData);
  }, [countriesData]);
  useEffect(() => {
    if (countries?.length < 1) {
      refetchCountries();
    }
  }, []);
  useEffect(() => {
    getAllExperiences();
  }, []);
  const getAllExperiences = () => {
    fetch(`${BASE_URL}worker.experience?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllEmployments(data.result))
      .catch((err) => console.log(err));
  };

  const handleChange = () => {
    setChecked(!checked);
  };
  const handleEdit = (job: { id: string }) => {
    fetch(`${BASE_URL}worker.experience?id=${job.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setJob(data.result[0]))
      .catch((err) => console.log(err));
  };
  const onNextClick = () => {
    navigate("/create-profile/education");
  };
  const removeExperience = () => {
    setAllEmployments(
      allEmployments?.filter((items: { id: string }) => items.id !== job.id)
    );
  };

  return (
    <div>
      <div className=" w-full !bg-white relative h-full">
        <RegistrationHeader />
        <div className="pt-12 mx-auto  place-items-center px-10 lg:px-0 flex flex-col min-h-[75vh] ">
          <div className="text-xl lg:text-4xl font-bold ">
            {" "}
            {t("createprofile.employment.title")}
            {/* If you have relevant work experience, add it here. */}
          </div>
          <p className="pt-8  lg:max-w-2xl text-sm text-[#6e7081]">
            {t("createprofile.employment.subtitle")}
            {/* Jobseekers who add their experience are twice as likely to find a
            job. But if you&apos;re just starting out, you can still create a
            great profile. Just head onto the next page. */}
          </p>{" "}
          {allEmployments?.length === 0 ? (
            <>
              <div className="flex flex-col items-start w-full max-w-2xl">
                <div className="w-[90%] lg:w-[377px] h-[276px] border-2  rounded-xl justify-start items-start flex flex-col border-dashed  border-azulBonico mt-16">
                  <Plus
                    fill={"white"}
                    className="h-14 border rounded-full ml-8 p-5 bg-azulBonico justify-center mt-8 hover:bg-azulOscuro cursor-pointer"
                    onClick={() => setShowModal(true)}
                  />
                  <p className="mt-5 font-bold text-2xl tracking-wider ml-8 text-[#6e7081]">
                    {t("createprofile.work_experience.body.box.in")}
                  </p>
                </div>
                <label className=" mt-10 text-sm text-[#6e7081] cursor-pointer p-1  hover:bg-[#ededee]">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                    className="mr-4"
                  />{" "}
                  {t("createprofile.education.body.box.subtitle")}
                  {/* Nothing to add? Check the box and keep going */}
                </label>
              </div>
            </>
          ) : (
            <>
              <Plus
                fill={"#6e7081"}
                className="h-12 border rounded-full p-4 justify-center mt-8 hover:bg-[#cfcfcf66] cursor-pointer "
                onClick={() => setShowModal(true)}
              />
              <div className="w-full flex items-center flex-wrap justify-center max-h-[620px] overflow-auto  max-w-[1550px]">
                {allEmployments?.map((job: ExperienceTypes) => {
                  return (
                    <div
                      className="flex max-w-[390px] w-[90%] lg:min-w-[390px] mx-8 mt-8 h-[278px] rounded-lg border justify-between border-blue-100 p-4 gap-4"
                      key={job.company_name}
                    >
                      <img
                        src={talent}
                        alt="talent"
                        className="h-10 items-center flex-[0_0_15%] max-w-[15%] mt-2 object-contain"
                      />
                      <div className="flex flex-col pl-1 flex-[0_0_60%] max-w-[60%]">
                        <p className="font-bold text-xl mb-2">
                          {job.job_title}
                        </p>
                        <p className="text-sm">{job.company_name}</p>
                        <p className="text-[#6e7081] text-sm">
                          {options?.map((month) => {
                            return month.id === job.start_date.substring(5, 7)
                              ? month.label
                              : "";
                          })}
                          &nbsp;
                          {job.start_date.substring(0, 4)} -&nbsp;
                          {(!job.end_date || job.end_date === "0000-00-00") &&
                            "Present"}
                          {job.end_date &&
                            options?.map((month) => {
                              return month.id === job?.end_date.substring(5, 7)
                                ? `${month.label} ${job.end_date.substring(
                                    0,
                                    4
                                  )}`
                                : "";
                            })}
                        </p>
                        <p className="text-[#6e7081] text-sm">
                          {job.city}
                          {countries?.length > 0 &&
                            countries?.map(
                              (country: {
                                country_code: string;
                                name: string;
                              }) => {
                                return country.country_code === job.country_code
                                  ? `, ${country.name}`
                                  : "";
                              }
                            )}
                        </p>
                        <p className="text-sm pt-2 overflow-hidden">
                          {job.description}
                        </p>
                      </div>
                      <div className="flex flex-col items-center gap-3 mt-2 flex-[0_0_15%] max-w-[15%]">
                        <img
                          src={edit}
                          alt="edit"
                          className="h-3 cursor-pointer"
                          onClick={() => {
                            handleEdit(job);
                            setTimeout(() => {
                              setShowEditModal(true);
                            }, 150);
                          }}
                        />
                        <img
                          src={trash}
                          alt="trash"
                          className="h-5 cursor-pointer"
                          onClick={() => {
                            setJob(job);
                            setShowDeleteModal(true);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        {showEditModal === true && (
          <ModalEditEmployment
            setShowEditModal={setShowEditModal}
            showEditJobModal={showEditModal}
            refetch={getAllExperiences}
          />
        )}
        {showDeleteModal === true && (
          <ModalDelete
            job={job}
            setShowModal={setShowDeleteModal}
            showModal={showDeleteModal}
            title={"Work Experience"}
            titleJob={job?.title}
            // handleNoMoreLocal={handleNoMoreLocal}
            handleRemove={removeExperience}
          />
        )}
        {showModal === true && (
          <ModalAddEmployment
            setShowModal={setShowModal}
            showAddJobModal={showModal}
            refetch={getAllExperiences}
          />
        )}
        <ProgressRegistration
          disabled={
            checked === false && allEmployments?.length === 0 ? true : false
          }
          backButton={"create-profile/title"}
          nextButtonText={t("createprofile.employment.rightBtn")}
          onNextClick={onNextClick}
          progress="24"
        />
      </div>
    </div>
  );
}

export default AddEmployment;
