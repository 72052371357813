import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { ReactComponent as Circle } from "../../../assets/img/circle.svg";
import { ReactComponent as Warning } from "../../../assets/img/warning.svg";
import { ReactComponent as Cross } from "../../../assets/img/cross-white.svg";
import { selectedApplicantsSection } from "../../../Atoms/jotaiAtoms";

import edit from "../../../assets/img/edit.png";
import "react-calendar/dist/Calendar.css";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { useNavigate } from "react-router-dom";
import { BASE_URL, BASE_URL_IMG } from "../../../Config/api.config";
import exclamation from "../../../assets/img/exclamation.png";
import { t } from "i18next";
import { useModalStore } from "src/Hooks/useModalStore";
import ModalSendOffer from "./ModalSendOffer";

const SendAnOffer = () => {
  const [applicant, setApplicant] = useLocalStorage("dataOfferedApplicant", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [jobData, setJobData] = useLocalStorage("jobData", "");
  const [jobID, setJobId] = useLocalStorage("jobID", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [selectedSection, setSelectedSection] = useAtom(
    selectedApplicantsSection
  );
  const [jobDate, setJobDate] = useState({ start: "", end: "" });
  const [warning, setWarning] = useState({
    date: false,
    terms: false,
    invalidDates: false,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModalPayOffer, setShowModalPayOffer] = useModalStore((state) => [
    state.showPayForOffer,
    state.setShowPayForOffer,
  ]);

  const navigate = useNavigate();

  const curr = new Date();
  curr.setDate(curr.getDate());
  const date = curr.toISOString().substring(0, 10);

  const offerData = new FormData();
  offerData.append("_method", "POST");
  offerData.append("client_id", userID);
  offerData.append("job_id", jobID);
  offerData.append("worker_id", applicant?.client_id);
  offerData.append("hire_status_id", "1");
  offerData.append("date_start", jobDate?.start);
  offerData.append("date_end", jobDate?.end);

  const sendOffer = async () => {
    const response = await fetch(`${BASE_URL}employer.hired.applicants`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: offerData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setSelectedSection("offers");
          setLoading(false);
          navigate("/applicants/offers");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleContinue = () => {
    if (
      termsAccepted &&
      jobDate?.start &&
      jobDate?.end &&
      !warning.invalidDates
    ) {
      setLoading(true);
      setShowModalPayOffer(showModalPayOffer);
      // sendOffer();
    } else {
      if (!termsAccepted) {
        setWarning((prev) => ({ ...prev, terms: true }));
      }
      if (!jobDate.start || !jobDate.end) {
        setWarning((prev) => ({ ...prev, date: true }));
      }
      // setWarning(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3500);
    }
  };
  useEffect(() => {
    if (termsAccepted) {
      setWarning((prev) => ({ ...prev, terms: false }));
    }
  }, [termsAccepted]);
  useEffect(() => {
    if (jobDate.end && jobDate.start) {
      setWarning((prev) => ({ ...prev, date: false }));
      if (jobDate.end < jobDate.start) {
        setWarning((prev) => ({ ...prev, invalidDates: true }));
      } else {
        setWarning((prev) => ({ ...prev, invalidDates: false }));
      }
    }
  }, [jobDate]);
  return (
    <div>
      <>
        <h2 className="text-2xl mt-8 px-8 font-font-bold mb-8">
          {t("employer.send_offer.title")}
        </h2>
        <div
          className={`flex justify-center transition-opacity duration-700 ${
            !showAlert ? "opacity-0 " : "opacity-100 "
          }`}
        >
          <div className="bg-[#9b211b] text-white font-bold text-sm rounded-lg py-3.5 tracking-wide px-6 mt-6 fixed items-start border">
            <div className="flex justify-between gap-[78px] items-center">
              <div className="flex gap-4 items-center">
                <Warning className="h-4 fill-white" />
                <p>{t("employer.fix_errors")}</p>
              </div>
              <Cross
                className="h-3 fill-white w-fit cursor-pointer"
                onClick={() => setShowAlert(false)}
              />
            </div>
          </div>
        </div>
        <div className="p-6 px-8  flex gap-3 bg-white border rounded-lg items-start">
          <div className="relative">
            <img
              src={`${BASE_URL_IMG}${applicant?.profile_image}`}
              alt="img"
              className="h-16  w-16 object-cover rounded-full"
            />
            <Circle
              fill={`${
                applicant?.online_status === "1" ? "#14A800" : "#94b8a3"
              }`}
              className="h-4 absolute bottom-0 right-0 border-[2px] border-white rounded-full"
            />
          </div>
          <div className="flex flex-col">
            <p className="text-azulBonico font-bold text-xl mb-2">
              {applicant.first_name} {applicant.last_name}
            </p>
            <p className="text-sm font-bold">{applicant.title}</p>
            <p className="text-sm text-slate-400 font-normal">
              {applicant.city_name}, {applicant.country_code_name}
            </p>
          </div>
        </div>
        <div className="p-6 px-8  flex gap-3 bg-white border rounded-lg items-start mt-6">
          <div className="flex flex-col">
            <p className="font-bold text-xl">
              {t("employer.send_offer.contract_terms")}
            </p>
            <div className="text-sm mt-8">
              <p>
                {t("employer.send_offer.protected_by")}{" "}
                <span className="text-azulBonico font-bold cursor-pointer hover:underline hover:text-azulOscuro">
                  {t("employer.send_offer.flexihours_payment_protection")}
                </span>{" "}
                {t("employer.send_offer.pay_for_the_work")}
              </p>

              <div className="mt-6 flex flex-col gap-1">
                <p className="font-bold">{t("employer.send_offer.salary")}</p>
                <div className="flex items-center gap-5">
                  <p>{jobData.data[0].budget} EUR / h</p>
                  <img
                    src={edit}
                    alt="edit"
                    className={"shown h-3 cursor-pointer"}
                  ></img>{" "}
                </div>
              </div>
              <div className="mt-6 flex items-center gap-8">
                <div className="flex flex-col gap-1">
                  <p className="font-bold">
                    {t("employer.send_offer.start_date")}
                  </p>
                  <input
                    type="date"
                    className="border-2 border-slate-200 w-fit px-4 py-1 rounded-lg text-sm text-slate-400 cursor-pointer outline-none"
                    min={date}
                    onChange={(e) => {
                      setJobDate((prev: any) => ({
                        ...prev,
                        start: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className=" flex flex-col gap-1">
                  <p className="font-bold">
                    {t("employer.send_offer.end_date")}
                  </p>
                  <input
                    type="date"
                    min={date}
                    className="border-2 border-slate-200 w-fit px-4 py-1 rounded-lg text-sm text-slate-400 cursor-pointer outline-none"
                    onChange={(e) =>
                      setJobDate((prev: any) => ({
                        ...prev,
                        end: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className={`mt-4 ${!warning.date && "hidden"}`}>
                <div className="flex items-center">
                  <img className="exclamation" src={exclamation} alt="hey" />
                  <p className="text-sm text-[#9b211b] font-bold">
                    {t("employer.send_offer.specify_date")}
                  </p>
                </div>
              </div>
              <div className={`mt-4 ${!warning.invalidDates && "hidden"}`}>
                <div className="flex items-center">
                  <img className="exclamation" src={exclamation} alt="hey" />
                  <p className="text-sm text-[#9b211b] font-bold">
                    {t("employer.send_offer.end_date_higher")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-6 px-8  flex gap-3 bg-white border rounded-lg items-start mt-6">
          <div className="flex flex-col">
            <p className="font-bold text-xl">
              {t("employer.send_offer.work_description")}
            </p>
            <div className="text-sm mt-8">
              <div className=" flex flex-col gap-1">
                <p className="font-bold">
                  {t("employer.send_offer.contract_title")}
                </p>
                <p
                  className="text-azulBonico cursor-pointer hover:underline hover:text-azulOscuro"
                  onClick={() => navigate("/applicants/view-job-post")}
                >
                  {jobData.data[0].title}
                </p>
              </div>
              <div className="mt-6 flex flex-col gap-1">
                <p className="font-bold">
                  {t("employer.send_offer.description_work")}
                </p>
                <p>{jobData.data[0].details}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="p-6 px-8  flex flex-col gap-3 bg-white border rounded-lg items-start mt-6">
          <label className="flex items-center text-sm">
            <div className={`absolute ${!warning.terms && "hidden"}`}>
              <div className="flex relative left-1 top-9  items-center">
                <img className="exclamation" src={exclamation} alt="hey" />
                <p className="text-sm text-[#9b211b] font-bold">
                  {t("employer.send_offer.agree_terms")}
                </p>
              </div>
            </div>
            <input
              type="checkbox"
              className="mr-3"
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
            {t("employer.send_offer.understand_agree")} &nbsp;
            <a
              className="text-azulBonico hover:underline cursor-pointer hover:text-azulOscuro"
              href={"/legal"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("employer.send_offer.terms_service")}
            </a>
            , {t("employer.send_offer.including")} &nbsp;
            <a
              className="text-azulBonico hover:underline cursor-pointer hover:text-azulOscuro"
              href={"/legal"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("employer.send_offer.user_agreement")}
            </a>{" "}
            &nbsp; and &nbsp;
            <a
              className="text-azulBonico hover:underline cursor-pointer hover:text-azulOscuro"
              href={"/legal"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("employer.send_offer.privacy_policy")}
            </a>
          </label>
          <div className="flex w-full mt-10 items-center justify-end gap-8 mr-2 tracking-wide">
            <button
              className="hover:underline "
              onClick={() => navigate("/applicants/review")}
            >
              {t("employer.send_offer.cancel")}
            </button>
            <button
              className="px-6 py-[6px] text-white bg-azulBonico hover:bg-azulOscuro rounded-full transition duration-300"
              onClick={() => handleContinue()}
            >
              {t("employer.send_offer.continue")}
            </button>
          </div>
        </div>
      </>
      {showModalPayOffer && <ModalSendOffer sendOffer={sendOffer} />}
    </div>
  );
};

export default SendAnOffer;
