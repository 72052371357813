import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "react-modal";
import { StylesModal } from "../../Utils/stylesModal";
import { cn } from "src/lib/utils";
import { Elements } from "@stripe/react-stripe-js";
import { XMarkIcon } from "@heroicons/react/24/solid";

import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../CheckoutForm";
import { useTranslation } from "react-i18next";

interface ModalRechargeCreditsProps {
  showRechargeCredits: boolean;
  setShowRechargeCredits: Dispatch<SetStateAction<boolean>>;
  clientSecret: string;
  price: string;
  numberOfCredits: string;
}

const ModalRechargeCredits = ({
  showRechargeCredits,
  setShowRechargeCredits,
  clientSecret,
  numberOfCredits,
  price,
}: ModalRechargeCreditsProps) => {
  const { t } = useTranslation();
  const [transition, setTransition] = useState(false);
  const stripePromise = loadStripe(
    "pk_live_51QrMFhGEpp8xu3GMcsag5qSTYD4K9w6Sb0gblbE4JZwEZHGvyc6PmFvJCtQgOacHu7SN1lcB34jv2o7aave7jsx100rmVm1ufL"
  );

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  const appearance = {
    theme: "stripe",
  };

  const options: any = {
    clientSecret,
    appearance,
  };

  return (
    <Modal isOpen={showRechargeCredits} style={StylesModal}>
      <div
        className={cn(
          `fixed z-50 ${
            transition === false ? "scale-y-0" : "scale-y-100"
          } transition duration-150 w-full lg:w-[700px] lg:left-[20%] border  lg:translate-x-[50%] rounded top-[50%] right-0 translate-y-[-50%] bg-white border-[0.5px] px-10 py-4`
        )}
      >
        <div className="flex items-center justify-between pb-10">
          <div className="">
            <h1 className="text-xl text-[#333] font-semibold">
              {numberOfCredits}{" "}
              {t("employer.applicants.view_jobpost.modal.clone.credits")}{" "}
              <span className="text-azulBonico text-[15px]">({price}€)</span>
            </h1>
          </div>
          <div
            onClick={() => setShowRechargeCredits(false)}
            className="hover:bg-gray-200 p-1 rounded-full h-10 w-10 ml-auto flex items-center justify-center cursor-pointer transition duration-200 ease-in-out"
          >
            <XMarkIcon className="h-4 w-4" />
          </div>
        </div>
        {clientSecret && stripePromise !== undefined && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm quantity={Number(numberOfCredits)} credits="" />
          </Elements>
        )}
      </div>
    </Modal>
  );
};

export default ModalRechargeCredits;
