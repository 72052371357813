import React, { useState, SetStateAction, useRef, useEffect } from "react";
import parse from "html-react-parser";
import ProfileCompleteness from "../../../Components/WorkerDashboard/ProfileCompleteness";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import edit from "../../../assets/img/edit.png";
import ModalEditAvailability from "../../../Components/Modals/ModalEditAvailability";
import ModalEditCategory from "../../../Components/Modals/ModalEditCategory";
import ModalHowToUseWorker from "../../../Components/Modals/ModalHowToUseWorker";
import { useTranslation } from "react-i18next";
import { Category } from "../../../Utils/services/apiCalls";
import { Skeleton } from "../../../Components/ui/skeleton";
import {
  BASE_URL,
  BASE_URL_IMG,
  INFO_WORKER,
  PROFILE_WORKER,
} from "src/Config/api.config";
import { useOnClickOutside } from "src/Hooks/useOnClickOutside";
import { useModalStore } from "src/Hooks/useModalStore";

import { useEmployerStore } from "src/Routing/useProfileStore";
import { useWalletSore } from "src/Hooks/useWalletStore";
import TextureProfile from "src/assets/texture/texture-profile.jpg";
import { useQuery } from "react-query";
import RatingStars from "src/Components/Rating/RatingStars";
import { HandleMonthName, HandleMonthNameES } from "src/Utils/HandleMonthName";

interface DashboardRightContainerProps {
  workerCategories: Category[];
  setWorkerCategories: React.Dispatch<SetStateAction<any>>;
  appliedCounter: { applied: number; active: number; offered: number };
  setAppliedCounter: React.Dispatch<
    SetStateAction<
      { applied: number; active: number; offered: number } | undefined
    >
  >;
  loadingWorkerCategories: boolean;
  loadingAppliedJobsNumber: boolean;
  refetchCategories: () => void;
  clientScore: {
    client_type_name: string;
    date_joined: string;
    score_avg: string;
    totalConnections: string;
    totalConnectionsTerminated: string;
    totalScores: string;
  };
}

function DashboardRightContainer({
  setWorkerCategories,
  workerCategories,
  appliedCounter,
  loadingWorkerCategories,
  loadingAppliedJobsNumber,
  refetchCategories,
  clientScore,
}: DashboardRightContainerProps) {
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);
  const [numberOfSavedJobs, setNumberOfSavedJobs] = useState();
  const [showModalAvailability, setShowModalAvailability] = useState(false);
  const [showModalCategory, setShowModalCategory] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [availability, setAvailability] = useLocalStorage(
    "Availability",
    "Available now"
  );
  const [showEditJobType, setShowEditJobType] = useState(false);
  const [showCreditsMenu, setShowCreditsMenu] = useModalStore((state) => [
    state.showCreditsMenu,
    state.setShowCreditsMenu,
  ]);
  const modalRef = useRef<HTMLDivElement>(null);
  const [loadingCredits, setLoadingCredits] = useState(false);
  const modal = useWalletSore();
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const { i18n } = useTranslation();
  useOnClickOutside(modalRef, () => {
    if (showCreditsMenu) {
      setShowCreditsMenu(showCreditsMenu);
    }
  });

  const getWorkerData = async () => {
    const data = await fetch(
      `${BASE_URL}${PROFILE_WORKER}?client_id=${userID}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setUserInfo((prev: any) => ({
          ...prev,
          profile_image: data.result.workerProfile?.profile_image,
        }));
      })

      .catch((err) => console.log(err));

    return data;
  };

  useEffect(() => {
    getWorkerData();
  }, []);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getWorkerInformation = async () => {
    const data = await fetch(`${BASE_URL}${INFO_WORKER}?client_id=${userID}`, {
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const {
    data: profileData,
    isLoading,
    refetch: refetchWorkerData,
  } = useQuery(["worker_info"], getWorkerInformation);
  return (
    <div className="hidden laptop:!block flex-[0_0_25%] max-w-[25%] p-2 mt-4  ">
      <div className="rounded relative dark:bg-background mr-2  dark:text-whiteish">
        <div className="relative flex flex-col items-center p-6 bg-white rounded-lg border mb-6 max-w-sm">
          <img
            src={TextureProfile}
            alt="Profile Background"
            className="absolute top-0 left-0 w-full h-16 object-cover rounded-t-lg"
          />
          <div className="relative -mt-12 z-10">
            <img
              src={`${BASE_URL_IMG}${userInfo?.profile_image}`}
              alt="Profile"
              className="h-24 w-24 rounded-full border-4 object-cover border-white"
              onError={(e: any) => {
                // If the image fails to load, replace it with a placeholder
                e.target.src = require("src/assets/img/account-photo.png");
              }}
            />
          </div>
          <div className="text-center mt-4">
            <p
              className="text-lg font-semibold underline cursor-pointer tracking-wide text-gray-800 hover:text-blue-600"
              onClick={() => navigate("/profile")}
            >
              {profileData?.data[0]?.first_name?.charAt(0).toUpperCase() +
                profileData?.data[0]?.first_name?.slice(1)}{" "}
              {profileData?.data[0]?.last_name}
            </p>
            <p className="text-sm text-gray-600 mt-1">
              {profileData?.data[0]?.title}
            </p>
          </div>
          <div className="mt-4">
            <RatingStars
              size="14"
              activeStar={clientScore?.score_avg}
              score={clientScore?.score_avg}
              evaluation={false}
              reviews={true}
              showHalf={true}
              info={{ totalScores: clientScore?.totalScores }}
            />
          </div>
          {clientScore?.totalConnections !== "0" && (
            <div className="rounded-lg  border border-gray-200 overflow-hidden bg-white">
              <div className="bg-gradient-to-r from-indigo-50 to-blue-50 px-6 py-4 border-b border-gray-100">
                <h3 className="text-sm font-medium text-gray-700">
                  {t("client.dashboard.statistics")}
                </h3>
              </div>

              <ul className="divide-y divide-gray-100">
                <li className="px-6 py-4 flex items-center justify-between hover:bg-gray-50 transition-colors duration-150">
                  <div className="flex items-center">
                    <div className="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4 h-4 text-blue-600"
                      >
                        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                      </svg>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-600">
                        {t("admin.analytics.jobs")}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <span className="text-lg font-bold text-gray-900">
                      {clientScore?.totalConnections}
                    </span>
                  </div>
                </li>

                <li className="px-6 py-4 flex items-center justify-between hover:bg-gray-50 transition-colors duration-150">
                  <div className="flex items-center">
                    <div className="h-8 w-8 rounded-full bg-red-50 flex items-center justify-center mr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4 h-4 text-red-500"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="18"
                          height="18"
                          rx="2"
                          ry="2"
                        ></rect>
                        <line x1="9" y1="9" x2="15" y2="15"></line>
                        <line x1="15" y1="9" x2="9" y2="15"></line>
                      </svg>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-600">
                        {t("dashboard_right_container_jobs_cancelled")}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <span className="text-lg font-bold text-gray-900">
                      {clientScore?.totalConnectionsTerminated}
                    </span>
                  </div>
                </li>
              </ul>

              <div className="px-6 py-3 bg-gray-50 border-t border-gray-100 flex items-center justify-center">
                <div className="bg-green-50 p-2 rounded-full mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                {/* <span className="text-[10px] text-gray-500">
                  {t("worker.dashboard.member_since")}{" "}
                  <span className="font-medium text-gray-700">
                    {i18n.language === "en"
                      ? HandleMonthName(clientScore?.date_joined?.slice(5, 7))
                      : HandleMonthNameES(
                          clientScore?.date_joined?.slice(5, 7)
                        )}{" "}
                    {clientScore?.date_joined?.slice(0, 4)}
                  </span>
                </span> */}
                <div className="flex flex-col">
                  <span className="text-sm text-gray-500">
                    {t("worker.dashboard.member_since")}
                  </span>
                  <span className=" text-sm font-medium text-gray-700">
                    {i18n.language === "en"
                      ? HandleMonthName(clientScore?.date_joined?.slice(5, 7))
                      : HandleMonthNameES(
                          clientScore?.date_joined?.slice(5, 7)
                        )}{" "}
                    {clientScore?.date_joined?.slice(0, 4)}
                  </span>
                </div>
              </div>
            </div>
          )}
          {/* {clientScore?.totalConnections !== "0" && (
  <ul className="flex flex-col gap-4  w-full text-gray-800 bg-white p-6 ">
    <li className="flex justify-between items-center py-2 border-b border-gray-100">
      <span className="font-semibold text-gray-900">
        {clientScore?.totalConnections}
      </span>
      <span className="text-[12px] text-gray-600 font-medium">
        {t("admin.analytics.jobs")}
      </span>
    </li>
    <li className="flex justify-between items-center py-2 border-b border-gray-100">
      <span className="font-semibold text-gray-900">
        {clientScore?.totalConnectionsTerminated}
      </span>
      <span className="text-[12px] text-gray-600 font-medium">
        {t("dashboard_right_container_jobs_cancelled")}
      </span>
    </li>
    <li className="text-[12px] text-gray-500 text-center mt-4 italic">
      {t("worker.dashboard.member_since")}{" "}
      <span className="text-[12px] text-gray-600 font-medium">
        {i18n.language === "en"
          ? HandleMonthName(clientScore?.date_joined?.slice(5, 7))
          : HandleMonthNameES(clientScore?.date_joined?.slice(5, 7))}{" "}
        {clientScore?.date_joined?.slice(0, 4)}
      </span>
    </li>
  </ul>
)} */}
        </div>
        <ProfileCompleteness userInfo={userInfo} />
        <div className="flex flex-col p-6 text-sm bg-white rounded border mt-4">
          <div>
            <div className="flex items-center justify-between">
              <p className="font-semibold">
                {t("worker.right_container_dashboard.availability")}
              </p>
              <img
                src={edit}
                alt="edit"
                className="h-3 cursor-pointer"
                onClick={() => setShowModalAvailability(true)}
              />
            </div>
            <p
              className={`mt-1 cursor-pointer font-bold hover:bg-[#f7f4f4] border rounded-xl px-3 border-slate-200 w-fit ${
                availability === "Available now"
                  ? "text-[#14A800]"
                  : availability === "Listening offers..."
                  ? "text-[#FF7D01]"
                  : "text-[#94b8a3]"
              }`}
              onClick={() => setShowModalAvailability(true)}
            >
              {availability}
            </p>
          </div>
          {showModalAvailability === true && (
            <ModalEditAvailability
              setShowModalAvailability={setShowModalAvailability}
              showModalAvailability={showModalAvailability}
              setAvailability={setAvailability}
              availability={availability}
            />
          )}
        </div>
        <div className="flex flex-col p-6 text-sm bg-white rounded border mt-4">
          <div className="flex items-center justify-between ">
            <p className="font-semibold">
              {t("worker.find_work.dashboard.my_categories")}
            </p>
            <img
              src={edit}
              alt="edit"
              className="h-3 cursor-pointer"
              onClick={() => setShowModalCategory(true)}
            />
            {showModalCategory === true && (
              <ModalEditCategory
                workerCategories={workerCategories}
                setWorkerCategories={setWorkerCategories}
                setShowModal={setShowModalCategory}
                showEditCategoriesModal={showModalCategory}
                refetchCategories={refetchCategories}
              />
            )}
          </div>
          <div className=" gap-1 flex flex-col mt-4">
            {workerCategories &&
              workerCategories?.map(
                (category: { category_id: string; name: string }) => {
                  if (!loadingWorkerCategories) {
                    return (
                      <p
                        className="text-azulOscuro text-sm font-medium cursor-pointer"
                        key={category.category_id}
                      >
                        {category.name}
                      </p>
                    );
                  } else {
                    return (
                      <Skeleton
                        key={category.category_id}
                        className="h-4 w-[60%]"
                      />
                    );
                  }
                }
              )}
          </div>
        </div>
      </div>

      <div className="rounded border  bg-white  mt-6 dark:bg-background dark:text-whiteish border-bordermr-2">
        <div className="flex flex-col p-6 border-b border-blue-100">
          <p className=" font-bold text-xl tracking-wider">
            {" "}
            {t("worker.right_container_dashboard.applied_jobs.title")}{" "}
          </p>
        </div>
        {appliedCounter?.applied === 0 &&
        appliedCounter?.active === 0 &&
        appliedCounter?.offered === 0 ? (
          <p className="text-sm p-6">
            {t("worker.right_container_dashboard.applied_jobs.desc")}
          </p>
        ) : (
          <>
            <div className="pt-6 px-6 pb-3 cursor-pointer ">
              {loadingAppliedJobsNumber ? (
                <Skeleton className="h-3 w-1/2" />
              ) : (
                <p
                  className="text-sm text-azulBonico font-medium hover:underline  hover:text-azulOscuro w-fit"
                  onClick={() => navigate("/applied-jobs")}
                >
                  {appliedCounter?.offered}{" "}
                  {t("worker.right_container_dashboard.applied_jobs.offers")}
                </p>
              )}
            </div>
            <div className=" px-6 pb-3 cursor-pointer">
              {loadingAppliedJobsNumber ? (
                <Skeleton className="h-3 w-1/2" />
              ) : (
                <p
                  className="text-sm text-azulBonico  font-medium  hover:underline  hover:text-azulOscuro w-fit"
                  onClick={() => navigate("/applied-jobs")}
                >
                  {appliedCounter?.active}{" "}
                  {t("worker.right_container_dashboard.applied_jobs.active")}
                </p>
              )}
            </div>
            <div className="px-6 pb-6 cursor-pointer">
              {loadingAppliedJobsNumber ? (
                <Skeleton className="h-3 w-1/2" />
              ) : (
                <p
                  className="text-sm text-azulBonico  font-medium hover:underline  hover:text-azulOscuro w-fit"
                  onClick={() => navigate("/applied-jobs")}
                >
                  {appliedCounter?.applied}{" "}
                  {t("worker.right_container_dashboard.applied_jobs.applied")}
                </p>
              )}
            </div>
          </>
        )}
      </div>
      <div className="rounded border mt-4 bg-white  mr-2 dark:bg-background dark:border-border dark:border dark:text-whiteish">
        <div className="flex flex-col p-6">
          <p className="font-medium">
            {parse(t("worker.home.rightbar.knowflexi.title"))}
          </p>
          <p className="text-azulBonico text-sm">
            {typeof t("worker.home.rightbar.knowflexi.subtitle.link").split(
              "<b>"
            )[0] === "string" && (
              <>
                {
                  t("worker.home.rightbar.knowflexi.subtitle.link").split(
                    "<b>"
                  )[0]
                }
              </>
            )}
            <span
              className="font-medium hover:underline cursor-pointer"
              onClick={() => setShowModal(true)}
            >
              {typeof t("worker.home.rightbar.knowflexi.subtitle.link").split(
                "<b>"
              )[1] === "string" && (
                <>
                  {parse(
                    t("worker.home.rightbar.knowflexi.subtitle.link")
                      .split("<b>")[1]
                      ?.toString()
                  )}
                </>
              )}
            </span>
          </p>
        </div>
        {showModal && (
          <ModalHowToUseWorker
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
      </div>
    </div>
  );
}

export default DashboardRightContainer;
