import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ImLocation } from "react-icons/im";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { useModalStore } from "src/Hooks/useModalStore";

import { BsFire, BsLightning } from "react-icons/bs";
import { FindWorker, HourDaysTypes } from "src/types/types";
import { BASE_URL, BASE_URL_IMG } from "src/Config/api.config";
import { selectedWorkerId, workerProfile } from "src/Atoms/jotaiAtoms";
import { useAtom } from "jotai";
import { userProfileStore } from "src/Routing/useProfileStore";

import { cn } from "src/lib/utils";
import { useTranslation } from "react-i18next";
import RatingStars from "../Rating/RatingStars";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";

interface WorkerCardProps {
  worker: FindWorker;
  refetch: () => void;
  showModalDiscover: { discover: boolean };
  setShowModalDiscover: Dispatch<SetStateAction<{ discover: boolean }>>;
  discoverValue: string;
  setClickedWorker: any;
}

const WorkerCard = ({
  worker,
  refetch,
  setShowModalDiscover,
  showModalDiscover,
  discoverValue,
  setClickedWorker,
}: WorkerCardProps) => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const { profileTypeToken } = userProfileStore((s) => s);
  const [animationData, setAnimationData] = useState<any>();
  useEffect(() => {
    import("src/Utils/lotties/Fire.json").then(setAnimationData);
  }, []);
  const [activeStar, setActiveStar] = useState();

  const [showModalInvite, setShowModalInvite] = useModalStore((state) => [
    state.showModalInvite,
    state.setShowModalInvite,
  ]);
  const [workerId, setWorkerId] = useAtom(selectedWorkerId);
  const { t } = useTranslation();
  const [workerProf, setWorkerProf] = useAtom(workerProfile);
  const navigate = useNavigate();
  const workerData = new FormData();
  workerData.append("_method", "POST");
  workerData.append("client_id_employer", userID);
  workerData.append("client_id_worker", worker.data.client_id_worker);

  const saveWorker = async () => {
    const data = await fetch(`${BASE_URL}discover.workers`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
      body: workerData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result === 1) {
          refetch();
        }
      })
      .catch((err) => console.log(err));
  };

  const unsaveWorker = async (workerId: string) => {
    const data = await fetch(
      `${BASE_URL}discover.workers?client_id_employer=${userID}&client_id_worker=${workerId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.result === 1) {
          refetch();
        }
      })
      .catch((err) => console.log(err));
  };
  const handleClick = () => {
    if (worker.data.blurred === "false") {
      navigate(`/find-worker/${worker.data.client_id_worker}`);
    }
    setWorkerProf(worker);
    setWorkerId(worker.data.client_id_worker);
  };
  return (
    <div
      onClick={handleClick}
      key={worker.data.client_id_worker}
      className="w-full relative dark:bg-background dark:text-whiteish border border-border bg-white flex px-4 py-6 hover:border transition cursor-pointer justify-between items-start rounded-lg mb-4"
    >
      <div className="flex items-start space-x-6">
        {/* Profile Image */}
        <div className="h-28 w-28 rounded-full overflow-hidden bg-gray-200 flex justify-center items-center border border-gray-300 shadow-md">
          <img
            loading="lazy"
            src={
              worker?.data.profile_image
                ? `${BASE_URL_IMG}${worker.data.profile_image}`
                : require("../../assets/img/user-worker.png")
            }
            alt="profile"
            className={cn(
              "h-full w-full object-cover transition-all duration-300 ease-in-out rounded-full",
              worker?.data.blurred === "true" ? "blur-lg" : "hover:scale-110"
            )}
            onError={(e:any) => (e.target.src = require("../../assets/img/user-worker.png"))}
          />
        </div>
        
        {/* Worker Info */}
        <div className="px-6 py-2 flex flex-col items-start space-y-4">
          <h1 className="text-2xl font-bold capitalize text-gray-900 dark:text-whiteish">
            {worker.data.title}
          </h1>
          
          {/* Location */}
          <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
            <ImLocation className="h-5 w-5 text-gray-500" />
            <p>{worker.data.city_name ?? "Worldwide"}</p>
          </div>

          {/* Badges */}
          <div className="flex items-center gap-3">
            {worker.data.profile_badge === "true" && (
              <Badge text={t("available_now")} icon={<BsLightning className="text-blue-400" />} color="blue" />
            )}
            {worker.data.profile_boost === "true" && (
              <Badge text={t("boosted")} icon={<BsFire className="text-orange-400" />} color="orange" />
            )}
          </div>
          
          {/* Description */}
          <Description text={worker?.data.overview??""} />
          
          {/* Rating */}
          <RatingStars
            score={worker?.data.score_avg}
            setActiveStar={setActiveStar}
            activeStar={worker?.data.score_avg}
            showHalf
            showModal={false}
            evaluation={false}
          />
          
          {/* Availability */}
          <Availability profileTime={worker.ProfileTime} />
        </div>
      </div>
      
      {/* Pricing and Actions */}
      <div className="flex items-center space-x-5">
        <h1 className="text-lg font-semibold">
          <span className="text-xl">{worker.data.budget}</span>€/h
        </h1>
        {worker.data.blurred === "true" && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setClickedWorker(worker.data);
              setShowModalDiscover((prev) => ({ ...prev, discover: true }));
            }}
            className="px-6 h-fit text-sm font-semibold py-1 bg-azulBonico text-white border border-azulBonico hover:bg-azulOscuro rounded-full transition duration-300 flex items-center gap-2"
          >
            {t("discover")}
          </button>
        )}
      </div>
    </div>
  );
};
const Badge = ({ text, icon, color }:{text:string; icon:any; color:string}) => (
  <div className={`flex items-center gap-2 px-4 py-1 bg-${color}-50 border border-${color}-400 rounded-full`}>
    {icon}
    <p className={`text-sm font-medium text-${color}-600`}>{text}</p>
  </div>
);

const Description = ({ text }:{text:string}) => (
  <div className="text-sm text-[#777777] mb-4 mt-6 pr-12 text-ellipsis">
    <p>{text ? `${text.slice(0, 170)}${text.length > 170 ? "..." : ""}` : `${i18next.t("no_description")}`}</p>
  </div>
);

const Availability = ({ profileTime }:{profileTime:HourDaysTypes[]}) => (
  <div className="pb-4 flex text-xs mt-2 gap-2 cursor-pointer w-fit">
    {profileTime.length > 0 &&
      profileTime.map((day) => (
        <div key={day.worker_time_profile_id} className="flex flex-col text-center bg-blue-200 rounded-lg w-[45px]">
          <p className="border-b border-blue-200 px-2 font-bold text-white py-2">{day.dayname}</p>
          <p className="px-2 text-slate-500 font-bold py-2">{day.hours}h</p>
        </div>
      ))}
  </div>
);
export default WorkerCard;
