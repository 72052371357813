import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
  lazy,
  Suspense,
} from "react";
import Select from "react-select";

import useLocalStorage from "../../Hooks/UseLocalStorage";
import {
  BASE_URL,
  LANGUAGES,
  LANGUAGE_WORKER,
} from "../../Config/api.config";
const Loader = lazy(() => import("src/Components/Loader/Loader"));
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import { allLevels } from "src/data/levels";

interface ModalAddLanguageProps {
  setShowAddLanguageModal: Dispatch<SetStateAction<boolean>>;
  showAddLanguageModal: boolean;
  refetch:()=>void
}

function ModalAddLanguage({
  setShowAddLanguageModal,
  showAddLanguageModal,
  refetch
}: ModalAddLanguageProps) {
  const { t } = useTranslation();
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [allLanguages, setAllLanguages] = useState([]);
  // const [allLevels, setAllLevels] = useState([]);
  const [checkingEnglish, setCheckingEnglish] = useState(false);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loading, setLoading] = useState(false);
  const [transition, setTransition] = useState(false);
  const [language, setLanguage] = useState({
    language: "",
    level: "",
  });
  const ref = useRef<HTMLDivElement>(null);
  const data = new FormData();
  data.append("_method", "POST");
  data.append("client_id", userID);
  const getAllLanguages = () => {
    fetch(`${BASE_URL}${LANGUAGES}?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllLanguages(data.result))
      .catch((err) => console.log(err));
  };
  
  const availableLanguages = allLanguages?.map(function (row: {
    name: string;
  }) {
    return { value: row.name, label: row.name };
  });

 

  useEffect(() => {
    getAllLanguages();
  }, []);
  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      //   width: "250px",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  const handleFormData = (language: { language: string; level: string }) => {
    const code: any = allLanguages?.find(
      ({ name }) => name === language?.language
    );
    const level: any = (language: { language: string; level: string }) => {
      switch (language?.level) {
        case "Native or Bilingual":
          return 4;
        case "Fluent":
          return 3;
        case "Conversational":
          return 2;
        case "Basic":
          return 1;
      }
    };
    data.append("language_code", code.alpha2_code);
    data.append("language_level_id", level(language));
  };
  const addLanguage = () => {
    if (language.language && language.level) {
      handleFormData(language);
    }
    postLanguage();
  };

  const postLanguage = () => {
    fetch(`${BASE_URL}${LANGUAGE_WORKER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json()).then((data)=>{
        if(data.status ==="ok"){
          refetch()
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showAddLanguageModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showAddLanguageModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowAddLanguageModal(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <div>
      {" "}
      <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50">
        {loading === false ? (
          <div
            className={`fixed z-50 ${
              transition === false ? "scale-x-0" : "scale-x-100"
            } transition duration-150 w-[90%] lg:w-[700px] left-[50%] border translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
            ref={ref}
          >
            <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
              {t("modal.add_language")}
            </div>
            <div className="flex flex-col w-full text-sm my-8 px-8">
              <div className="flex py-2 bg-[#2abeeb3d] font-bold px-4 gap-8">
                <p className="flex-[0_0_50%] max-w-[50%]">
                  {t("modal.add_language.language")}
                </p>
                <p className="flex-[0_0_50%] max-w-[50%] -ml-4">
                  {t("modal.add_language.proficiency")}
                </p>
              </div>
              <div className="flex items-center gap-4 justify-between pt-4 pb-28">
                <Select
                  options={availableLanguages}
                  styles={colourStyles}
                  placeholder={t(
                    "createprofile.language.body.box.secondrow.placeholderLeft"
                  )}
                  className="flex-[0_0_50%] max-w-[50%] pr-4"
                  onChange={(e: any) => {
                    setLanguage((language) => ({
                      ...language,
                      language: e.value,
                    }));
                    setCheckingEnglish(true);
                  }}
                />
                <Select
                  options={allLevels}
                  styles={colourStyles}
                  placeholder={t(
                    "createprofile.language.body.box.secondrow.placeholderRight"
                  )}
                  className="flex-[0_0_50%] max-w-[50%] pr-4"
                  onChange={(e: any) => {
                    setLanguage((language) => ({
                      ...language,
                      level: e.value,
                    }));
                    setCheckingEnglish(true);
                  }}
                />
              </div>
            </div>

            <div className="flex w-full h-full items-end p-4 gap-2 pr-4 mt-2 justify-end border-t border-blue-100">
              <Button
                className="bg-none text-[0.9rem] w-24 h-10 border border-azulBonico text-azulBonico rounded-3xl hover:bg-blancoGrisClaro  mt-1 "
                onClick={() => {
                  closeModal();
                }}
              >
                {t("modal.add_language.cancel")}
              </Button>
              <Button
                className={` border-none  w-[110px] rounded-[20px] text-[0.9rem] px-[1rem]  h-10 ${
                  !language?.language || !language?.level
                    ? "cursor-not-allowed bg-blancoGris text-slate-300 disabled"
                    : "bg-azulBonico hover:bg-azulOscuro text-white "
                } `}
                disabled={!language?.language || !language?.level}
                onClick={(e) => {
                  addLanguage();
                  setLoading(true);
                  setTimeout(() => {
                    window.location.reload();
                  }, 200);
                }}
              >
                {t("modal.add_language.save")}
              </Button>
            </div>
          </div>
        ) : (
          <Suspense fallback={null}>
            <Loader />
          </Suspense>
        )}
      </div>
    </div>
  );
}

export default ModalAddLanguage;
