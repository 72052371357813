import React, {
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
  ElementRef,
} from "react";
import Select from "react-select";
import Modal from "react-modal";
import "react-phone-input-2/lib/style.css";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import {
  BASE_URL,
  CITIES,
  POSTCODES,
  PROFILE_WORKER,
} from "../../Config/api.config";

import { ReactComponent as Spain } from "../../assets/img/spain.svg";
import { ReactComponent as Australia } from "../../assets/img/australia-rounded.svg";
import { StylesModal } from "../../Utils/stylesModal";
import { useTranslation } from "react-i18next";

interface ModalEditLocationProps {
  showEditLocationModal: boolean;
  setShowEditLocationModal: Dispatch<SetStateAction<boolean>>;
  refetch:()=>void
}

function ModalEditLocation({
  showEditLocationModal,
  setShowEditLocationModal,
  refetch
}: ModalEditLocationProps) {
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");

  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [countries, setCountries] = useLocalStorage("AllCountries", []);
  const [isHovering, setIsHovering] = useState(false);
  const [citiesList, setCitiesList] = useState<any>([]);
  const [selectedCitiesList, setSelectedCitiesList] = useState<any>([]);
  const [postcodesList, setPostcodesList] = useState<any>();
  const [selectedPostcodesList, setSelectedPostcodesList] = useState<any>([]);
  const [originalData, setOriginalData] = useState<any>();
  const [notFound, setNotFound] = useState(false);
  const [notCodesFound, setNotCodesFound] = useState(false);
  const [cancelData, setCancelData] = useState(false);
  const [transition, setTransition] = useState(false);
  const { t } = useTranslation();

  const ref = useRef<ElementRef<"div">>(null);
  //ALL COUNTRIES
  // const availableCountries = countries?.map(function (row) {
  //   return { value: row.name, label: row.name };
  // });
  const availableCountries = [
    {
      value: "Spain",
      label: (
        <div className="flex items-center gap-4">
          <Spain className="h-4" />
          Spain{" "}
        </div>
      ),
    },
    {
      value: "Australia",
      label: (
        <div className="flex items-center gap-4">
          <Australia className="h-4" />
          Australia
        </div>
      ),
    },
  ];

  const getAllCities = (props: string) => {
    if (props?.length >= 1) {
      fetch(`${BASE_URL}${CITIES}?country_code=ES&name=${props}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setCitiesList(data.result);
          if (data.result.length === 0) {
            setNotFound(true);
          } else {
            setNotFound(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    const append = citiesList?.map(function (row: {
      name: string;
      id: string;
    }) {
      return {
        label: row.name,
        value: row.name,
        id: row.id,
      };
    });
    setSelectedCitiesList(append);
  }, [citiesList]);

  const getPostcodesFromCountry = (props: string) => {
    if (props.length > 1) {
      fetch(`${BASE_URL}${POSTCODES}?country_code=ES&name=${props}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setPostcodesList(data.result);
          if (data.result.length === 0) {
            setNotCodesFound(true);
          } else {
            setNotCodesFound(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const append = postcodesList?.map(function (row: {
      postcode: string;
      state: string;
      locality: string;
      id: string;
    }) {
      return {
        label: row.locality + "," + row.state + "," + row.postcode,
        value: row.locality + "," + row.state + "," + row.postcode,
        id: row.id,
      };
    });
    setSelectedPostcodesList(append);
  }, [postcodesList]);
  useEffect(() => {
    setOriginalData({
      country_code_name: userInfo?.country_code_name,
      country_code: userInfo?.country_code,
      city_name: userInfo?.city_name,
      city_id: userInfo?.city_id,
      post_code_name: userInfo?.post_code_name,
      post_code_id: userInfo?.post_code_id,
      address_street: userInfo?.address_street,
    });
  }, []);

  const handleCancelation = () => {
    setUserInfo((info: any) => ({
      ...info,
      country_code_name: originalData?.country_code_name,
      country_code: originalData?.country_code,
      city_name: originalData?.city_name,
      city_id: originalData?.city_id,
      post_code_name: originalData?.post_code_name,
      post_code_id: originalData?.post_code_id,
      address_street: originalData?.address_street,
    }));
    setTransition(false);
    setTimeout(() => {
      setShowEditLocationModal(false);
    }, 150);
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);
  data.append("address_street", userInfo?.address_street);

  const updateLocation = () => {
    fetch(`${BASE_URL}${PROFILE_WORKER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json()).then((data)=>{
        if(data.result.workerProfileUpdated=== 1){
          refetch()
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChanges = () => {
    data.append("city_id", userInfo?.city_id);
    data.append("post_code_id", userInfo?.post_code_id);
    data.append("country_code", "ES");
    updateLocation();
    setTimeout(() => {
      setShowEditLocationModal(false);
      if (window.location.pathname !== "/profile") {
        window.location.reload();
      }
    }, 200);
  };

  const colourStyles = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      width: "180px",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };
  const colourStyles2 = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showEditLocationModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showEditLocationModal]);

  useEffect(() => {
    if (cancelData === true) {
      handleCancelation();
    }
  }, [cancelData]);

  const closeModal = () => {
    setCancelData(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <Modal
      isOpen={showEditLocationModal}
      style={StylesModal}
      ariaHideApp={false}
    >
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-x-0" : "scale-x-100"
        } transition duration-150 w-[90%] lg:w-[700px] left-[50%] border translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
          {t("createprofile.submit.edityourLocation.title.card")}
        </div>
        <div className="p-8 pt-4">
          <p className="text-sm font-bold tracking-wide mb-6">
            {t("employer.edit_location.modify_address")}
          </p>

          <div className="flex flex-col text-sm">
            <label className="mb-4">
              {t("employer.edit_location.country")}*
              {userInfo?.country_code_name === "Spain" ? (
                <Select
                  styles={colourStyles}
                  className="mt-2 w-fit"
                  isSearchable={false}
                  value={{
                    value: "Spain",
                    label: (
                      <div className="flex items-center gap-4">
                        <Spain className="h-4" />
                        Spain{" "}
                      </div>
                    ),
                  }}
                  options={availableCountries}
                  onFocus={handleMouseEnter}
                  onBlur={handleMouseLeave}
                  onChange={(e: any) =>
                    setUserInfo((info: any) => ({
                      ...info,
                      country_code_name: e.value,
                      post_code_name: "",
                      city_name: "",
                    }))
                  }
                />
              ) : userInfo?.country_code_name === "Australia" ? (
                <Select
                  styles={colourStyles}
                  className="mt-2 w-fit"
                  isSearchable={false}
                  value={{
                    value: "Australia",
                    label: (
                      <div className="flex items-center gap-4">
                        <Australia className="h-4" />
                        Australia{" "}
                      </div>
                    ),
                  }}
                  options={availableCountries}
                  onFocus={handleMouseEnter}
                  onBlur={handleMouseLeave}
                  onChange={(e: any) =>
                    setUserInfo((info: any) => ({
                      ...info,
                      country_code_name: e.value,
                      post_code_name: "",
                      city_name: "",
                    }))
                  }
                />
              ) : (
                <Select
                  styles={colourStyles}
                  className="mt-2 w-fit"
                  isSearchable={false}
                  options={availableCountries}
                  onFocus={handleMouseEnter}
                  onBlur={handleMouseLeave}
                  // onChange={(e) => handleCountryCode(e)}
                  onChange={(e) =>
                    setUserInfo((info: any) => ({
                      ...info,
                      country_code_name: e?.value,
                    }))
                  }
                />
              )}
            </label>

            <div className="flex flex-1 mb-4 text-sm gap-4">
              <label className="relative w-full">
                {t("employer.edit_location.city")} *
                <Select
                  onInputChange={(e) => getAllCities(e)}
                  options={selectedCitiesList}
                  className="mt-2"
                  styles={colourStyles2}
                  placeholder="Search Cities..."
                  value={{
                    label: userInfo?.city_name,
                    value: userInfo?.city_name,
                  }}
                  onChange={(e: any) =>
                    setUserInfo((info: any) => ({
                      ...info,
                      city_name: e?.value,
                      city_id: e?.id,
                    }))
                  }
                  // noOptionsMessage={() =>"Please, type for search"}
                  noOptionsMessage={() =>
                    notFound === true
                      ? `${t("employer.edit_location.no_results_found")}`
                      : `${t("employer.edit_location.type_forsearch")}`
                  }
                />
              </label>
              <label className="relative w-full">
                {t("employer.edit_location.postcal_code")} *
                <Select
                  onInputChange={(e) => getPostcodesFromCountry(e)}
                  options={selectedPostcodesList}
                  className="mt-2"
                  styles={colourStyles2}
                  placeholder="Search Postcodes..."
                  value={{
                    label: userInfo?.post_code_name,
                    value: userInfo?.post_code_name,
                  }}
                  onChange={(e: any) =>
                    setUserInfo((info: any) => ({
                      ...info,
                      post_code_name: e?.value,
                      post_code_id: e?.id,
                    }))
                  }
                  noOptionsMessage={() =>
                    notCodesFound === true
                      ? `${t("employer.edit_location.no_results_found")}`
                      : `${t("employer.edit_location.type_forsearch")}`
                  }
                />
              </label>
            </div>
            <div className="flex flex-col gap-3">
              <p className="text-sm">
                {t("employer.edit_location.street_address")}
              </p>
              <input
                value={userInfo?.address_street}
                onChange={(e) =>
                  setUserInfo((info: any) => ({
                    ...info,
                    address_street: e.target.value,
                  }))
                }
                type="text"
                className="w-full border h-10 pl-4 outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
              />
            </div>
          </div>
        </div>

        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
          <button
            className="bg-none text-[0.9rem] font-semibold w-24 h-10 border border-azulBonico text-azulBonico rounded-3xl hover:bg-blancoGrisClaro cursor-pointer mt-1 transition duration-300"
            onClick={() => {
              setCancelData(true);
            }}
          >
            {t("employer.edit_location.cancel")}
          </button>
          <button
            className={`border-none font-semibold w-[110px] rounded-[20px] text-[0.9rem] px-[1rem] transition duration-300 h-10  ${
              userInfo?.country_code_name &&
              userInfo?.post_code_name &&
              userInfo?.city_name
                ? "bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer"
                : "cursor-not-allowed bg-blancoGris text-slate-300"
            } `}
            disabled={
              !userInfo?.country_code_name ||
              !userInfo?.post_code_name ||
              !userInfo?.city_name
            }
            onClick={(e) => {
              handleChanges();
            }}
          >
            {t("employer.edit_location.update")}
          </button>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}

export default ModalEditLocation;
