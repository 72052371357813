import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import edit from "../../../assets/img/edit.png";
import plus from "../../../assets/img/plus.png";
import trash from "../../../assets/img/trash-can.png";
import { ReactComponent as Pin } from "../../../assets/img/in-person.svg";
import { ReactComponent as Review } from "../../../assets/img/review.svg";
import ModalEditEmployment from "../../../Components/Modals/ModalEditEmployment";
import ModalEditEducation from "../../../Components/Modals/ModalEditEducation";
import ModalDelete from "../../../Components/InitialClientForm/ModalDelete";
import ModalAddEmployment from "../../../Components/Modals/ModalAddEmployment";
import ModalAddEducation from "../../../Components/Modals/ModalAddEducation";
import ModalEditTitle from "../../../Components/Modals/ModalEditTitle";
import ModalEditBio from "../../../Components/Modals/ModalEditBio";
import ModalEditLanguages from "../../../Components/Modals/ModalEditLanguages";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { ReactComponent as Plus } from "../../../assets/img/plus.svg";
import { BASE_URL, BASE_URL_IMG } from "../../../Config/api.config";
import ModalDeleteEducation from "../../../Components/Modals/ModalDeleteEducation";
import { useNavigate } from "react-router-dom";
import ModalAddLanguage from "../../../Components/Modals/ModalAddLanguage";
import EditSkillSelector from "../../../Components/Worker/Registration/EditSkillSelector";
import ModalAddProfilePhoto from "../../../Components/Modals/ModalAddProfilePhoto";
import ModalEditBudget from "../../../Components/Modals/ModalEditBudget";
import ModalEditLocation from "../../../Components/Modals/ModalEditLocation";
// import { Loader } from "../../../Components/Loader/Loader";
const Loader = lazy(() => import("src/Components/Loader/Loader"));

import AvailableDays from "../../../Components/Time/AvailableDays";
import ModalEditSchedule from "../../../Components/Modals/ModalEditSchedule";
import RegistrationHeader from "../../../Components/Worker/Registration/Header/RegistrationHeader";
import SmallFooter from "../../../Components/Footer/SmallFooter";
import ModalEditCategory from "../../../Components/Modals/ModalEditCategory";
import { useTranslation } from "react-i18next";
import { options } from "src/lib/months";
import {
  EducationTypes,
  ExperienceTypes,
  HoursJobTypes,
} from "src/types/types";
import { useQuery } from "react-query";
import { userProfileStore } from "src/Routing/useProfileStore";

function SubmitProfile() {
  const { t } = useTranslation();
  const [showEditPhoneModal, setShowEditPhoneModal] = useState(false);
  const [showEditJobModal, setShowEditJobModal] = useState(false);
  const [showEditEducationModal, setShowEditEducationModal] = useState(false);
  const [showEditSkillsModal, setshowEditSkillsModal] = useState(false);
  const [showEditJobType, setShowEditJobType] = useState(false);
  const [showDeleteJobModal, setShowDeleteJobModal] = useState(false);
  const [showAddJobModal, setShowAddJobModal] = useState(false);
  const [showAddEducationModal, setShowAddEducationModal] = useState(false);
  const [showEditTitleModal, setShowEditTitleModal] = useState(false);
  const [showModalEditSchedule, setShowModalEditSchedule] = useState(false);
  const [showEditBioModal, setShowEditBioModal] = useState(false);
  const [showEditLocationModal, setShowEditLocationModal] = useState(false);
  const [showEditCategoriesModal, setShowEditCategoriesModal] = useState(false);
  const [showEditBudgetModal, setShowEditBudgetModal] = useState(false);
  const [showEditLanguagesModal, setShowEditLanguagesModal] = useState(false);
  const [showAddLanguageModal, setShowAddLanguageModal] = useState(false);
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const [preview, setPreview] = useLocalStorage("profile_img", "");
  const [imageUrlPlayer, setImageUrlPlayer] = useState();
  const [workerInfo, setWorkerInfo] = useState([]);
  const [detailsInfo, setDetailsInfo] = useState([]);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [workerLanguages, setWorkerLanguages] = useState<any>();
  const [allEmployments, setAllEmployments] = useState<any>();
  const [allEducations, setAllEducations] = useState<any>();
  const [job, setJob] = useLocalStorage("dataJobEdit", []);
  const [endingYear, setEndingYear] = useState("");
  const [startingYear, setStartingYear] = useState("");
  const [showAddProfilePhotoModal, setShowAddProfilePhotoModal] =
    useState(false);
  const [education, setEducation] = useLocalStorage("dataEducationEdit", []);
  const [allWorkerSkills, setAllWorkerSkills] = useState<any>();
  const [showDeleteEducationModal, setShowDeleteEducationModal] =
    useState(false);
  const [allChosenTimes, setAllChosenTimes] = useState<HoursJobTypes[]>([]);
  // const [fullPhoneNumber, setFullPhoneNumber] = useLocalStorage("phone", "");
  const [now, setNow] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const profileType = userProfileStore((s) => s.profileTypeToken);

  const [showContent, setShowContent] = useState(false);
  const [workerCategories, setWorkerCategories] = useState<any>();
  const inputPicture = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const getUserInfo = async() => {
   const response = await fetch(`${BASE_URL}workers?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setWorkerInfo(data.result.data[0]))
      .catch((err) => console.log(err));
  };
  const getWorkerData = async () => {
    const data = await fetch(`${BASE_URL}worker.profile?client_id=${userID}`, {
      // method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: workerInfoDetails, refetch:refetchWorkerData } = useQuery(["workerInfo"], getWorkerData);

  useEffect(() => {
    if(workerInfoDetails?.workerProfile!==undefined){

      setDetailsInfo(workerInfoDetails?.workerProfile);
    }
  }, [workerInfoDetails]);

  useEffect(() => {
    if (profileType === "worker") {
      setUserInfo({ ...detailsInfo, ...workerInfo });
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    }
  }, [workerInfo, detailsInfo]);
  const handleEdit = (job: any) => {
    fetch(`${BASE_URL}worker.experience?id=${job.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setJob(data.result[0]))
      .catch((err) => console.log(err));
  };
  const getAllExperiences = () => {
    fetch(`${BASE_URL}worker.experience?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllEmployments(data.result))
      .catch((err) => console.log(err));
  };

  const getAllEducations = () => {
    fetch(`${BASE_URL}worker.education?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllEducations(data.result))
      .catch((err) => console.log(err));
  };
  const getWorkerLanguages = () => {
    fetch(`${BASE_URL}worker.language?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setWorkerLanguages(data.result))
      .catch((err) => console.log(err));
  };

  const getSkills = () => {
    fetch(`${BASE_URL}worker.skill?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllWorkerSkills(data.result))
      .catch((err) => console.log(err));
  };

  const getCategories = async() => {
    const response = await fetch(`${BASE_URL}worker.category?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setWorkerCategories(data.result))
      .catch((err) => console.log(err));

    return response
  };

  const getTimes = () => {
    fetch(`${BASE_URL}worker.time.profile?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllChosenTimes(data.result))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCategories();
    getWorkerData();
    getTimes();
    getUserInfo();
    getAllExperiences();
    getAllEducations();
    getWorkerLanguages();
    getSkills();
    setTimeout(() => {
      setShowLoader(false);
    }, 300);
  }, []);
  const date = new Date();
  useEffect(() => {
    setNow(date.getHours() + ":" + date.getMinutes());
  }, [date]);

  const handleInputPictureClick = () => {
    inputPicture.current?.click();
  };

  const removeExperience = () => {
    setAllEmployments(
      allEmployments?.filter((items: any) => items.id !== job.id)
    );
  };

  const removeEducation = () => {
    setAllEducations(
      allEducations?.filter((items: any) => items.id !== education.id)
    );
  };

  const handleNoMoreLocal = () => {
    setJob();
    setEducation();
  };
  const handleEditEducation = (education: any) => {
    setEducation(education);
    setTimeout(() => {
      setShowEditEducationModal(true);
    }, 200);
  };
  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);
  data.append("completed", "1");
  const postState = () => {
    fetch(`${BASE_URL}worker.profile`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const handleSubmit = () => {
    postState();
    navigate("/create-profile/finish");
  };

  return (
    <div className="h-screen w-full bg-white">
      <RegistrationHeader />
      <div className="lg:max-w-4xl mt-12 mx-auto px-10 lg:px-0 h-fit border ">
        <div className="bg-white/10">
          <p className="text-xl font-medium lg:p-8 p-4 border-b ">
            {t("createprofile.submit.previewprofile.title")}
          </p>
        </div>
        <div className="bg-white flex items-center justify-between">
          <div className="flex flex-col lg:p-8 p-4">
            <p className="font-medium">
              {t("createprofile.submit.lookingGood.title")}{" "}
              {userInfo?.first_name} {userInfo?.last_name}!
            </p>
            <p className="mt-4 text-sm">
              {/* Make any edits you want, then submit your profile. You can make
              more changes after it&apos;s live. */}
              {t("createprofile.submit.lookingGood.subtitle")}
            </p>
            <button
              className="px-12 py-2 text-white bg-azulBonico hover:bg-azulOscuro rounded-full transition duration-300 mt-8 w-fit"
              onClick={() => handleSubmit()}
            >
              {t("createprofile.submit.SubmitProfileBtn")}
              {/* Submit Profile */}
            </button>
          </div>
          <Review className="h-[140px] hidden lg:inline w-auto mr-12" />
        </div>
      </div>
      {showContent === false ? (
        <Suspense fallback={null}>
          <Loader />
        </Suspense>
      ) : (
        <>
          <div className="max-w-4xl mt-8 mx-auto flex lg:flex-row flex-col rounded">
            <div className="lg:flex-[0_0_75%] lg:max-w-[75%] bg-white border p-8">
              <div className="flex gap-12">
                <img
                  src={`${BASE_URL_IMG}${userInfo?.profile_image}`}
                  alt="profile"
                  className="h-24 w-24 object-cover rounded-full"
                />
                <div className="flex flex-col">
                  <p className="text-xl font-medium">
                    {userInfo?.first_name} {userInfo?.last_name}
                  </p>
                  <div className="flex items-center gap-2 mt-3 text-sm">
                    <Pin className="h-3" />
                    <p>
                      {userInfo?.city_name}, {userInfo?.country_code_name}
                    </p>
                  </div>
                  <p className="text-sm text-[#6e7081] mt-1">
                    {now?.length === 3
                      ? "0" + date.getHours() + ":0" + date.getMinutes()
                      : (now?.substring(0, 2) === "10" ||
                          now?.substring(0, 2) === "11" ||
                          now?.substring(0, 2) === "12" ||
                          now?.substring(0, 2) === "13" ||
                          now?.substring(0, 2) === "14" ||
                          now?.substring(0, 2) === "15" ||
                          now?.substring(0, 2) === "16" ||
                          now?.substring(0, 2) === "17" ||
                          now?.substring(0, 2) === "18" ||
                          now?.substring(0, 2) === "19" ||
                          now?.substring(0, 2) === "20" ||
                          now?.substring(0, 2) === "21" ||
                          now?.substring(0, 2) === "22" ||
                          now?.substring(0, 2) === "23") &&
                        now?.length === 4
                      ? date.getHours() + ":0" + date.getMinutes()
                      : now?.substring(0, 2) !== "10" &&
                        now?.substring(0, 2) !== "20" &&
                        now?.length === 4
                      ? "0" + date.getHours() + ":" + date.getMinutes()
                      : date.getHours() + ":" + date.getMinutes()}
                    &nbsp;local time
                  </p>
                </div>
              </div>
              <div
                className="relative cursor-pointer top-0 right-0"
                onClick={() => setShowAddProfilePhotoModal(true)}
              >
                <div className="relative mt-3">
                  <Plus
                    fill={"#2abeeb"}
                    className="h-3 absolute top-[22px] left-[14px] w-auto"
                  />
                  <button className="px-4 text-sm font-medium w-fit py-1 pl-8 text-azulBonico mt-3 hover:bg-[#f7f4f4] border border-blue-100  bg-white  rounded-full transition duration-300">
                    {t("createprofile.submit.uploadPhotoBtn")}
                  </button>
                </div>
              </div>
              <div className="flex items-center mt-8 gap-3">
                <p className=" font-medium">{userInfo?.title}</p>
                <img
                  src={edit}
                  alt="edit"
                  className="h-3 cursor-pointer"
                  onClick={() => setShowEditTitleModal(true)}
                ></img>
              </div>
              <div className="mt-4 text-sm ">
                <p className="max-w-[600px] overflow-hidden">
                  {userInfo?.overview}
                </p>
                <img
                  src={edit}
                  alt="edit"
                  className="h-3 mt-3 cursor-pointer"
                  onClick={() => setShowEditBioModal(true)}
                ></img>
              </div>
              <div className="flex mt-8 items-center gap-3">
                <p className="font-medium">{userInfo?.budget} EUR / h</p>
                <img
                  src={edit}
                  alt="edit"
                  className="h-3 cursor-pointer"
                  onClick={() => setShowEditBudgetModal(true)}
                />
              </div>
              <div>
                <div className="flex mt-5 mb-2 items-center gap-3">
                  <p className="font-medium">
                    {t("createprofile.submit.edityourAvailableHours.title")}
                  </p>
                  <img
                    src={edit}
                    alt="edit"
                    className="h-3 cursor-pointer"
                    onClick={() => setShowModalEditSchedule(true)}
                  />
                </div>
                {showModalEditSchedule && (
                  <ModalEditSchedule
                    setShowModal={setShowModalEditSchedule}
                    showModal={showModalEditSchedule}
                    allChosenTimes={allChosenTimes}
                    // profileType={"worker"}
                    // id={userID}
                    setAllChosenTimes={setAllChosenTimes}
                  />
                )}
                <AvailableDays allChosenTimes={allChosenTimes} />
              </div>
            </div>
            <div className="lg:flex-[0_0_25%] lg:max-w-[25%]">
              <div className="px-8">
                <div className="flex lg:flex-row justify-between border-t pt-6 pb-6 border-blue-200 items-center">
                  <p className="font-medium ">
                    {t("createprofile.submit.edityourLocation.title")}
                  </p>
                  <img
                    src={edit}
                    alt="edit"
                    className="h-3 mr-6 cursor-pointer"
                    onClick={() => setShowEditLocationModal(true)}
                  ></img>
                </div>
                <p className="text-sm">
                  {userInfo?.address_street !== "undefined" &&
                    userInfo?.address_street !== "null" &&
                    userInfo?.address_street}
                </p>
                <p className="text-sm">
                  {userInfo?.city_name}, {userInfo?.post_code_name}
                </p>
                <p className="text-sm pb-8 border-b border-blue-200">
                  {userInfo?.country_code_name}, {userInfo?.country_code}
                </p>
              </div>
              <div className="mx-8 py-6 border-b border-blue-200">
                <div className="flex justify-between mb-6 items-center">
                  <p className="font-medium ">
                    {t("createprofile.submit.edityourCategories.title.card")}
                  </p>
                  <img
                    src={edit}
                    alt="edit"
                    className="h-3 cursor-pointer mr-6"
                    onClick={() => setShowEditCategoriesModal(true)}
                  />
                </div>
                {workerCategories &&workerCategories?.map((cat: any) => {
                  return (
                    <>
                      <p className="text-sm mb-4">{cat.name}</p>
                    </>
                  );
                })}
              </div>

              <div className="ml-8  py-6 border-t border-blue-200">
                <div className="flex items-center mb-6">
                  <p className="font-medium ">
                    {t("createprofile.submit.edityourJobType.title")}
                  </p>
                </div>
              </div>
              <div className="mx-8 py-6 border-t border-blue-200">
                <div className="flex items-center  mb-6">
                  <p className="font-medium ">
                    {t("createprofile.submit.edityourLanguages.title")}
                  </p>
                  <img
                    src={plus}
                    alt="plus"
                    className="h-3 ml-4 cursor-pointer"
                    onClick={() => setShowAddLanguageModal(true)}
                  />{" "}
                  <img
                    src={edit}
                    alt="edit"
                    className="h-3 cursor-pointer ml-7"
                    onClick={() => setShowEditLanguagesModal(true)}
                  />
                </div>
                {workerLanguages?.map(
                  (language: {
                    language_name: string;
                    languale_level_name: string;
                  }) => {
                    return (
                      <>
                        <p className="text-sm mb-4">
                          {language.language_name}:{" "}
                          <span className="text-xs text-[#6e7081]">
                            {language.languale_level_name}
                          </span>
                        </p>
                      </>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <div className="lg:max-w-4xl mt-8 mx-auto h-fit flex rounded">
            <div className="lg:flex-[0_0_75%] lg:max-w-[75%] bg-white border ">
              <div className="flex justify-between border-b border-blue-100 bg-white p-8">
                <p className="text-xl font-medium ">
                  {t("createprofile.submit.edityourskills.title")}
                </p>{" "}
                <img
                  src={edit}
                  alt="edit"
                  className="h-3 mt-3 cursor-pointer"
                  onClick={() => setshowEditSkillsModal(true)}
                />
              </div>
              <div className="p-8 flex flex-wrap">
                {allWorkerSkills?.map((skill: { name: string }) => {
                  return (
                    <>
                      <p
                        className={`bg-blancoGrisClaro px-2 text-xs mt-2 w-fit py-0.5 mx-1 my-1 flex items-center border rounded-xl cursor-pointer text-[#343a47]`}
                      >
                        {skill.name}
                      </p>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="lg:max-w-4xl mt-8 mx-auto h-fit flex rounded">
            <div className="lg:flex-[0_0_75%] lg:max-w-[75%] bg-white border ">
              <div className="flex justify-between border-b border-blue-100 bg-white p-8">
                <p className="text-xl font-medium">
                  {t("createprofile.submit.edityourworkexperience.title")}
                </p>{" "}
                <img
                  src={plus}
                  alt="plus"
                  className="h-3 mt-3 cursor-pointer"
                  onClick={() => setShowAddJobModal(true)}
                />
              </div>
              <div className="flex flex-col">
                {allEmployments?.map((experience: ExperienceTypes) => {
                  return (
                    <>
                      <div className="p-6 border-b border-blue-100">
                        <div className="flex justify-between items-center">
                          <div className="flex flex-col">
                            <p className="font-medium">
                              {experience.job_title} | {experience.company_name}
                            </p>
                            <p className="text-sm text-[#6e7081]">
                              {options?.map((month) => {
                                return month.id ===
                                  experience.start_date.substring(5, 7)
                                  ? month.label
                                  : "";
                              })}
                              &nbsp;
                              {experience.start_date.substring(0, 4)} -&nbsp;
                              {(!experience.end_date ||
                                experience.end_date === "0000-00-00") &&
                                "Present"}
                              {experience.end_date &&
                                options?.map((month) => {
                                  return month.id ===
                                    experience?.end_date.substring(5, 7)
                                    ? `${
                                        month.label
                                      } ${experience.end_date.substring(0, 4)}`
                                    : "";
                                })}
                            </p>
                          </div>
                          <div className="flex gap-5">
                            <div
                              onClick={() => {
                                handleEdit(experience);
                                // whatID(job.id);
                                setTimeout(() => {
                                  setShowEditJobModal(true);
                                }, 300);
                              }}
                            >
                              <img
                                src={edit}
                                alt="edit"
                                className="h-3 cursor-pointer mt-1"
                              />
                            </div>
                            <div
                              onClick={() => {
                                setJob(experience);
                                setShowDeleteJobModal(true);
                              }}
                            >
                              <img
                                src={trash}
                                alt="trash"
                                className="h-5 cursor-pointer"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-4 text-sm">
                          <p>{experience.description}</p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="lg:max-w-4xl mt-8 mx-auto h-fit flex pb-48 rounded">
            <div className="lg:flex-[0_0_75%] lg:max-w-[75%] bg-white border ">
              <div className="flex justify-between border-b border-blue-100 bg-white p-8">
                <p className="text-xl font-medium">
                  {t("createprofile.submit.edityoureducationhistory.title")}
                </p>{" "}
                <img
                  src={plus}
                  alt="plus"
                  className="h-3 mt-3 cursor-pointer"
                  onClick={() => setShowAddEducationModal(true)}
                />
              </div>
              <div className="flex flex-col ">
                {allEducations?.map((education: EducationTypes) => {
                  return (
                    <div key={education.id}>
                      <div className=" border-b p-6 border-blue-100">
                        <div className="flex justify-between items-center">
                          <div className="flex flex-col">
                            <p className="font-medium">
                              {education.institution_name}
                            </p>
                            <p className="text-sm text-[#6e7081]">
                              {education.degree && education.degree + "-"}{" "}
                              {education.area_of_study}
                            </p>
                            <p className="text-sm text-[#6e7081]">
                              {education.start_date.substring(0, 4)} -{" "}
                              {education.end_date &&
                                education.end_date.substring(0, 4)}
                            </p>
                          </div>
                          <div className="flex gap-5">
                            <img
                              src={edit}
                              alt="edit"
                              className="h-3 cursor-pointer mt-1"
                              onClick={() => handleEditEducation(education)}
                            />
                            <img
                              src={trash}
                              alt="trash"
                              className="h-5 cursor-pointer"
                              onClick={() => {
                                setEducation(education);
                                setShowDeleteEducationModal(true);
                              }}
                            />
                          </div>
                        </div>
                        <div className="mt-4 text-sm">
                          <p>{education.description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}

      {showEditCategoriesModal && (
        <ModalEditCategory
          setShowModal={setShowEditCategoriesModal}
          showEditCategoriesModal={showEditCategoriesModal}
          workerCategories={workerCategories!}
          refetchCategories={getCategories}
          setWorkerCategories={setWorkerCategories}
        />
      )}
      {showEditBudgetModal === true && (
        <ModalEditBudget
          setShowEditBudgetModal={setShowEditBudgetModal}
          showEditBudgetModal={showEditBudgetModal}
          workerDataBudget={userInfo.budget}
          refetchData={getWorkerData}
        />
      )}
      {showEditLocationModal === true && (
        <ModalEditLocation
          setShowEditLocationModal={setShowEditLocationModal}
          showEditLocationModal={showEditLocationModal}
          refetch={refetchWorkerData}
        />
      )}
      {showAddProfilePhotoModal === true && (
        <ModalAddProfilePhoto
          // handleInputPictureClick={handleInputPictureClick}
          showAddProfilePhotoModal={showAddProfilePhotoModal}
          setShowAddProfilePhotoModal={setShowAddProfilePhotoModal}
          refetchWorkerInfo={getUserInfo}
          // setPreview={setPreview}
          // preview={preview}
        />
      )}
      {showEditSkillsModal === true && (
        <EditSkillSelector
          setShowModal={setshowEditSkillsModal}
          showEditSkillsModal={showEditSkillsModal}
          workerCategories={workerCategories}
        />
      )}
      {showEditJobModal === true && (
        <ModalEditEmployment
          setShowEditModal={setShowEditJobModal}
          showEditJobModal={showEditJobModal}
          refetch={getAllExperiences}
        />
      )}
      {showAddJobModal === true && (
        <ModalAddEmployment
          setShowModal={setShowAddJobModal}
          showAddJobModal={showAddJobModal}
          refetch={getAllExperiences}

        />
      )}
      {showAddEducationModal === true && (
        <ModalAddEducation
          setShowModal={setShowAddEducationModal}
          showAddEducationModal={showAddEducationModal}
          startingYear={startingYear}
          setStartingYear={setStartingYear}
          endingYear={endingYear}
          setEndingYear={setEndingYear}
          refetch={() => getAllEducations()}
        />
      )}
      {showEditEducationModal === true && (
        <ModalEditEducation
          setShowEditEducationModal={setShowEditEducationModal}
          showEditEducationModal={showEditEducationModal}
          education={education}
          setEducation={setEducation}
          refetch={() => getAllEducations()}
        />
      )}
      {showDeleteJobModal === true && (
        <ModalDelete
          job={job}
          setShowModal={setShowDeleteJobModal}
          // showDeleteJobModal={showDeleteJobModal}
          title={"Job Experience"}
          titleJob={job?.title}
          // handleNoMoreLocal={handleNoMoreLocal}
          handleRemove={removeExperience}
          showModal={false}
        />
      )}
      {showDeleteEducationModal === true && (
        <ModalDeleteEducation
          education={education}
          handleNoMoreLocal={handleNoMoreLocal}
          setShowModal={setShowDeleteEducationModal}
          showDeleteEducationModal={showDeleteEducationModal}
          title={t("worker.profile.modal_education.title")}
        />
      )}
      {showEditTitleModal === true && (
        <ModalEditTitle
          setShowEditTitleModal={setShowEditTitleModal}
          showEditTitleModal={showEditTitleModal}
          workerDataTitle={userInfo.title}
          refetchData={getWorkerData}
        />
      )}
      {showEditBioModal === true && (
        <ModalEditBio
          setShowEditBioModal={setShowEditBioModal}
          showEditBioModal={showEditBioModal}
          refetchData={getWorkerData}
        />
      )}
      {showEditLanguagesModal === true && (
        <ModalEditLanguages
          setShowEditLanguagesModal={setShowEditLanguagesModal}
          showEditLanguagesModal={showEditLanguagesModal}
          refetch={getWorkerLanguages}
        />
      )}
      {showAddLanguageModal === true && (
        <ModalAddLanguage
          setShowAddLanguageModal={setShowAddLanguageModal}
          showAddLanguageModal={showAddLanguageModal}
          refetch={refetchWorkerData}
        />
      )}
      <SmallFooter marginRight={false} />
    </div>
  );
}

export default SubmitProfile;
