import React, { Dispatch, SetStateAction } from "react";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import {
  // dataMessagesProcessOne,
  process1WithParamNew,
} from "../../Atoms/jotaiAtoms";
import { BASE_URL, CHAT } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { JobTypes, WorkerProfileDataTypes } from "../../types/types";
import { ModalProps } from "./ModalWorkerCard";
import { useTranslation } from "react-i18next";
import useUpdateCredits from "src/Hooks/useUpdateCredits";
import { Button } from "../ui/Button";
import { AiOutlineClose } from "react-icons/ai";
import { cn } from "src/lib/utils";
import { StylesModal } from "src/Utils/stylesModal";
import {
  useActionTypesStore,
  useEmployerStore,
  userProfileStore,
} from "src/Routing/useProfileStore";
import toast from "react-hot-toast";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { decryptArray } from "src/Hooks/decryptString";

interface ModalStartChatProps {
  showModal: ModalProps;
  setShowModal: Dispatch<SetStateAction<ModalProps>>;
  clickedWorker: WorkerProfileDataTypes;
  job: JobTypes;
}

const ModalStartChat = ({
  showModal,
  setShowModal,
  clickedWorker,
  job,
}: ModalStartChatProps) => {
  const { t } = useTranslation();
  const [transition, setTransition] = useState(false);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  // const [dataProcess1, setDataProcess1] = useAtom(dataMessagesProcessOne);
  const [useParamsForStartConversation, setUseParamsForStartConversation] =
    useAtom(process1WithParamNew);
  const [navigateToMsg, setNavigateToMsg] = useState(false);
  const profileType = userProfileStore((s) => s.profileTypeToken);
  const [jobID, setJobID] = useLocalStorage("jobID", "");

  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const actions = useActionTypesStore((s: any) => s.actions);
  const { creditsDataInfo, updateCredits } = useUpdateCredits(
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.name === "Chat Job"
    )[0]?.credits,
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.name === "Chat Job"
    )[0]?.id
  );
  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);


  const startNewConversation = () => {
    fetch(
      `${BASE_URL}${CHAT}?client_id=${userID}&client_id_chat_new=${clickedWorker.client_id}&job_id=${jobID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          updateCredits();
          setUseParamsForStartConversation(clickedWorker.client_id);
          setNavigateToMsg(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleStartConversation = () => {
    if (
      Number(creditsNumber) >
      Number(
        decryptArray(actions)?.filter(
          (action: { id: string; name: string; credits: string }) =>
            action.name === "Chat Job"
        )[0]?.credits
      )
    ) {
      startNewConversation();
    } else {
      toast.error(`${t("actions.not_enough_credits")}`);
    }
  };
  useEffect(() => {
    if (navigateToMsg) {
      if (profileType === "employer") {
        navigate("/messages-em");
      } else {
        navigate("/messages");
      }
    }
  }, [navigateToMsg]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal.chat && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal.chat]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal((prev) => ({ ...prev, chat: false }));
    }, 150);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <Modal isOpen={showModal.chat} style={StylesModal}>
      <div
        className={cn(
          "fixed z-10 transition duration-150 w-[90%] py-4 lg:w-[500px] left-[50%] translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]"
        )}
        ref={ref}
      >
        <div className="flex justify-between items-center border-b py-2 mb-6 p-4">
          <div className="text-xl font-semibold   pl-4">
            {t("employer.modal.start_chat.title")}
          </div>
          <Button
            className="h-6 w-6 rounded-md p-0 bg-gray-200 flex justify-center items-center"
            onClick={() => closeModal()}
          >
            <AiOutlineClose className="h-4 w-4" />
          </Button>
        </div>
        <div className=" px-8 py-6">
          {t("employer.modal.start_chat.desc")}{" "}
          <span className="italic font-semibold text-azulBonico">
            {clickedWorker?.first_name + " " + clickedWorker?.last_name}
          </span>{" "}
          {t("employer.modal.start_chat.desc2")}{" "}
          {/* <span className="italic font-semibold text-azulBonico">
            {job?.title}
          </span> */}
          <div className=" ">
            <div className="flex items-center mt-2">
              <p className="text-xs text-neutral-400">
                {Number(
                  decryptArray(actions)?.filter(
                    (action: { id: string; name: string; credits: string }) =>
                      action.name === "Chat Job"
                  )[0]?.credits
                )}{" "}
                {t("employer.applicants.view_jobpost.modal.clone.credits")}
              </p>
              <QuestionMarkCircleIcon className="h-4 w-4 ml-1 text-neutral-400" />
            </div>
          </div>
        </div>
        <div className="flex w-full h-full p-4 gap-2 justify-end">
          {/* <button
               className="border border-azulBonico font-semibold text-azulBonico w-32  rounded-3xl text-[0.9rem] px-[1rem]  cursor-pointer transition duration-300 h-10 hover:bg-blancoGrisClaro"
               onClick={() => closeModal()}
             >
               {t("employer.modal.start_chat.cancel")}
             </button> */}
          <button
            className={`border-none font-semibold text-white w-32 rounded-[20px] text-[0.9rem] px-[1rem] cursor-pointer transition duration-300 h-10 bg-azulBonico hover:bg-azulOscuro `}
            onClick={() => handleStartConversation()}
          >
            {t("employer.modal.start_chat.continue")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalStartChat;
