import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsChatDots, BsExclamationCircle, BsThreeDots } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import ModalInviteWorker from "src/Components/Modals/ModalInviteWorker";
import AvailableDays from "src/Components/Time/AvailableDays";
import { BASE_URL, BASE_URL_IMG } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { useModalStore } from "src/Hooks/useModalStore";
import ReviewCard from "src/Pages/Worker/Profile/ReviewCard";
import { options } from "src/lib/months";
import { cn } from "src/lib/utils";
import {
  EducationWorkerProfile,
  EmploymentWorkerProfile,
  FindWorker,
  LanguagesWorkerProfile,
  ReviewTypes,
  SkillsWorkerProfile,
} from "src/types/types";

import UserProfile from "src/assets/img/user-worker.png";
import GoBackButton from "src/Components/ui/GoBack";
import ModalInviteToJobWorker from "src/Components/Modals/ModalInviteToJobWorker";
import { FireIcon } from "@heroicons/react/24/solid";

import { toast } from "react-hot-toast";
import RatingStars from "src/Components/Rating/RatingStars";
import { HandleMonthName, HandleMonthNameES } from "src/Utils/HandleMonthName";

const WorkerProfileScreen = () => {
  const [showModalInvite, setShowModalInvite] = useModalStore((state) => [
    state.showModalInvite,
    state.setShowModalInvite,
  ]);
  const [showReportOptions, setShowReportsOptions] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const params = useParams();
  const [employments, setEmployments] = useState([]);
  const [educations, setEducations] = useState<EducationWorkerProfile[]>([]);
  const [skills, setSkills] = useState<SkillsWorkerProfile[]>([]);
  const [languages, setLanguages] = useState<LanguagesWorkerProfile[]>([]);
  const [chosenTimes, setChosenTimes] = useState([]);
  const [workerScore, setWorkerScore] = useState();
  const [reviews, setReviews] = useState<ReviewTypes[]>([]);
  const { t } = useTranslation();
  const [workerProf, setWorkerProf] = useState<{
    first_name: string;
    last_name: string;
  }>({ first_name: "", last_name: "" });
  const navigate = useNavigate();
  const [showInviteOptionsMenu, setShowInviteOptionsMenu] = useModalStore(
    (state) => [state.showInviteOptionsMenu, state.setShowInviteOptionsMenu]
  );
  const [workerProfile, setWorkerProfile] = useState<FindWorker>();
  const { i18n } = useTranslation();
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showReportOptions &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setShowReportsOptions(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showReportOptions]);

  const getWorkerData = async () => {
    const data = await fetch(
      `${BASE_URL}worker.profile?client_id=${params.id}`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: workerInfo, isLoading } = useQuery(
    ["workerInfo", params.id],
    getWorkerData
  );

  const getWorkerInformation = async () => {
    const data = await fetch(`${BASE_URL}workers?client_id=${params.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: workerData } = useQuery(
    ["worker", params.id],
    getWorkerInformation
  );

  useEffect(() => {
    setEmployments(workerData?.workerExperience);
    setEducations(workerData?.workerEducation);
    setSkills(workerData?.workerSkills);
    setLanguages(workerData?.workerLanguage);
    setChosenTimes(workerData?.workerProfileTime);
    setWorkerScore(workerData?.score);
    setWorkerProf(workerData?.data?.[0]);
  }, [workerData]);

  const getReviews = async () => {
    const data = await fetch(
      `${BASE_URL}connections.reviews?client_id=${params.id}`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: reviewsData } = useQuery(["reviews", params.id], getReviews);

  useEffect(() => {
    setReviews(reviewsData?.reviews);
  }, [reviewsData]);

  const getWorkerDiscovered = async () => {
    const data = await fetch(
      `${BASE_URL}discover.workers?client_id_employer=${userID}&client_id_worker=${params.id}`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    ).then((res) => res.json());

    return data.result[0];
  };

  const { data } = useQuery(
    ["worker_discovered", params.id],
    getWorkerDiscovered
  );

  useEffect(() => {
    setWorkerProfile(data);
  }, [data]);

  const cancelInvite = (jobId: string) => {
    fetch(
      `${BASE_URL}employer.invite.workers?client_id=${userID}&job_id=${jobId}&client_id_worker=${params.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setWorkerProfile((prev: any) => ({
            ...prev,
            invites: prev.invites.filter(
              (invite: { job_id: string }) => invite.job_id !== jobId
            ),
          }));
          toast.success(`${t("worker.profile.invitation.cancel_invite")}`);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div
        className="flex items-center gap-1 fill-azulBonico cursor-pointer text-azulBonico hover:underline my-8 mx-4"
        onClick={() => navigate(-1)}
      >
        <GoBackButton />
      </div>
      <div className="flex w-full bg-white rounded border py-10  px-4 relative pt-16">
        <div className="absolute w-full h-24 bg-[#2563eb] bg-gradient-to-r from-[#2563eb]  to-azulBonico top-0 left-0 rounded-t" />
        <div className="absolute top-6 right-10">
          <div
            onClick={() => setShowReportsOptions(!showReportOptions)}
            className={cn(
              "h-8 w-8 bg-white rounded-full justify-center items-center flex cursor-pointer",
              showReportOptions &&
                " border-white border-2 bg-azulBonico transition"
            )}
          >
            <BsThreeDots
              className={cn(
                "h-4 w-4",
                showReportOptions && "text-white transition"
              )}
            />
          </div>
        </div>
        {showReportOptions && (
          <div ref={modalRef}>
            <div className="absolute bg-white top-20 right-4 transition w-48 borderrounded p-2">
              <div
                className={`bg-white border-l border-t z-20 w-4 h-4 rotate-[45deg] absolute -top-2 right-8 `}
              />
              <div className="flex items-center gap-2 justify-center cursor-pointer">
                <BsExclamationCircle />
                <div>{t("employer.messages.modal_chat.report_user")}</div>
              </div>
            </div>
          </div>
        )}
        <div className="flex items-end justify-start w-full">
          <img
            src={
              workerInfo?.workerProfile?.profile_image
                ? BASE_URL_IMG + workerInfo?.workerProfile?.profile_image
                : UserProfile
            }
            className="w-20 h-20 lg:h-52 lg:w-52 rounded object-cover absolute lg:relative top-2 z-10"
          />
          <div className="flex items-center justify-between ml-12 mt-10 lg:mt-0 w-full pb-5">
            <div className="flex flex-col ">
              <h1 className="text-lg lg:text-3xl font-semibold">
                {workerProf?.first_name} {workerProf?.last_name}
              </h1>
              <h1 className="text-sm lg:text-xl font-medium">
                {workerInfo?.workerProfile?.title}
              </h1>
              <div className="flex items-start gap-1 text-slate-500 text-sm mt-4">
                <ImLocation className="h-4 w-4" />
                <p>{workerInfo?.workerProfile?.post_code_name}</p>
              </div>
            </div>
            <div className="flex items-center ml-auto  border p-4 mt-2 rounded">
              <div className="flex items-start justify-between w-full ">
                <div className=" mr-10">
                  <RatingStars
                    size="14"
                    activeStar={workerData?.score?.avgScore}
                    score={workerData?.score?.avgScore}
                    evaluation={false}
                    reviews={true}
                    showHalf={true}
                    info={{ totalScores: workerData?.score?.totalScores }}
                  />
                </div>
                <ul className="flex flex-col gap-2 mt-2 w-full text-gray-700">
                  <li className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">
                      {t("admin.analytics.jobs")}
                    </span>
                    <span className="font-medium">
                      {workerData?.score?.totalConnections}
                    </span>
                  </li>
                  <li className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">
                      {t("dashboard_right_container_jobs_cancelled")}
                    </span>
                    <span className="font-medium">
                      {workerData?.score?.totalConnectionsTerminated}
                    </span>
                  </li>
                  <li className="text-sm text-gray-400  mt-2 text-right">
                    {t("worker.dashboard.member_since")}{" "}
                    {i18n.language === "en"
                      ? HandleMonthName(
                          workerData?.score?.date_joined?.slice(5, 7)
                        )
                      : HandleMonthNameES(
                          workerData?.score?.date_joined?.slice(5, 7)
                        )}{" "}
                    {workerData?.score?.date_joined?.slice(0, 4)}
                  </li>
                </ul>
              </div>

              {workerProfile?.data?.date_featured && (
                <div className="flex items-center gap-1 px-2 py-1 text-sm border rounded-full ml-4">
                  <FireIcon className="text-orange-500 h-4 w-4" />
                  <p className="text-orange-500 hidden lg:block">
                    {t("employer.find_workers.sort_featured")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {workerProfile?.invites?.length === 0 ? ( */}
        <div
          className="flex items-center justify-end absolute bottom-4 bg-azulBonico px-3 text-white hover:bg-azulOscuro rounded-full transition text-sm font-medium right-4 space-x-2  border-azulBonico  py-1  cursor-pointer "
          onClick={() => setShowInviteOptionsMenu(showInviteOptionsMenu)}
        >
          {t("employer.invite_workers.invite_to_job")}
        </div>
      </div>
      <div className="flex flex-col lg:grid grid-cols-3">
        <div className="col-span-1 h-full   my-4  ">
          <div className="flex items-center justify-between w-full border-b bg-white rounded border p-4">
            <h1 className="text-lg font-medium py-2">
              {t("employer.findworkers.viewprofile.rightBtn.yes.others.title")}
            </h1>
            <BsChatDots />
          </div>
          <div className="py-4 bg-white rounded border p-4">
            <div className="border-b border-border ">
              <div className="flex items-center mb-6">
                <p className="font-medium text-sm">
                  {t("worker.profile.languages")}
                </p>
              </div>
              {languages?.map((language) => (
                <div
                  key={language.worker_language_id}
                  className="flex flex-col items-start text-sm mb-4"
                >
                  {language.language_name}
                  <span className="text-gray-500">
                    {language.languale_level_name}
                  </span>
                </div>
              ))}
            </div>
            <div className="border-b border-border py-4">
              <div className="flex mt-5 mb-2 items-center gap-8">
                <p className="font-medium">
                  {t("worker.profile.available_hours")}
                </p>
              </div>
              {chosenTimes?.length > 0 ? (
                <AvailableDays allChosenTimes={chosenTimes} />
              ) : (
                <p className="text-sm text-gray-500">
                  {t("worker.profile.no_time")}
                </p>
              )}
            </div>
            <div className="border-b border-border py-4">
              <div className="flex items-center">
                <p className="font-medium text-lg">
                  {t("worker.profile.skills")}
                </p>
              </div>
              <div className="flex flex-wrap mt-4">
                {skills?.length > 0 &&
                  skills?.map((skill) => {
                    return (
                      <p
                        key={skill.skill_id}
                        className="bg-blancoGrisClaro px-2 text-xs mt-2 flex flex-wrap w-fit py-0.5 mx-1 my-1 items-center border rounded-xl text-[#343a47]"
                      >
                        {skill.name}
                      </p>
                    );
                  })}
                {skills?.length === 0 && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {t("skills.no_skills.empty_container")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2 ">
          <div className="bg-white rounded border p-4 my-4 ml-4">
            <h1 className="text-xl font-medium">
              {t(
                "employer.findworkers.viewprofile.rightBtn.yes.overview.title"
              )}
            </h1>

            <h3 className="my-3 text-sm">
              {workerInfo?.workerProfile?.overview}
            </h3>
            {!workerInfo?.workerProfile?.overview && (
              <div className="mt-2">
                <h3 className="text-gray-500 text-sm">
                  {t("employer.findworkers.viewprofile.no_overview")}
                </h3>
              </div>
            )}
          </div>

          <div className="bg-white rounded border p-4 my-4 ml-4">
            <h1 className="text-xl font-medium">
              {t("worker.profile.employment_history")}
            </h1>

            {employments?.length > 0 &&
              employments?.map((experience: EmploymentWorkerProfile) => {
                return (
                  <div
                    key={experience.job_title}
                    className=" py-4 border-b border-gray-200"
                  >
                    <div className="flex justify-between items-center">
                      <p className="font-medium">
                        {experience.job_title} | {experience.company_name}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="text-sm text-[#7b7f80b5]">
                        {options?.map((month) => {
                          return month.id ===
                            experience.start_date.substring(5, 7)
                            ? month.label
                            : "";
                        })}
                        &nbsp;
                        {experience.start_date.substring(0, 4)} - &nbsp;
                        {(!experience.end_date ||
                          experience.end_date === "0000-00-00") &&
                          "Present"}
                        {experience.end_date &&
                          options?.map((month) => {
                            return month.id ===
                              experience?.end_date.substring(5, 7)
                              ? `${month.label} ${experience.end_date.substring(
                                  0,
                                  4
                                )}`
                              : "";
                          })}
                      </p>
                      <p className="text-sm">{experience.description}</p>
                    </div>
                  </div>
                );
              })}
            {employments?.length === 0 && (
              <div className="mt-2">
                <p className="text-gray-500 text-sm">
                  {t("worker.profile.work_experience_empty")}
                </p>
              </div>
            )}
          </div>
          <div className="bg-white rounded border p-4 my-4 ml-4">
            <h1 className="text-xl font-medium">
              {t("worker.profile.education")}
            </h1>
            {educations?.length > 0 &&
              educations?.map((education) => {
                return (
                  <>
                    <div className="flex items-start justify-between  pr-3 mb-4 mt-2">
                      <div className="flex flex-col w-[70%]">
                        <p className="font-medium">
                          {education.institution_name}
                        </p>

                        <p className="text-sm">
                          {education.degree && `${education.degree},`}
                          &nbsp; {`${education.area_of_study} `}{" "}
                        </p>
                        <p className="text-sm text-gray-500">
                          {education.start_date.substring(0, 4)}{" "}
                          {education.end_date &&
                            `- ${education.end_date.substring(0, 4)}`}
                        </p>
                      </div>
                    </div>
                  </>
                );
              })}
            {educations?.length === 0 && (
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t("employer.findworkers.viewprofile.no_education")}
                </p>
              </div>
            )}
          </div>

          <div className="bg-white rounded border p-4 my-4 ml-4">
            <h1 className="text-xl font-medium mb-4">
              {t("employer.findworkers.viewprofile.reviews")}
            </h1>
            <div className="">
              {reviews?.length > 0 && (
                // <div className="px-6 pt-3">
                <div className="text-sm border border-gray-200 bg-white dark:border-border dark:bg-background flex flex-col rounded">
                  <div className="border-b border-gray-200 dark:border-border">
                    <p className="font-medium text-lg tracking-wide p-8">
                      {t("worker.profile.employment_history")}
                    </p>
                  </div>
                  <div>
                    {reviews?.map((review) => (
                      <ReviewCard key={review.id} review={review} />
                    ))}
                  </div>
                </div>
              )}
              {reviews?.length === 0 && (
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {t("employer.findworkers.viewprofile.no_reviews")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      {showModalInvite && <ModalInviteWorker />}

      {params.id && showInviteOptionsMenu && (
        <ModalInviteToJobWorker
          refetch={getWorkerDiscovered}
          workerID={params.id}
          // workerInvitedToJobs={worer.invites}
        />
      )}
    </>
  );
};

export default WorkerProfileScreen;
