import React, { useEffect, useState, useRef, lazy, Suspense } from "react";
import edit from "../../../assets/img/edit.png";
import { ReactComponent as Addition } from "../../../assets/img/plus.svg";
import { ReactComponent as Trash } from "../../../assets/img/trash-can.svg";
import { ReactComponent as Remote } from "../../../assets/img/home-office.svg";
import { ReactComponent as Play } from "../../../assets/img/play.svg";
import { ReactComponent as Circle } from "../../../assets/img/circle.svg";
import { ReactComponent as Settings } from "../../../assets/img/settings.svg";
import { ReactComponent as HomeOffice } from "../../../assets/img/home-office.svg";
import { ReactComponent as InPerson } from "../../../assets/img/in-person.svg";

import {
  BADGE_AVAILABLE,
  BASE_URL,
  BASE_URL_IMG,
  BOOST_PROFILE,
  CONNECTION_REVIEWS,
  INFO_WORKER,
  PROFILE_WORKER,
} from "../../../Config/api.config";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { useNavigate } from "react-router-dom";

const ModalAddEmployment = lazy(
  () => import("src/Components/Modals/ModalAddEmployment")
);
const ModalDelete = lazy(
  () => import("src/Components/InitialClientForm/ModalDelete")
);
const ModalEditEmployment = lazy(
  () => import("src/Components/Modals/ModalEditEmployment")
);
const ModalAddEducation = lazy(
  () => import("src/Components/Modals/ModalAddEducation")
);
const ModalDeleteEducation = lazy(
  () => import("src/Components/Modals/ModalDeleteEducation")
);
const ModalEditEducation = lazy(
  () => import("src/Components/Modals/ModalEditEducation")
);
const ModalAddLanguage = lazy(
  () => import("src/Components/Modals/ModalAddLanguage")
);
const ModalEditLanguages = lazy(
  () => import("src/Components/Modals/ModalEditLanguages")
);
const ModalAddVideoIntroduction = lazy(
  () => import("src/Components/Modals/ModalAddVideoIntroduction")
);
const ModalEditTitle = lazy(
  () => import("src/Components/Modals/ModalEditTitle")
);

const EditSkillSelector = lazy(
  () => import("src/Components/Worker/Registration/EditSkillSelector")
);

const ModalAddProfilePhoto = lazy(
  () => import("src/Components/Modals/ModalAddProfilePhoto")
);
const ModalEditBudget = lazy(
  () => import("src/Components/Modals/ModalEditBudget")
);
const ModalAddAnotherExperience = lazy(
  () => import("src/Components/Modals/ModalAddAnotherExperience")
);
const ModalEditBio = lazy(() => import("src/Components/Modals/ModalEditBio"));
const ModalVideoIntroduction = lazy(
  () => import("src/Components/Modals/ModalVideoIntroduction")
);
const ModalDeleteGeneral = lazy(
  () => import("src/Components/Modals/ModalDeleteGeneral")
);
const ModalEditAvailability = lazy(
  () => import("src/Components/Modals/ModalEditAvailability")
);

import {
  allWorkerExployments,
  navBarSettingsWorker,
  whichModal,
} from "../../../Atoms/jotaiAtoms";
import { useAtom } from "jotai";
import ModalEditCategory from "../../../Components/Modals/ModalEditCategory";

import RatingStars from "../../../Components/Rating/RatingStars";
import { options } from "src/lib/months";
import ModalEditSchedule from "../../../Components/Modals/ModalEditSchedule";
import AvailableDays from "../../../Components/Time/AvailableDays";
import {
  ReviewTypes,
  WorkerInfo,
  WorkerProfileDataTypes,
} from "../../../types/types";
import { useTranslation } from "react-i18next";
// import ModalEditJobType from "src/Components/Modals/ModalEditJobType";
import ModalEditLocation from "src/Components/Modals/ModalEditLocation";
import { FireIcon, TrashIcon } from "@heroicons/react/24/solid";
import ReviewCard from "./ReviewCard";
import { PencilIcon } from "@heroicons/react/24/solid";
import GoBackButton from "src/Components/ui/GoBack";
import { useModalStore } from "src/Hooks/useModalStore";
import ModalFeaturedProfile from "./ModalFeaturedProfile";
import { cn } from "src/lib/utils";
import { toast } from "react-hot-toast";
import { useQuery } from "react-query";
import LoadingSpinner from "src/Pages/General/Register/LoadingSpinner";
import { GrLocation } from "react-icons/gr";
import ModalAvailableProfile from "./ModalAvailableProfile";
import { useActionTypesStore } from "src/Routing/useProfileStore";
import useUpdateCredits from "src/Hooks/useUpdateCredits";
import { decryptArray } from "src/Hooks/decryptString";
import { useFormatActions } from "src/Routing/useCreditsActions";
import { BsLightning } from "react-icons/bs";

function Profile() {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [showEditJobModal, setShowEditJobModal] = useState(false);
  const [showAddEducationModal, setShowAddEducationModal] = useState(false);
  const [showEditBudgetModal, setShowEditBudgetModal] = useState(false);
  const [showDeleteVideoModal, setShowDeleteVideoModal] = useState(false);
  const [openModal, setOpenModal] = useAtom(whichModal);
  const [endingYear, setEndingYear] = useState("");
  const [startingYear, setStartingYear] = useState("");
  const [allEmployments, setAllEmployments] = useAtom(allWorkerExployments);
  const [showEditEducationModal, setShowEditEducationModal] = useState(false);
  const [showModalEditSchedule, setShowModalEditSchedule] = useState(false);
  const [showAddProfilePhotoModal, setShowAddProfilePhotoModal] =
    useState(false);
  const [showEditSkillsModal, setShowEditSkillsModal] = useState(false);
  const [showModalVideoIntroduction, setShowModalVideoIntroduction] =
    useState(false);
  const [showEditTitleModal, setShowEditTitleModal] = useState(false);
  const [showAddVideoIntroduction, setShowAddVideoIntroduction] =
    useState(false);
  const [picturePreview, setPicturePreview] = useLocalStorage("profileImg", "");
  const [imageUrlPlayer, setImageUrlPlayer] = useState();
  const [showAddLanguageModal, setShowAddLanguageModal] = useState(false);
  const [showEditLanguageModal, setShowEditLanguageModal] = useState(false);
  const [showEditLocationModal, setShowEditLocationModal] = useState(false);
  const [showModalAvailability, setShowModalAvailability] = useState(false);
  const [showDeleteEducationModal, setShowDeleteEducationModal] =
    useState(false);
  const [showEditJobType, setShowEditJobType] = useState(false);
  const [preview, setPreview] = useLocalStorage("profile_img", "");
  const [activeStar, setActiveStar] = useState();
  const [showAddJobModal, setShowAddJobModal] = useState(false);
  const [showAddAnotherExperienceModal, setShowAddAnotherExperienceModal] =
    useState(false);
  const [showEditBioModal, setShowEditBioModal] = useState(false);
  const [showDeleteJobModal, setShowDeleteJobModal] = useState(false);
  const [job, setJob] = useLocalStorage("dataJobEdit", []);
  const [education, setEducation] = useState<{
    id: string;
    institution_name: string;
    degree: string;
    area_of_study: string;
    start_date: string;
    end_date: string;
  }>({
    id: "",
    institution_name: "",
    degree: "",
    area_of_study: "",
    start_date: "",
    end_date: "",
  });
  const [allEducations, setAllEducations] = useState<
    Array<{
      id: string;
      institution_name: string;
      degree: string;
      area_of_study: string;
      start_date: string;
      end_date: string;
    }>
  >([]);
  const [workerCategories, setWorkerCategories] = useState<
    Array<{ category_id: string; name: string }>
  >([]);
  const [allLanguages, setAllLanguages] =
    useState<Array<{ language_name: string; languale_level_name: string }>>();
  const [allSkills, setAllSkills] = useState<
    Array<{ name: string; skill_id: string }>
  >([]);
  const [workerProfile, setWorkerProfile] = useState<WorkerProfileDataTypes>();
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const [decorationBar, setDecorationBar] = useAtom(navBarSettingsWorker);
  const [allChosenTimes, setAllChosenTimes] = useLocalStorage("workTimes", []);
  const [now, setNow] = useState("");
  const [showEditCategoriesModal, setShowEditCategoriesModal] = useState(false);
  const [showPopover, setShowPopover] = useState<string | null>();
  const [reviews, setReviews] = useState<ReviewTypes[]>([]);
  const [workerScore, setWorkerScore] = useState<{
    totalScores: string;
    avgScore: string;
  }>({ avgScore: "", totalScores: "" });
  const [availability, setAvailability] = useLocalStorage(
    "Availability",
    "Available now"
  );
  const [featureStatus, setFeatureStatus] = useState(false);
  const navigate = useNavigate();
  const [videoUrl, setVideoUrl] = useState<string | null>("");

  const [showModalFeaturedProfile, setShowModalFeaturedProfile] = useModalStore(
    (state) => [
      state.showModalFeaturedProfile,
      state.setShowModalFeaturedProfile,
    ]
  );
  const [showModalAvailableProfile, setShowModalAvailableProfile] =
    useModalStore((state) => [
      state.showModalAvailableProfile,
      state.setShowModalAvailableProfile,
    ]);
  const [paramsActions, setParamsActions] = useFormatActions((state) => [
    state.formatTypes,
    state.setFormatTypes,
  ]);
  const { t } = useTranslation();
  const actions = useActionTypesStore((s: any) => s.actions);
  const [featureValueCreditsLap, setFeatureValueCreditsLap] = useState("");
  const [availableValueCreditsLap, setAvailableValueCreditsLap] = useState("");
  const { updateCredits } = useUpdateCredits(
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.id === "18"
    )[0]?.credits,
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.id === "18"
    )[0]?.id
  );
  const { updateCredits: updateCreditsAvailable } = useUpdateCredits(
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.id === "19"
    )[0]?.credits,
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.id === "19"
    )[0]?.id
  );
  useEffect(() => {
    if (typeof paramsActions === "string" && paramsActions) {
      setAvailableValueCreditsLap(
        JSON.parse(paramsActions)?.filter(
          (param: { id: string; name: string; value: string }) =>
            param.name === "WORKER_PROFILE_LAP_BADGE"
        )[0]?.value
      );
      setFeatureValueCreditsLap(
        JSON.parse(paramsActions)?.filter(
          (param: { id: string; name: string; value: string }) =>
            param.name === "WORKER_PROFILE_LAP_BOOST"
        )[0]?.value
      );
    } else {
      refetchParams();
    }
  }, [paramsActions]);
  const getParamsTypes = async () => {
    const data = await fetch(`${BASE_URL}system.params`, {
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return data.result;
  };

  const { data: paramsData, refetch: refetchParams } = useQuery(
    ["params"],
    getParamsTypes
  );

  useEffect(() => {
    setParamsActions(paramsData?.params);
  }, [paramsData]);

  useEffect(() => {
    if (openModal === "Employment History") setShowAddJobModal(true);
    if (openModal === "Video introduction") setShowAddVideoIntroduction(true);
    if (openModal === "Education") setShowAddEducationModal(true);
    if (openModal === "Profile Photo") setShowAddProfilePhotoModal(true);
    if (openModal === "Overview") setShowEditBioModal(true);
    if (openModal === "Skills") setShowEditSkillsModal(true);
  }, [openModal]);

  const getWorkerInformation = async () => {
    const data = await fetch(`${BASE_URL}${INFO_WORKER}?client_id=${userID}`, {
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const {
    data: profileData,
    isLoading,
    refetch: refetchWorkerData,
  } = useQuery(["worker_info"], getWorkerInformation);

  useEffect(() => {
    setAllEmployments(profileData?.workerExperience);
    setAllEducations(profileData?.workerEducation);
    setAllSkills(profileData?.workerSkills);
    setAllLanguages(profileData?.workerLanguage);
    setAllChosenTimes(profileData?.workerProfileTime);
    setWorkerScore(profileData?.score);
  }, [profileData]);

  const getWorkerData = async () => {
    const data = await fetch(
      `${BASE_URL}${PROFILE_WORKER}?client_id=${userID}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };
  const {
    data: workerInfo,
    refetch: refetchWorkerInfo,
    isLoading: isLoadingWorker,
  } = useQuery<WorkerInfo>(["worker_key", userID], getWorkerData);

  useEffect(() => {
    setWorkerProfile(workerInfo?.workerProfile);
    setVideoUrl(workerInfo?.workerProfile?.profile_video ?? "");
  }, [workerInfo]);

  const getCategories = async () => {
    const response = await fetch(
      `${BASE_URL}worker.category?client_id=${userID}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  const { data: workerCategoriesData, isLoading: loadingWorkerCategories } =
    useQuery({
      queryKey: ["categories"],
      queryFn: getCategories,
    });

  useEffect(() => {
    setWorkerCategories(workerCategoriesData?.result);
  }, [workerCategoriesData]);

  const getReviews = async () => {
    const data = await fetch(
      `${BASE_URL}${CONNECTION_REVIEWS}?client_id=${userID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: reviewsData } = useQuery(["reviews"], getReviews);

  useEffect(() => {
    setReviews(reviewsData?.reviews);
  }, [reviewsData]);

  const dataVideo = new FormData();
  dataVideo.append("_method", "PUT");
  dataVideo.append("client_id", userID);
  dataVideo.append("profile_video", "");
  dataVideo.append("profile_video_type", "");
  const deleteVideo = () => {
    fetch(`${BASE_URL}worker.profile`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: dataVideo,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getWorkerData();
    getCategories();
    getReviews();
  }, []);

  const removeExperience = () => {
    setAllEmployments(
      allEmployments?.filter((items: { id: string }) => items.id !== job.id)
    );
  };

  const handleNoMoreLocal = () => {
    setJob();
    setEducation({
      area_of_study: "",
      degree: "",
      id: "",
      end_date: "",
      institution_name: "",
      start_date: "",
    });
  };
  const date = new Date();
  useEffect(() => {
    setNow(date.getHours() + ":" + date.getMinutes());
  }, []);
  const inputPicture = useRef<HTMLInputElement>(null);
  const handleInputPictureForm = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const reader = new FileReader();
    setImageUrlPlayer(e.target.files[0]);
    const file = e.target.files[0];
    setPicturePreview(URL.createObjectURL(file));
  };
  const handleInputPictureClick = () => {
    inputPicture.current?.click();
  };

  const handleMouseEnter = (props: string) => {
    if (props === "settings") setShowPopover("settings");
  };
  const handleMouseLeave = () => {
    setShowPopover(null);
  };

  const isFeatured = async () => {
    const data = await fetch(
      `${BASE_URL}${BOOST_PROFILE}?client_id_worker=${userID}`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: featuredData, refetch: refetchFeatured } = useQuery(
    ["featured", userID],
    isFeatured
  );

  const isAvailable = async () => {
    const data = await fetch(
      `${BASE_URL}${BADGE_AVAILABLE}?client_id_worker=${userID}`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: availableData, refetch: refetchAvailable } = useQuery({
    queryKey: ["available", userID],
    queryFn: isAvailable,
  });

  useEffect(() => {
    if (featuredData?.boostProfile?.length > 0) {
      setFeatureStatus(true);
    } else if (featuredData?.boostProfile?.length === 0) {
      setFeatureStatus(false);
    }
  }, [featuredData]);

  const featureWorkerData = new FormData();
  featureWorkerData.append("_method", "POST");
  featureWorkerData.append("client_id_worker", userID);

  const featureWorker = async (laps: string) => {
    featureWorkerData.append("laps", laps);
    const response = await fetch(`${BASE_URL}${BOOST_PROFILE}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
      body: featureWorkerData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setShowModalFeaturedProfile(showModalFeaturedProfile);
          refetchFeatured();

          updateCredits(Number(laps));
        } else if (data.status === "error") {
          toast.error(`${data?.result.error_msg}`);
        }
      })
      .catch((err) => console.log(err));

    return response;
  };

  const badgeData = new FormData();
  badgeData.append("_method", "POST");
  badgeData.append("client_id_worker", userID);

  const availableBadge = async (laps: string) => {
    badgeData.append("laps", laps);

    const response = await fetch(`${BASE_URL}${BADGE_AVAILABLE}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
      body: badgeData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setShowModalAvailableProfile(showModalAvailableProfile);
          updateCreditsAvailable(Number(laps));
          refetchAvailable();
        } else if (data.status === "error") {
          toast.error(`${data.result.error_msg}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  return (
    <>
      <div className="">
        <div
          className="flex items-center mt-6 gap-2 fill-azulBonico cursor-pointer text-azulBonico hover:underline"
          onClick={() => navigate("/dashboard-worker")}
        >
          <GoBackButton />
        </div>
        <div className="flex flex-col w-full bg-white  mt-8  border rounded dark:bg-background dark:border-border dark:text-whiteish dark:border">
          <div className="flex justify-between pt-6 px-4 lg:px-8 pb-8 border-b gap-10 text-sm border-gray-200 ">
            <div className="flex ">
              <div>
                <Circle
                  // fill={"#14A800"}
                  fill={`${
                    availability === "Available now"
                      ? "#14A800"
                      : availability === "Listening offers..."
                      ? "#FF7D01"
                      : "#94b8a3"
                  }`}
                  className="h-5 relative top-[50px] lg:top-[72px] left-[56px] lg:left-[94px] border-[4px] border-white rounded-full"
                />
              </div>
              {isLoadingWorker ? (
                <div className="h-16 w-16 lg:h-24  lg:w-24 object-cover rounded-full bg-white items-center justify-center flex">
                  <LoadingSpinner />
                </div>
              ) : (
                <img
                  src={`${BASE_URL_IMG}${workerProfile?.profile_image}`}
                  alt="profile"
                  className="h-16 w-16 lg:h-24  lg:w-24 object-cover rounded-full"
                  onError={(e: any) => {
                    // If the image fails to load, replace it with a placeholder
                    e.target.src = require("src/assets/img/account-photo.png");
                  }}
                />
              )}

              <div
                className="relative cursor-pointer -top-5 right-16 lg:right-24"
                onClick={() => setShowAddProfilePhotoModal(true)}
              >
                <div className="relative mt-3">
                  <Addition
                    fill={"#4F5D73"}
                    className="h-8 cursor-pointer rounded-full bg-slate-200 p-2"
                  />
                </div>
                <input
                  ref={inputPicture}
                  type="file"
                  placeholder="Open a image"
                  accept="image/png, image/jpeg, image/gif*"
                  className="hidden"
                  name="image"
                  onChange={handleInputPictureForm}
                />
              </div>
              <div className="flex flex-col">
                <p className="text-lg lg:text-2xl font-extrafont-medium">
                  {profileData?.data[0]?.first_name}{" "}
                  {profileData?.data[0]?.last_name}
                </p>

                <div className="flex items-center gap-3 mt-2">
                  {workerProfile?.job_type == "1" ||
                  workerProfile?.job_type == "3" ||
                  workerProfile?.job_type == "4" ? (
                    <>
                      <GrLocation className="h-4 w-4" />
                      {workerProfile?.city_name !== null ? (
                        <p>
                          {workerProfile?.city_name},{" "}
                          {workerProfile?.country_code_name}
                        </p>
                      ) : (
                        <p>{workerProfile?.country_code_name}</p>
                      )}
                    </>
                  ) : (
                    <>
                      <Remote className="h-4 w-fit" />
                      <p>{t("worker.edit_job_type.modal.work_type.remote")}</p>
                    </>
                  )}
                </div>
                <div className="flex flex-col lg:flex-row items-start lg:items-center mt-2 gap-1">
                  <div className="mt-2 ">
                    <RatingStars
                      activeStar={workerScore?.avgScore}
                      score={workerScore?.avgScore}
                      evaluation={false}
                      reviews={true}
                      showHalf={true}
                      info={workerScore}
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    {featureStatus && (
                      <div
                        className={cn(
                          "flex items-center gap-3 border rounded-full text-sm ml-4 px-4 py-1 border-orange-400",
                          showModalFeaturedProfile && "bg-gray-100"
                        )}
                      >
                        <FireIcon
                          className={cn(
                            "h-4 w-4 ",
                            featureStatus ? "text-orange-500" : "text-gray-500"
                          )}
                        />

                        <p className="text-[#333]">Boosted</p>
                      </div>
                    )}
                    {availableData?.badgeProfile?.length > 0 ? (
                      <div className="flex items-center gap-2 px-4 py-1 bg-blue-50 border border-blue-400 rounded-full">
                        <BsLightning className="text-blue-400" />
                        <p className="text-sm font-medium text-blue-600">
                          Available Now
                        </p>
                      </div>
                    ) : (
                      <div className="flex items-center gap-2 px-4 py-1 bg-blue-50 border border-gray-500 rounded-full">
                        <BsLightning className="text-gray-500" />
                        <p className="text-sm font-medium text-gray-500">
                          Not Available
                        </p>
                      </div>
                    )}
                    {/* <p
                        className={` text-xs ml-4 cursor-pointer hover:bg-[#f7f4f4] font-medium tracking-wide border rounded-xl px-3 py-1 w-fit ${
                          availability === "Available now"
                            ? "text-[#14A800] border-[#14A800]"
                            : availability === "Listening offers..."
                            ? "text-[#FF7D01] border-[#FF7D01]"
                            : "text-[#94b8a3] border-[#94b8a3]"
                        }`}
                        onClick={() => setShowModalAvailability(true)}
                      >
                        {availability}
                      </p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex text-sm">
              <div className="relative">
                <Settings
                  className="h-6 w-fit fill-slate-500 cursor-pointer"
                  onMouseEnter={() => handleMouseEnter("settings")}
                  onMouseLeave={() => handleMouseLeave()}
                  onClick={() => {
                    setDecorationBar("info");
                    navigate("/settings");
                  }}
                />
                <div
                  className={`inline-block absolute -top-11 z-10 -left-4 text-sm font-light text-gray-500 bg-white rounded-lg border border-gray-200 border dark:border-border ${
                    showPopover === "settings"
                      ? "opacity-1 transition-opacity duration-300"
                      : "opacity-0 transition-opacity duration-300"
                  }`}
                >
                  <div className="py-1 px-2">
                    <p>{t("worker.profile.settings")}</p>
                  </div>
                </div>
                <div
                  className={`bg-white z-50 border-r-2 border-b-2 w-[12px] h-[12px] rotate-45 absolute -top-[20px] left-1.5 ${
                    showPopover === "settings"
                      ? "opacity-1 transition-opacity duration-300"
                      : "opacity-0 transition-opacity duration-300"
                  }`}
                ></div>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className="flex-[0_0_35%] border-r border-gray-200 dark:border-border">
              <div className="border-b border-gray-200 p-8">
                <div className="flex items-center justify-between py-4">
                  <p>{t("availability_badge")}</p>

                  <div className="flex items-center space-x-2">
                    {availableData?.badgeProfile?.length > 0 ? (
                      <p className="text-[#333]">On</p>
                    ) : (
                      <p className="text-gray-400">Off</p>
                    )}
                    <div
                      onClick={() =>
                        setShowModalAvailableProfile(showModalAvailableProfile)
                      }
                      className="h-8 w-8 cursor-pointer border rounded-full items-center justify-center flex"
                    >
                      <PencilIcon className="text-gray-400 h-4 cursor-pointer " />
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between py-4">
                  <p>{t("boost_profile")}</p>
                  <div className="flex items-center space-x-2">
                    {featuredData?.boostProfile?.length > 0 ? (
                      <p className=" text-[#333]">On</p>
                    ) : (
                      <p className="text-gray-400">Off</p>
                    )}
                    <div
                      onClick={() => {
                        // if (featuredData?.boostProfile?.length === 0) {
                        setShowModalFeaturedProfile(showModalFeaturedProfile);
                        // }
                      }}
                      className="h-8 w-8 cursor-pointer border rounded-full items-center justify-center flex"
                    >
                      <PencilIcon className="text-gray-400 h-4 cursor-pointer " />
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-b border-gray-200 p-8">
                <div className="flex items-center">
                  <p className="font-medium">
                    {t("worker.profile.video_introduction")}
                  </p>
                  <Addition
                    fill={"#4F5D73"}
                    className="h-4 cursor-pointer ml-12"
                    onClick={() => setShowAddVideoIntroduction(true)}
                  />
                </div>

                <div className="flex flex-col">
                  <div
                    className="relative cursor-pointer mt-2"
                    onClick={() => setShowModalVideoIntroduction(true)}
                  >
                    <img
                      src={`https://img.youtube.com/vi/${
                        videoUrl?.split("v=")[1]?.split("&")[0]
                      }/mqdefault.jpg`}
                      alt="thumbnail"
                    />
                    <Play
                      className="h-10 absolute top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4"
                      fillOpacity={"0.9"}
                      fill={"white"}
                    />
                  </div>
                </div>
                {/* )} */}
              </div>
              <div className="border-b border-gray-200 p-8 dark:border-border ">
                <div className="flex items-center ">
                  <p className="font-medium">
                    {t("worker.right_container_dashboard.job_type")}
                  </p>
                </div>
                <div className="mt-3">
                  {workerProfile?.job_type == "1" && (
                    <div className="flex items-center text-sm">
                      <GrLocation className="h-4 w-4 fill-gray-500 mr-1" />
                      <p>{workerProfile?.city_name}</p>
                    </div>
                  )}
                  {workerProfile?.job_type == "2" && (
                    <div className="flex items-center text-sm">
                      <HomeOffice className="h-4 fill-gray-500 " />
                      <p>{t("employer.job_details.remote")}</p>
                    </div>
                  )}
                  {workerProfile?.job_type == "3" && (
                    <div className="flex items-center text-sm">
                      <InPerson className="h-3 fill-gray-500 mr-1 " />
                      <p>
                        <b>{t("employer.job_details.hybrid")}</b>{" "}
                        {workerProfile.city_name !== null &&
                          ` ${workerProfile.city_name}`}
                      </p>
                    </div>
                  )}
                  {workerProfile?.job_type == "4" && (
                    <div className="flex items-center text-sm">
                      {t("worker.edit_job_type.modal.work_type.nomatter")}
                    </div>
                  )}
                </div>
              </div>
              <div className="px-8 border-b border-gray-200 dark:border-border">
                <div className="flex   pt-6 pb-6 items-center">
                  <p className="font-bold ">
                    {t("settings.contact_info.location")}
                  </p>
                  <img
                    src={edit}
                    alt="edit"
                    className="h-3 cursor-pointer ml-6"
                    onClick={() => setShowEditLocationModal(true)}
                  ></img>
                </div>
                <div className="flex flex-col items-start  py-1">
                  <p className="text-sm">
                    {t("employer.client.settings.address")}
                  </p>{" "}
                  <p className="text-sm text-gray-500">
                    {workerProfile?.address_street !== "undefined" &&
                      workerProfile?.address_street !== "null" &&
                      workerProfile?.address_street}
                  </p>
                </div>
                <div className="flex flex-col items-start py-2 ">
                  <p className="text-sm">
                    {t("employer.client.settings.city")}
                  </p>
                  <p className="text-sm text-gray-500">
                    {workerProfile?.post_code_name}
                  </p>
                </div>
                <div className="flex flex-col items-start py-2">
                  <p className="text-sm">
                    {t("employer.edit_location.country")}
                  </p>
                  <p className="text-sm pb-8 text-gray-500">
                    {workerProfile?.country_code_name},{" "}
                    {workerProfile?.country_code}
                  </p>
                </div>
              </div>
              <div className=" border-gray-200 p-8 dark:border-border">
                <div className="flex items-center mb-6">
                  <p className="font-medium">
                    {t("worker.profile.categories")}
                  </p>
                  <img
                    src={edit}
                    alt="edit"
                    className="h-3 cursor-pointer ml-6"
                    onClick={() => setShowEditCategoriesModal(true)}
                  />
                </div>
                <ul className="list-disc list-inside">
                  {workerCategories?.map((category) => {
                    return (
                      <li
                        key={category.category_id}
                        className="text-sm text-gray-500 font-normal"
                      >
                        {category.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="border-b border-gray-200 p-8 dark:border-border">
                <div className="flex items-center mb-6">
                  <p className="font-medium">{t("worker.profile.budget")}</p>
                  <img
                    src={edit}
                    alt="edit"
                    className="h-3 cursor-pointer ml-6"
                    onClick={() => setShowEditBudgetModal(true)}
                  />
                </div>
                <div className="flex items-center mr-6 text-sm">
                  <p>
                    <span className="font-medium">
                      {workerProfile?.budget}€
                    </span>{" "}
                    /h
                  </p>
                  {/* <span className="text-gray-500 text-xs ml-2">
                      {t("worker.profile.budget_brutos")}
                    </span> */}
                </div>
              </div>
              <div className="border-b border-gray-200 p-8 dark:border-border">
                <div className="flex items-center mb-6">
                  <p className="font-medium">{t("worker.profile.languages")}</p>
                  <Addition
                    fill={"#4F5D73"}
                    className="h-4 cursor-pointer ml-12"
                    onClick={() => setShowAddLanguageModal(true)}
                  />
                  <img
                    src={edit}
                    alt="edit"
                    className="h-3 cursor-pointer ml-6"
                    onClick={() => setShowEditLanguageModal(true)}
                  />
                </div>
                {allLanguages?.map((language) => {
                  return (
                    <div
                      key={language.language_name}
                      className="flex flex-col items-start  text-sm mb-4"
                    >
                      {language.language_name}
                      <span className=" text-gray-500">
                        {language.languale_level_name}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col">
                <div className="flex  px-8 pt-8 pb-6 items-center">
                  <p className="font-medium">{t("worker.profile.education")}</p>
                  <Addition
                    fill={"#4F5D73"}
                    className="h-4 cursor-pointer ml-12"
                    onClick={() => setShowAddEducationModal(true)}
                  />
                </div>
                {allEducations?.map((education) => {
                  return (
                    <div key={education.id}>
                      <div className="flex items-start justify-between pl-8 pr-3 mb-4">
                        <div className="flex flex-col w-[70%]">
                          <p className="font-medium">
                            {education.institution_name}
                          </p>

                          <p className="text-sm">
                            {education.degree && `${education.degree},`}
                            &nbsp; {`${education.area_of_study} `}{" "}
                          </p>
                          <p className="text-sm text-gray-500">
                            {education.start_date.substring(0, 4)}{" "}
                            {education.end_date &&
                              `- ${education.end_date.substring(0, 4)}`}
                          </p>
                        </div>
                        <div className="flex mt-2 items-center justify-end">
                          <img
                            src={edit}
                            alt="edit"
                            className="cursor-pointer h-3 mr-4 "
                            onClick={() => {
                              setEducation(education);
                              setTimeout(() => {
                                setShowEditEducationModal(true);
                              }, 200);
                            }}
                          />
                          <TrashIcon
                            onClick={() => {
                              setEducation(education);
                              setShowDeleteEducationModal(true);
                            }}
                            className="cursor-pointer h-4 text-[#4F5D73] "
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex-[0_0_65%]">
              <div className="flex flex-col p-8 border-b border-gray-200 dark:border-border">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <p className="font-medium text-lg">
                      {workerProfile?.title}
                    </p>
                    <img
                      src={edit}
                      alt="edit"
                      className="cursor-pointer h-3 ml-6"
                      onClick={() => setShowEditTitleModal(true)}
                    />
                  </div>
                </div>
                <div className="flex mt-4">
                  <p className="max-w-[550px] overflow-hidden text-sm">
                    {workerProfile?.overview ?? (
                      <p className="text-sm text-gray-500">
                        {" "}
                        {t("worker.profile.no_description")}
                      </p>
                    )}
                  </p>
                  <img
                    src={edit}
                    alt="edit"
                    className="h-3 cursor-pointer ml-8"
                    onClick={() => setShowEditBioModal(true)}
                  />{" "}
                </div>
              </div>
              <div className="p-8">
                <div className="flex items-center">
                  <p className="font-medium text-lg">
                    {t("worker.profile.skills")}
                  </p>
                  <img
                    src={edit}
                    alt="edit"
                    className="cursor-pointer h-3 ml-8"
                    onClick={() => setShowEditSkillsModal(true)}
                  />
                </div>
                <div className="flex flex-wrap mt-4">
                  {allSkills?.length > 0 ? (
                    allSkills?.map((skill) => {
                      return (
                        <p
                          key={skill.skill_id}
                          className={`bg-blancoGrisClaro px-2 text-xs mt-2 flex flex-wrap w-fit py-0.5 mx-1 my-1 items-center border rounded-xl text-[#343a47]`}
                        >
                          {skill.name}
                        </p>
                      );
                    })
                  ) : (
                    <p className="text-sm text-gray-500">
                      {t("worker.profile.no_skills")}
                    </p>
                  )}
                </div>
              </div>
              <div className="px-8 border-t border-gray-200 pb-4 dark:border-border">
                <div className="flex mt-5 mb-2 items-center gap-8">
                  <p className="font-medium">
                    {t("worker.profile.available_hours")}
                  </p>
                  <img
                    src={edit}
                    alt="edit"
                    className="h-3 cursor-pointer"
                    onClick={() => setShowModalEditSchedule(true)}
                  />
                </div>
                {showModalEditSchedule && (
                  <ModalEditSchedule
                    setShowModal={setShowModalEditSchedule}
                    showModal={showModalEditSchedule}
                    allChosenTimes={allChosenTimes}
                    draftId=""
                    setAllChosenTimes={setAllChosenTimes}
                  />
                )}
                {allChosenTimes?.length > 0 ? (
                  <AvailableDays allChosenTimes={allChosenTimes} />
                ) : (
                  <p className="text-sm text-gray-500">
                    {t("worker.profile.no_time")}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full bg-white mt-8 border rounded dark:bg-background dark:border-border dark:text-whiteish">
          <div className="border-b flex justify-between border-gray-200 items-center dark:border-border dark:border">
            <p className="p-8 font-medium text-lg">
              {t("worker.profile.work_experience")}
            </p>
            <Addition
              fill={"#4F5D73"}
              className="h-5 cursor-pointer mr-12"
              onClick={() => setShowAddJobModal(true)}
            />{" "}
          </div>
          {allEmployments?.length > 0 &&
            allEmployments?.map(
              (experience: {
                job_title: string;
                company_name: string;
                start_date: string;
                end_date: string;
                description: string;
              }) => {
                return (
                  <div
                    key={experience.job_title}
                    className="p-8 border-b border-gray-200"
                  >
                    <div className="flex justify-between items-center">
                      <p className="font-medium">
                        {experience.job_title} | {experience.company_name}
                      </p>
                      <div className="flex">
                        <img
                          src={edit}
                          alt="edit"
                          className="h-3 cursor-pointer"
                          onClick={() => {
                            setJob(experience);
                            setShowEditJobModal(true);
                          }}
                        />
                        <Trash
                          fill={"#4F5D73"}
                          className="h-[14px] cursor-pointer ml-6"
                          onClick={() => {
                            setJob(experience);
                            setShowDeleteJobModal(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <p className="text-sm text-[#7b7f80b5]">
                        {options?.map((month) => {
                          return month.id ===
                            experience.start_date.substring(5, 7)
                            ? month.label
                            : "";
                        })}
                        &nbsp;
                        {experience.start_date.substring(0, 4)} - &nbsp;
                        {(!experience.end_date ||
                          experience.end_date === "0000-00-00") &&
                          "Present"}
                        {experience.end_date &&
                          options?.map((month) => {
                            return month.id ===
                              experience?.end_date.substring(5, 7)
                              ? `${month.label} ${experience.end_date.substring(
                                  0,
                                  4
                                )}`
                              : "";
                          })}
                      </p>
                      <p className="text-sm">{experience.description}</p>
                    </div>
                  </div>
                );
              }
            )}
          {allEmployments?.length === 0 && (
            <div className="p-8">
              <p className="text-gray-500 ">
                {t("worker.profile.work_experience_empty")}
              </p>
            </div>
          )}
        </div>

        <div className="flex flex-col w-full bg-white mt-8 border rounded mb-16 dark:bg-background dark:text-whiteish">
          <div className="">
            {reviews?.length > 0 && (
              // <div className="px-6 pt-3">
              <div className="text-sm border border-gray-200 bg-white dark:border-border dark:bg-background flex flex-col rounded">
                <div className="border-b border-gray-200 dark:border-border">
                  <p className="font-medium text-lg tracking-wide p-8">
                    {t("worker.profile.employment_history")}
                  </p>
                </div>
                <div>
                  {reviews
                    ?.slice(0)
                    ?.reverse()
                    ?.slice(0, 10)
                    ?.sort((a: any, b: any) => {
                      if (
                        a.date_early_end !== null &&
                        b.date_early_end !== null
                      ) {
                        return b.date_early_end.localeCompare(a.date_early_end);
                      } else if (a.date_end !== null && b.date_end !== null) {
                        return b.date_end.localeCompare(a.date_end);
                      }
                    })
                    ?.map((review) => (
                      <ReviewCard key={review.id} review={review} />
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* )} */}
      {showEditCategoriesModal === true && (
        <Suspense fallback={null}>
          <ModalEditCategory
            setShowModal={setShowEditCategoriesModal}
            showEditCategoriesModal={showEditCategoriesModal}
            workerCategories={workerCategories}
            setWorkerCategories={setWorkerCategories}
            refetchCategories={getCategories}
          />
        </Suspense>
      )}
      {showEditBioModal === true && (
        <Suspense fallback={null}>
          <ModalEditBio
            setShowEditBioModal={setShowEditBioModal}
            showEditBioModal={showEditBioModal}
            refetchData={()=>{refetchWorkerData(); refetchWorkerInfo()}}
          />
        </Suspense>
      )}
      {showAddAnotherExperienceModal === true && (
        <Suspense fallback={null}>
          <ModalAddAnotherExperience
            showAddAnotherExperienceModal={showAddAnotherExperienceModal}
            setShowAddAnotherExperienceModal={setShowAddAnotherExperienceModal}
          />
        </Suspense>
      )}
      {showEditBudgetModal === true && (
        <Suspense fallback={null}>
          <ModalEditBudget
            setShowEditBudgetModal={setShowEditBudgetModal}
            workerDataBudget={workerProfile?.budget}
            showEditBudgetModal={showEditBudgetModal}
            refetchData={()=>{refetchWorkerData(); refetchWorkerInfo()}}
          />
        </Suspense>
      )}
      {showAddProfilePhotoModal === true && (
        <Suspense fallback={null}>
          <ModalAddProfilePhoto
            setShowAddProfilePhotoModal={setShowAddProfilePhotoModal}
            showAddProfilePhotoModal={showAddProfilePhotoModal}
            company={""}
            setCompanyLogoFile={null}
            refetchWorkerInfo={refetchWorkerInfo}
          />
        </Suspense>
      )}
      {showEditSkillsModal === true && (
        <Suspense fallback={null}>
          <EditSkillSelector
            refetchData={()=>{refetchWorkerData();refetchWorkerInfo()}}
            setShowModal={setShowEditSkillsModal}
            showEditSkillsModal={showEditSkillsModal}
            workerCategories={workerCategories}
          />
        </Suspense>
      )}
      {showEditTitleModal === true && (
        <Suspense fallback={null}>
          <ModalEditTitle
            setShowEditTitleModal={setShowEditTitleModal}
            showEditTitleModal={showEditTitleModal}
            workerDataTitle={workerProfile?.title}
            refetchData={()=>{refetchWorkerData;refetchWorkerInfo()}}
          />
        </Suspense>
      )}
      {showAddVideoIntroduction === true && (
        <Suspense fallback={null}>
          <ModalAddVideoIntroduction
            setShowAddVideoIntroduction={setShowAddVideoIntroduction}
            showAddVideoIntroduction={showAddVideoIntroduction}
            refetch={refetchWorkerInfo}
          />
        </Suspense>
      )}
      {showEditLanguageModal === true && (
        <Suspense fallback={null}>
          <ModalEditLanguages
            setShowEditLanguagesModal={setShowEditLanguageModal}
            showEditLanguagesModal={showEditLanguageModal}
            refetch={refetchWorkerData}
          />
        </Suspense>
      )}
      {showAddLanguageModal === true && (
        <Suspense fallback={null}>
          <ModalAddLanguage
            setShowAddLanguageModal={setShowAddLanguageModal}
            showAddLanguageModal={showAddLanguageModal}
            refetch={refetchWorkerData}
          />
        </Suspense>
      )}{" "}
      {showAddJobModal === true && (
        <Suspense fallback={null}>
          <ModalAddEmployment
            setShowModal={setShowAddJobModal}
            showAddJobModal={showAddJobModal}
            refetch={refetchWorkerData}
          />
        </Suspense>
      )}
      {showDeleteJobModal === true && (
        <Suspense fallback={null}>
          <ModalDelete
            job={job}
            error={false}
            setShowModal={setShowDeleteJobModal}
            title={"Job Experience"}
            titleJob={job?.title}
            showModal={false}
            handleRemove={removeExperience}
          />
        </Suspense>
      )}
      {showEditJobModal === true && (
        <Suspense fallback={null}>
          <ModalEditEmployment
            setShowEditModal={setShowEditJobModal}
            showEditJobModal={showEditJobModal}
            refetch={refetchWorkerData}
          />
        </Suspense>
      )}
      {showAddEducationModal === true && (
        <Suspense fallback={null}>
          <ModalAddEducation
            setShowModal={setShowAddEducationModal}
            startingYear={startingYear}
            setStartingYear={setStartingYear}
            endingYear={endingYear}
            setEndingYear={setEndingYear}
            showAddEducationModal={showAddEducationModal}
            refetch={refetchWorkerData}
          />
        </Suspense>
      )}
      {showDeleteEducationModal === true && (
        <Suspense fallback={null}>
          <ModalDeleteEducation
            education={education}
            // handleRemove={removeEducation}
            handleNoMoreLocal={handleNoMoreLocal}
            setShowModal={setShowDeleteEducationModal}
            showDeleteEducationModal={showDeleteEducationModal}
            title={t("worker.profile.modal_education.title")}
            refetchData={refetchWorkerData}
          />
        </Suspense>
      )}
      {showEditEducationModal === true && (
        <Suspense fallback={null}>
          <ModalEditEducation
            education={education}
            setEducation={setEducation}
            setShowEditEducationModal={setShowEditEducationModal}
            showEditEducationModal={showEditEducationModal}
            refetch={refetchWorkerData}
          />
        </Suspense>
      )}
      {showModalVideoIntroduction === true && (
        <Suspense fallback={null}>
          <ModalVideoIntroduction
            videoUrl={videoUrl ?? ""}
            // showModalVideoIntroduction={showModalVideoIntroduction}
            setShowModal={setShowModalVideoIntroduction}
            showModal={showModalVideoIntroduction}
          />
        </Suspense>
      )}
      {showDeleteVideoModal === true && (
        <Suspense fallback={null}>
          <ModalDeleteGeneral
            header={t("worker.profile.modal_video.title")}
            body={t("worker.profile.modal_video.desc")}
            setShowModal={setShowDeleteVideoModal}
            showModal={showDeleteVideoModal}
            supr={deleteVideo}
          />
        </Suspense>
      )}
      {showModalAvailability === true && (
        <Suspense fallback={null}>
          <ModalEditAvailability
            setShowModalAvailability={setShowModalAvailability}
            showModalAvailability={showModalAvailability}
            setAvailability={setAvailability}
            availability={availability}
          />
        </Suspense>
      )}
      {showEditLocationModal === true && (
        <Suspense fallback={null}>
          <ModalEditLocation
            setShowEditLocationModal={setShowEditLocationModal}
            showEditLocationModal={showEditLocationModal}
            refetch={()=>{refetchWorkerData();refetchWorkerInfo()}}
          />
        </Suspense>
      )}
      {showModalFeaturedProfile && (
        <ModalFeaturedProfile
          onClick={featureWorker}
          featureStatus={featuredData?.boostProfile?.length > 0}
          featureValue={
            decryptArray(actions)?.filter(
              (action: { id: string; name: string; credits: string }) =>
                action.id === "18"
            )[0]?.credits
          }
          featureBadge={featureValueCreditsLap}
          workerInfo={workerInfo}
          profileData={profileData}
        />
      )}
      {showModalAvailableProfile && (
        <ModalAvailableProfile
          onClick={availableBadge}
          availableStatus={false}
          availableValue={
            decryptArray(actions)?.filter(
              (action: { id: string; name: string; credits: string }) =>
                action.id === "19"
            )[0]?.credits
          }
          isAvailable={availableData?.badgeProfile}
          lapBadge={availableValueCreditsLap}
          workerInfo={workerInfo}
          profileData={profileData}
        />
      )}
    </>
  );
}

export default Profile;
