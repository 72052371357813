import React, { useState, useEffect, lazy, Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, JOB_TIME_PROFILE, POSTINGS } from "../../Config/api.config";

import ModalDeletePost from "../Modals/ModalDeletePost";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { HoursJobTypes } from "../../types/types";
import { getJobInfo, getJobTime } from "src/Hooks/useApplicationProcess";
import { userProfileStore } from "src/Routing/useProfileStore";
const Loader = lazy(() => import("src/Components/Loader/Loader"));

const JobPostEditView = lazy(() => import("./JobPostEditView"));

export interface InitialStateJobType {
  budget: string;
  category_name: string;
  category_id: string;
  city_name: string;
  city_id: string;
  country_code: string;
  details: string;
  job_type: string;
  location: string;
  post_code_id: string;
  title: string;
  type_name: string;
  netpay: string;
  due_days: string;
  expire_offer_days: string;
  workers_needed: string;
  job_id: string;
  expire_date: string;
  date_created: string;
}

const INITIAL_STATE_JOB: InitialStateJobType = {
  budget: "",
  category_name: "",
  category_id: "",
  city_name: "",
  city_id: "",
  country_code: "",
  details: "",
  job_type: "",
  location: "",
  post_code_id: "",
  title: "",
  type_name: "",
  netpay: "",
  due_days: "",
  expire_offer_days: "",
  workers_needed: "",
  job_id: "",
  date_created: "",
  expire_date: "",
};

export default function JobPostEdit() {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [selectedCategory, setSelectedCategory] = useLocalStorage(
    "selectedCategory",
    ""
  );
  const [jobDescription, setJobDescription] = useLocalStorage(
    "jobDescription",
    ""
  );
  const params = useParams();
  const [allLocations, setAllLocations] = useLocalStorage("allLocations", []);
  const [originalData, setOriginalData] = useLocalStorage("originalData", "");
  const [categoryID, setCategoryID] = useLocalStorage("jobCategoryID", "");
  // const [jobID, setJobID] = useLocalStorage("jobID", "");
  const [jobInfo, setJobInfo] =
    useState<InitialStateJobType>(INITIAL_STATE_JOB);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState("");
  const [allChosenTimes, setAllChosenTimes] = useState<HoursJobTypes[]>([]);
  const [jobTitle, setJobTitle] = useLocalStorage("jobTitle", "");
  const [budget, setBudget] = useLocalStorage("budget", "");
  const [clientSkills, setClientSkills] = useLocalStorage("clientSkills", []);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [jobType, setJobType] = useLocalStorage("jobType", "");
  const [clientSkillsID, setClientSkillsID] = useLocalStorage(
    "clientSkillsID",
    ""
  );
  const [skillsID, setSkillsID] = useLocalStorage("skillsID", []);
  const [jobLocation, setJobLocation] = useLocalStorage("jobLocation", "");
  const [jobLocationID, setJobLocationID] = useLocalStorage(
    "jobLocationID",
    ""
  );
  const [country, setCountry] = useState("");
  const [selected, setSelected] = useLocalStorage("selected", "");
  const [postcode, setPostcode] = useState("");
  const [postcodeID, setPostcodeID] = useState("");
  const [skills, setSkills] = useLocalStorage("skills", []);
  const [expireDate, setExpireDate] = useState("");
  const [hireDate, setHireDate] = useState("");
  const [workersNeeded, setWorkersNeeded] = useState("01");

  const [netPay, setNetPay] = useState("");
  const [showModalDeletePost, setShowModalDeletePost] = useState(false);
  const [checkErrors, setCheckErrors] = useState<any>(false);
  const { t } = useTranslation();

  const jobID = params.id ?? "";
  const { isJobLoading, jobInfoData } = getJobInfo(jobID);
  const { jobTimeData } = getJobTime(jobID);

  const navigate = useNavigate();
  const profileType = userProfileStore((s) => s.profileTypeToken);
  const data = new FormData();

  useEffect(() => {
    setAllChosenTimes(jobTimeData);
  }, [jobTimeData]);

  const deleteTimes = (id: string) => {
    fetch(`${BASE_URL}${JOB_TIME_PROFILE}?job_time_profile_id=${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const sendData = new FormData();
  sendData.append("_method", "POST");
  sendData.append("job_id", jobInfo?.job_id);

  const sendTime = (from: string, to: string, day: string) => {
    sendData.append("hour_from", from);
    sendData.append("hour_to", to);
    sendData.append("dayname", day);
    fetch(`${BASE_URL}${JOB_TIME_PROFILE}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: sendData,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!isJobLoading) {
      setJobInfo(jobInfoData?.data[0]);
      setClientSkills(jobInfoData?.skills);
      setJobType(jobInfoData?.data[0].job_type);
      setJobLocation({ name: jobInfoData?.data[0].city_name });
      setCategoryID(jobInfoData?.data[0].category_id);
      setJobLocationID(jobInfoData?.data[0]?.city_id);
      setPostcodeID(jobInfoData?.data[0]?.post_code_id);
      setPostcode(jobInfoData?.data[0].location);
      setSelectedCategory(jobInfoData?.data[0].category_name);
      setCountry(jobInfoData?.data[0].country_code);
      setNetPay(jobInfoData?.data[0].netpay);
    }
  }, [jobInfoData, isJobLoading]);

  useEffect(() => {
    if (!originalData?.budget && jobInfo) {
      setOriginalData((prev: any) => ({
        ...prev,
        budget: jobInfo.budget,
        category_name: jobInfo.category_name,
        category_id: jobInfo.category_id,
        city_name: jobInfo.city_name,
        city_id: jobInfo.city_id,
        country_code: jobInfo.country_code,
        details: jobInfo.details,
        job_type: jobInfo.job_type,
        location: jobInfo.location,
        postcode_id: jobInfo.post_code_id,
        title: jobInfo.title,
        type_name: jobInfo.type_name,
        skills: clientSkills,
        netPay: jobInfo?.netpay,
      }));
      setJobType(jobInfo.job_type);
      setJobLocation({ name: jobInfo.city_name });
      setNetPay(jobInfo.netpay);
      setPostcode(jobInfo.location);
      setCountry(jobInfo.country_code);
      setHireDate(jobInfo.due_days);
      setExpireDate(jobInfo.expire_offer_days);
      setWorkersNeeded(jobInfo.workers_needed);
    }
  }, [jobInfo, originalData]);

  useEffect(() => {
    if (allChosenTimes?.length > 0 && !originalData?.times) {
      setOriginalData((prev: any) => ({
        ...prev,
        times: allChosenTimes,
      }));
    }
  }, [allChosenTimes, originalData]);

  //cuando se dar a cancel, para no actualizar los horarios
  const isIncluded = () => {
    //para eliminar los horarios añadidos
    const arrayIdsCurrent = allChosenTimes?.map(
      (time: { job_time_profile_id: string }) => time.job_time_profile_id
    );
    const arrayIdsOriginal = originalData.times?.map(
      (time: { job_time_profile_id: string }) => time.job_time_profile_id
    );
    const deleteIds = arrayIdsCurrent.filter(
      (x) => !arrayIdsOriginal.includes(x)
    );
    for (let i = 0; i < deleteIds?.length; i++) {
      deleteTimes(deleteIds[i]);
    }
    //para añadir los horarios eliminados
    for (let i = 0; i < originalData?.times.length; i++) {
      sendTime(
        originalData.times[i].hour_from,
        originalData.times[i].hour_to,
        originalData.times[i].dayname
      );
    }
  };

  useEffect(() => {
    if (jobInfo && jobInfo.job_type === "2") {
      setJobInfo((prev: any) => ({
        ...prev,
        city_name: "",
        country_code: "",
        location: "",
      }));

      setPostcode("");
      setJobLocation();
      setCountry("");
    }
  }, [jobInfo.job_type]);

 

  const checkAppendings = () => {
    if (originalData.title !== jobInfo.title) {
      data.append("title", jobInfo.title!);
    }
    if (originalData.details !== jobInfo.details) {
      data.append("details", jobInfo.details!);
    }
    if (originalData.category_name !== jobInfo.category_name) {
      data.append("category_id", jobInfo.category_id!);
    }
    if (
      JSON.stringify(
        originalData?.skills?.map((skills: { skill_id: string }) => {
          return skills.skill_id;
        })
      ) !=
      JSON.stringify(
        clientSkills?.map((skill: { skill_id: string }) => {
          return skill.skill_id;
        })
      )
    ) {
      data.append(
        "skills_list",
        clientSkills?.map((skill: { skill_id: string }) => skill.skill_id)
      );
    }
    if (originalData.job_type != jobType) {
      if (jobType == 2) {
        data.append("job_type", jobType);
      } else {
        data.append("job_type", jobType);
        if (
          originalData?.city_id !== jobLocationID &&
          originalData?.postcode_id !== postcodeID
        ) {
          data.append("city_id", jobLocationID);
          data.append("post_code_id", postcodeID);
          data.append("country_code", "ES");
        } else if (originalData?.postcode_id !== postcodeID) {
          data.append("city_id", originalData?.city_id);
          data.append("post_code_id", postcodeID);
          data.append("country_code", "ES");
        } else if (originalData?.city_id !== jobLocationID) {
          data.append("post_code_id", originalData?.postcode_id);

          data.append("city_id", jobLocationID);
          data.append("country_code", "ES");
        } else {
          data.append("post_code_id", originalData?.postcode_id);

          data.append("city_id", originalData?.city_id);
          data.append("country_code", "ES");
        }
      }
    } else {
      if (jobType != 2) {
        if (
          originalData?.city_id !== jobLocationID &&
          originalData?.postcode_id !== postcodeID
        ) {
          data.append("city_id", jobLocationID);
          data.append("post_code_id", postcodeID);
          data.append("country_code", "ES");
        } else if (originalData?.postcode_id !== postcodeID) {
          data.append("post_code_id", postcodeID);
          data.append("country_code", "ES");
        } else if (originalData?.city_id !== jobLocation?.id) {
          data.append("city_id", jobLocationID);
          data.append("country_code", "ES");
        }
      }
    }

    if (originalData.budget !== jobInfo.budget) {
      data.append("budget", jobInfo.budget!);
    }
    data.append("netpay", netPay);
    data.append("currency_code", "EUR");
    data.append("due_days", hireDate);
    data.append("expire_offer_days", expireDate);
    data.append("workers_needed", workersNeeded === "01" ? "1" : workersNeeded);
    data.append("_method", "PUT");
    data.append("client_id", userID);
    data.append("job_id", jobInfo.job_id!);

    // const editJob = () => {
    fetch(`${BASE_URL}postings?job_id=${jobID}`, {
      method: "POST",
      body: data,
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setJobInfo(data.result.data[0]);
        setClientSkills(data.result.skills);
        setJobType(data.result.data[0].job_type);
        setJobLocation({ name: data.result.data[0].city_name });
        setCategoryID(data.result.data[0].category_id);
        setJobLocationID(data.result.data[0]?.city_id);
        setPostcodeID(data.result.data[0]?.post_code_id);
        setPostcode(data.result.data[0].location);
        setSelectedCategory(data.result.data[0].category_name);
        setCountry(data.result.data[0].country_code);
        setNetPay(data.result.data[0].netpay);
        // setJobData(data.result);
      })
      .catch((err) => console.log(err));
    // };
    setOriginalData();
    setJobInfo(INITIAL_STATE_JOB);
    setSelected();
    setBudget();
    setSelectedCategory();
    setJobLocation();
    setJobDescription();
    setCategoryID();
    setSkillsID();
    setSkills();
    setJobTitle();
    setJobLocationID();
    setClientSkillsID();
    setClientSkills();
    setJobType();
    setTimeout(() => {
      if (profileType === "employer") {
        navigate("/dashboard");
      } else {
        navigate("/dashboard-worker");
      }
    }, 100);
  };

  const handleJobCancel = () => {
    isIncluded();
    setOriginalData();
    setPostcode("");
    setJobInfo(INITIAL_STATE_JOB);
    setSelected();
    setBudget();
    setSelectedCategory();
    setJobLocation();
    setJobDescription();
    setCategoryID();
    setSkillsID();
    setSkills();
    setJobTitle();
    setJobLocationID();
    setClientSkillsID();
    setClientSkills();
    setJobType();
    navigate(-1);
  };

  const curr = new Date();
  curr.setDate(curr.getDate());

  const deletePost = (id: string) => {
    const data = fetch(`${BASE_URL}${POSTINGS}?job_id=${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ` + loggedInUserUid,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.affected === "deleted") {
          setShowModalDeletePost(false);
          toast.success(
            `${jobInfo.title} ${t(
              "employer.posting_edit.delete.toast_success"
            )}`
          );
          if (profileType === "employer") {
            navigate("/dashboard");
          } else {
            navigate("/dashboard-worker");
          }
        } else if (data.result.error_id === "400") {
          setShowModalDeletePost(false);
          toast.error(data.result.error_msg);
          // toast.error(`Job (${jobInfo.title}) not found`);
        } else {
          toast.error(`${data.result.error_msg}`);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (jobInfo.title?.length >= 8)
      setCheckErrors((prev: any) => ({ ...prev, title: false }));
    if (jobInfo.details?.length >= 50)
      setCheckErrors((prev: any) => ({ ...prev, details: false }));
  }, [jobInfo.title, jobInfo.details]);

  return (
    <div>
      <>
        <Suspense fallback={null}>
          <JobPostEditView
            checkAppendings={checkAppendings}
            jobInfo={jobInfo}
            checkErrors={checkErrors}
            setCheckErrors={setCheckErrors}
            handleJobCancel={handleJobCancel}
            setJobInfo={setJobInfo}
            clientSkills={clientSkills}
            setClientSkills={setClientSkills}
            jobLocation={jobLocation}
            setJobLocation={setJobLocation}
            setJobType={setJobType}
            jobType={jobType}
            setPostcodeID={setPostcodeID}
            postcode={postcode}
            setPostcode={setPostcode}
            country={country}
            setCountry={setCountry}
            setBudget={setBudget}
            setSelectedCurrencyCode={setSelectedCurrencyCode}
            netPay={netPay}
            setNetPay={setNetPay}
            isIncluded={isIncluded}
            showModalDeletePost={showModalDeletePost}
            setShowModalDeletePost={setShowModalDeletePost}
            allChosenTimes={allChosenTimes}
            setAllChosenTimes={setAllChosenTimes}
            hireDate={hireDate}
            setHireDate={setHireDate}
            expireDate={expireDate}
            setExpireDate={setExpireDate}
            workersNeeded={workersNeeded}
            setWorkersNeeded={setWorkersNeeded}
          />
        </Suspense>
      </>
      {showModalDeletePost && (
        <ModalDeletePost
          deletePost={deletePost}
          showModalDeletePost={showModalDeletePost}
          setShowModalDeletePost={setShowModalDeletePost}
          jobInfo={jobInfo}
        />
      )}
    </div>
  );
}
