import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsArrowUpRight, BsThreeDotsVertical } from "react-icons/bs";
import { IoCopyOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { cn } from "src/lib/utils";
import { RiDownloadLine } from "react-icons/ri";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/solid";
import { BASE_URL } from "src/Config/api.config";
import { Wallet } from "src/types/types";

import { useEmployerStore } from "src/Routing/useProfileStore";
import { Button } from "src/Components/ui/Button";
import WalletSkeleton from "src/Components/ui/skeleton/WalletSkeleton";
import FlexiCoin from "src/assets/img/flexicoin.png";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "src/Components/CheckoutForm";
import { useWalletSore } from "src/Hooks/useWalletStore";
import Modal from "react-modal";
import {
  useClientCredits,
  useClientCreditsInfo,
} from "src/Hooks/useClientCredits";
import { usePriceStripe } from "src/Hooks/usePriceStripe";

const date_format = "MM//dd/yyyy";

const suggestedPrices = [{ price: "50" }, { price: "100" }, { price: "200" }];
const suggestedCredits = [
  { credits: "100" },
  { credits: "200" },
  { credits: "400" },
];

const WalletCredits = () => {
  const paramForm: any = localStorage.getItem("paramType");

  const coefficient = JSON.parse(paramForm)?.find(
    (param: { name: string; id: string; value: string }) =>
      param.name === "COEFFICIENT"
  )?.value;
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const [animateModal, setAnimateModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [walletInfo, setWalletInfo] = useState<Wallet[]>([]);
  const [showBuy, setShowBuy] = useState(false);
  const [amountInput, setAmountInput] = useState("0");
  const [showCopied, setShowCopied] = useState(false);
  const walletRef = useRef<HTMLDivElement>(null);
  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);
  const modal = useWalletSore();

  const [showActivity, setShowActivity] = useState(false);
  const stripePromise = loadStripe(
    "pk_live_51QrMFhGEpp8xu3GMcsag5qSTYD4K9w6Sb0gblbE4JZwEZHGvyc6PmFvJCtQgOacHu7SN1lcB34jv2o7aave7jsx100rmVm1ufL"
  );
  // const stripePromise = loadStripe(
  //   "pk_test_51Ko0cvIbATEeXV2RrbmqpvSq0bBPhpArQw3FQ4Mfe9uQBfzHpTcObrd5QXFkQQGisgD7pkXkRDS3DGiZg5qLE2Mz003UcxTS2u"
  // );
  const { clientCreditsData, refetchClientCredits } = useClientCredits();
  const { priceStripeData, refetchPriceStripe } = usePriceStripe();

  useEffect(() => {
    const priceAmount = priceStripeData?.amount || 0; // Price in cents (for example, 100 cents = 1 EUR)
    const amount = Number(amountInput);

    if (!isNaN(priceAmount) && !isNaN(amount)) {
      // Calculate the total price in cents (without dividing by 100)
      const total = Math.round(priceAmount * amount); // No division by 100 here, as priceAmount is in cents
      setTotalPrice(total); // Now totalPrice is an integer in cents
    } else {
      setTotalPrice(0); // Or handle in another way if needed
    }
  }, [amountInput]);

  useEffect(() => {
    setTimeout(() => {
      setAnimateModal(true);
    }, 50);
  }, [modal.isOpen]);

  const {
    refetchWalletInfo,
    walletData,
    isWalletLoading: loading,
  } = useClientCreditsInfo();

  useEffect(() => {
    setWalletInfo(walletData);
  }, [walletData]);

  const handleAmountToBuy = (e: string) => {
    setAmountInput(e);
  };

  const copyToClipboard = async (e: string) => {
    await navigator.clipboard.writeText(e);
    setShowCopied(true);

    setTimeout(() => {
      setShowCopied(false);
    }, 2000);
  };

  //Stripe
  const [clientSecret, setClientSecret] = useState("");

  const createPaymentData = new FormData();
  createPaymentData.append("amount", `${totalPrice}`);
  createPaymentData.append("credits", amountInput);
  createPaymentData.append("userId", userInfo.id);
  createPaymentData.append("product_id", priceStripeData?.productId || "");
  createPaymentData.append("action_id", "8");
  createPaymentData.append("token", loggedInUserUid);

  const createPayment = () => {
    fetch(`${BASE_URL}create.php`, {
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      method: "POST",
      body: createPaymentData,
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
      })
      .catch((err) => console.log(err));
  };

  const appearance = {
    theme: "flat",
  };

  const options: any = {
    clientSecret,
    appearance,
  };

  const StylesModal: any = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 10,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0)",
      zIndex: 200,
    },
    content: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "100%",
      left: "50%",
      right: "50%",
      overflow: "auto",
      border: "none",
      background: "#fff",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
      padding: "20px",
      backgroundColor: "transparent",
    },
  };

  return (
    <Modal
      style={StylesModal}
      isOpen={modal.isOpen}
      onRequestClose={() => modal.onClose()}
    >
      <div
        ref={walletRef}
        className={cn(
          "fixed left-64 bottom-52 transition p-2 border -2xl rounded-2xl  w-80 py-4 text-black z-10   bg-gray-50 dark:bg-background border-border  translate-y-2 "
        )}
      >
        <div
          className={` border-l bg-gray-50 border-border dark:bg-background  border-b z-20 w-4 h-4 rotate-[45deg] absolute bottom-24 -left-2 `}
        />
        <div className=" border-b border-border px-2  flex items-center justify-center mb-2">
          <div className="flex flex-col items-center py-2 hover:bg-gray-100 transition rounded-md px-1 relative">
            <p className="text-sm font-medium  text-center dark:text-whiteish ">
              {userInfo.first_name} {userInfo.last_name}
            </p>
            <div
              onClick={() => copyToClipboard(userInfo.email)}
              className="flex items-center gap-1 text-xs cursor-pointer dark:text-whiteish"
            >
              {userInfo.email?.slice(0, 4)}...{userInfo.email?.slice(-3)}
              <IoCopyOutline />
            </div>
            <div
              className={cn(
                "w-24 h-12 rounded-md bg-gray-50 flex items-center justify-center scale-0 absolute top-14 -translate-y-2 opacity-20",
                showCopied && "scale-100 translate-y-0 opacity-100 transition"
              )}
            >
              <p className="text-xs text-[#333]">Copied!</p>
            </div>
          </div>
          <div className="  text-center py-2 absolute right-4">
            <BsThreeDotsVertical />
          </div>
        </div>
        {showBuy && priceStripeData?.amount && (
          <div>
            <div className="flex items-center justify-center py-2">
              <div
                onClick={() => setShowBuy(false)}
                className="absolute left-4"
              >
                <p className="text-azulBonico dark:text-whiteish text-xs cursor-pointer ">
                  {t("wallet.back_button")}
                </p>
              </div>
              <div>
                <p className="font-medium text-sm dark:text-whiteish">
                  {t("wallet.amount_buy")}
                </p>
              </div>
              <div
                onClick={() => setShowBuy(false)}
                className="absolute right-4"
              >
                <p className="text-azulBonico text-xs cursor-pointer">
                  {t("wallet.cancel")}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center my-4 dark:text-whiteish">
              <div className="text-3xl font-medium">
                {amountInput === "" ? "0" : amountInput}
                <img
                  src={require("src/assets/img/flexicoin-nobg.png")}
                  alt="Flexicoin Logo"
                  className="inline-block w-6 h-6 ml-1"
                />
              </div>

              <div className=" text-xs">
                {totalPrice / 100} {t("wallet.currency_estimated")}
              </div>
            </div>
            {clientSecret === "" && (
              <div className="flex items-center justify-center gap-4 mb-5 dark:text-whiteish">
                {suggestedCredits.map((suggestion) => (
                  <div
                    key={suggestion.credits}
                    onClick={() => setAmountInput(`${suggestion.credits}`)}
                    className="border rounded-full px-3 py-1 text-xs dark:text-whiteish cursor-pointer hover:bg-gray-100 transition"
                  >
                    {suggestion.credits}{" "}
                    <img
                      src={require("src/assets/img/flexicoin-nobg.png")}
                      alt="Flexicoin Logo"
                      className="inline-block w-4 h-4 ml-1"
                    />
                  </div>
                ))}
              </div>
            )}

            {clientSecret === "" && (
              <div>
                <div className="border-2 px-2 py-1 dark:border-border  mx-3 rounded-md border-azulBonico mb-4 bg-input">
                  <input
                    maxLength={4}
                    value={amountInput}
                    onChange={(e) => {
                      if (e.target.value.length > 5) {
                        return;
                      }
                      handleAmountToBuy(e.target.value.replace(/[^0-9]*/g, ""));
                    }}
                    type="number"
                    className=" text-xs outline-none h-6 px-2 bg-transparent dark:text-whiteish"
                    placeholder="Enter amount to buy"
                    onKeyDown={(e) => {
                      if (e.key === ".") {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
            )}

            <>
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm quantity={totalPrice} credits={amountInput} />
                </Elements>
              )}
            </>
            {clientSecret === "" && (
              <>
                <div
                  onClick={() => createPayment()}
                  className="flex items-center justify-center border dark:text-whiteish py-2 px-4 mx-3 rounded-full mt-6 dark:hover:bg-input border-border transition duration-200 ease-in-out hover:bg-blue-50 cursor-pointer"
                >
                  <p className="text-sm">{t("wallet.continue_payment")}</p>
                </div>
              </>
            )}
          </div>
        )}

        {!showBuy && (
          <div className="bg-white rounded border border-border">
            <div className="flex flex-col justify-center items-center">
              <div className=" text-[13px] mt-2">
                <p className=" text-[#333] text-lg font-semibold dark:text-whiteish">
                  {creditsNumber} {t("credits.title")}
                </p>
                <p className="text-[10px] text-gray-500 text-center dark:text-whiteish">
                  {Number(creditsNumber) / coefficient}€
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center my-4 gap-4 w-[70%] mx-auto">
              <div
                onClick={() => setShowBuy(!showBuy)}
                className="flex flex-col items-center w-1/3"
              >
                <div className="bg-azulOscuro p-2 rounded-full hover:bg-azulBonico transition cursor-pointer">
                  <RiDownloadLine className="h-5 w-5 text-white" />
                </div>
                <p className="text-xs text-azulOscuro">
                  {t("wallet.credits_buy")}
                </p>
              </div>
              <div className="flex flex-col items-center w-1/3 ">
                <div className="bg-gray-400 p-2 rounded-full hover:cursor-not-allowed transition cursor-pointer">
                  <BsArrowUpRight className="h-5 w-5 text-white" />
                </div>
                <p className="text-xs text-gray-400">
                  {t("wallet.credits_send")}
                </p>
              </div>
              <div className="flex flex-col items-center w-1/3">
                <div className="bg-gray-400 p-2 rounded-full hover:cursor-not-allowed transition cursor-pointer">
                  <ArrowsRightLeftIcon className="h-5 w-5 text-white" />
                </div>
                <p className="text-xs text-gray-400">
                  {t("wallet.credits_swap")}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center border-b-2">
              <div
                onClick={() => setShowActivity(true)}
                className={cn("border-b-2 transition border-azulBonico w-full")}
              >
                <p className="text-azulOscuro text-sm font-medium pb-2 text-center">
                  {t("wallet.credits_activity")}
                </p>
              </div>
            </div>
            <div className="h-56 overflow-y-auto scrollbar-thum">
              {/* walletInfo?.map((mov) => ( */}
              {walletInfo?.map((mov) => (
                <>
                  {loading && <WalletSkeleton />}
                  {!loading && (
                    <div
                      className="flex justify-between px-4 py-2 items-center border-b"
                      key={mov.id}
                    >
                      <div className="flex items-center space-x-5">
                        <p className="text-xs text-right text-gray-500">
                          {mov.date_created?.slice(0, 10)}
                        </p>
                      </div>
                      <div className="w-20 flex items-start justify-start">
                        <p className="text-xs text-left text-gray-400">
                          {mov.action_name}
                        </p>
                      </div>
                      <div className="flex items-center gap-3 w-20">
                        {/* <img src={LogoImage} className="h-6 w-5 object-cover" /> */}
                        <div className="flex flex-col w-10">
                          <div className="flex items-center space-x-4">
                            <p className="dark:text-whiteish text-sm">
                              {mov.credits}
                            </p>
                          </div>
                          <p className="text-xs text-rositaOscuro dark:text-whiteish">
                            <div className="flex items-center space-x-2">
                              <span className="font-medium text-sm">
                                {Number(mov.credits) / coefficient}€
                              </span>{" "}
                            </div>
                          </p>
                        </div>
                        <img
                          src={FlexiCoin}
                          className="h-5 w-5 object-contain"
                        />
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>

            <div className="flex flex-col items-center py-2 justify-center ">
              <p className="text-xs font-medium text-gray-600 dark:text-whiteish">
                {t("wallet.credits_activity.question")}
                <span>
                  {" "}
                  <Button onClick={() => refetchClientCredits()}>
                    <h3 className="text-azulOscuro font-medium text-sm">
                      {t("wallet.credits_activity.refresh_list")}
                    </h3>
                  </Button>
                </span>
              </p>

              <h1 className="text-xs mt-2 dark:text-whiteish text-center border-t pt-3">
                {t("wallet.credits_activity.need_help")}
              </h1>
              <p
                onClick={() => navigate("/contact")}
                className="text-azulOscuro font-medium cursor-pointer text-xs"
              >
                Flexihours {t("wallet.credits_activity.flexihours_support")}
              </p>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default WalletCredits;
