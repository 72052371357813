import React, { Dispatch, SetStateAction, Suspense } from "react";
import { BASE_URL_IMG } from "src/Config/api.config";
import { ReactComponent as Check } from "src/assets/img/check.svg";
import { useTranslation } from "react-i18next";

import StepsRightContainer from "../HowItWorks/StepsRightContainer";
import RatingStars from "../Rating/RatingStars";
import { HandleMonthName, HandleMonthNameES } from "src/Utils/HandleMonthName";

interface DashboardRightContainerProps {
  clientData: any;
  loadingCredits: boolean;
  setShowInstructionsCollapsed: Dispatch<SetStateAction<boolean>>;
  showInstructionsCollapsed: boolean;
  clientScore: {
    client_type_name: string;
    date_joined: string;
    score_avg: string;
    totalConnections: string;
    totalConnectionsTerminated: string;
    totalScores: string;
  };
}
const DashboardRightContainer = ({
  clientData,
  loadingCredits,
  showInstructionsCollapsed,
  setShowInstructionsCollapsed,
  clientScore,
}: DashboardRightContainerProps) => {
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  return (
    <div className="hidden laptop:!block flex-[0_0_40%] ml-8 max-w-[35%] p-2 mt-4 ">
      <div className="flex flex-col items-center w-full py-4 bg-white rounded-lg border  mb-4">
        <div className="flex items-center justify-center ">
          <div className="flex items-center space-x-6">
            {!clientData?.company_logo ? (
              <div className="flex flex-col items-center space-y-4 bg-white p-6 ">
                <img
                  src={require("../../assets/img/user-worker.png")}
                  alt="Default Profile"
                  className="h-20 w-20 rounded-full border-2 border-gray-200"
                />
                <p className="text-lg font-semibold text-gray-800">
                  {clientData?.first_name} {clientData?.last_name}
                </p>
                <div className="flex items-center space-x-2">
                  <Check className="h-4 fill-azulBonico" />
                  <p className="text-sm font-medium text-azulBonico">
                    {t("employer.dashboard.validated")}
                  </p>
                </div>
                <RatingStars
                  size="14"
                  activeStar={clientScore?.score_avg}
                  score={clientScore?.score_avg}
                  evaluation={false}
                  reviews={true}
                  showHalf={true}
                  info={{ totalScores: clientScore?.totalScores }}
                />
                <div className="bg-white rounded-lg  p-6 border border-gray-100">
                  <h3 className="text-lg font-semibold text-gray-800 mb-4">
                    {t("client.dashboard.statistics")}
                  </h3>

                  <div className="space-y-5">
                    {/* Jobs Count */}
                    <div className="flex items-center justify-between border-b border-gray-100 pb-3">
                      <div className="flex flex-col">
                        <span className="text-sm text-gray-500">
                          {t("admin.analytics.jobs")}
                        </span>
                        <span className="text-xl font-bold text-indigo-600 mt-1">
                          {clientScore?.totalConnections || 0}
                        </span>
                      </div>
                      <div className="bg-indigo-50 p-2 rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-indigo-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                        </svg>
                      </div>
                    </div>

                    {/* Cancelled Jobs */}
                    <div className="flex items-center justify-between border-b border-gray-100 pb-3">
                      <div className="flex flex-col">
                        <span className="text-sm text-gray-500">
                          {t("dashboard_right_container_jobs_cancelled")}
                        </span>
                        <span className="text-xl font-bold text-rose-600 mt-1">
                          {clientScore?.totalConnectionsTerminated || 0}
                        </span>
                      </div>
                      <div className="bg-rose-50 p-2 rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-rose-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>

                    {/* Member Since */}
                    <div className="flex items-center pt-1">
                      <div className="bg-green-50 p-2 rounded-full mr-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-green-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm text-gray-500">
                          {t("worker.dashboard.member_since")}
                        </span>
                        <span className="text-sm font-medium text-gray-700">
                          {i18n.language === "en"
                            ? HandleMonthName(
                                clientScore?.date_joined?.slice(5, 7)
                              )
                            : HandleMonthNameES(
                                clientScore?.date_joined?.slice(5, 7)
                              )}{" "}
                          {clientScore?.date_joined?.slice(0, 4)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <ul className="flex flex-col gap-3 mt-4 text-gray-700 w-full">
                  <li className="flex justify-between items-center">
                    <span className="font-medium">
                      {clientScore?.totalConnections}
                    </span>
                    <span className="text-sm text-gray-500">
                      {t("admin.analytics.jobs")}
                    </span>
                  </li>
                  <li className="flex justify-between items-center">
                    <span className="font-medium">
                      {clientScore?.totalConnectionsTerminated}
                    </span>
                    <span className="text-sm text-gray-500">
                      {t("dashboard_right_container_jobs_cancelled")}
                    </span>
                  </li>
                  <li className="text-sm text-gray-400">
                    {t("worker.dashboard.member_since")}{" "}
                    {i18n.language === "en"
                      ? HandleMonthName(clientScore?.date_joined?.slice(5, 7))
                      : HandleMonthNameES(
                          clientScore?.date_joined?.slice(5, 7)
                        )}{" "}
                    {clientScore?.date_joined?.slice(0, 4)}
                  </li>
                </ul> */}
              </div>
            ) : (
              // <div className="flex flex-col items-center">
              //   <img
              //     src={require("../../assets/img/user-worker.png")}
              //     className="h-16 w-16 rounded-full"
              //     alt="Default Profile"
              //   />
              //   <p className="mt-2 text-base font-semibold text-gray-800">
              //     {clientData?.first_name} {clientData?.last_name}
              //   </p>
              //   <div className="flex items-center space-x-2 mt-1">
              //     <Check className="h-4 fill-azulBonico" />
              //     <p className="text-xs text-azulBonico">
              //       {t("employer.dashboard.validated")}
              //     </p>
              //   </div>
              //   <RatingStars
              //     size="13"
              //     activeStar={clientScore?.score_avg}
              //     score={clientScore?.score_avg}
              //     evaluation={false}
              //     reviews={true}
              //     showHalf={true}
              //     info={{ totalScores: clientScore?.totalScores }}
              //   />
              //   <ul className="flex flex-col gap-2 mt-2 font-medium text-sm">
              //     <li className="flex items-center">
              //       <span>{clientScore?.totalConnections}</span>
              //       <span className="text-xs text-gray-500 ml-2">
              //         {t("admin.analytics.jobs")}
              //       </span>
              //     </li>
              //     <li className="flex items-center">
              //       <span>{clientScore?.totalConnectionsTerminated}</span>
              //       <span className="text-xs text-gray-500 ml-2">
              //         {t("dashboard_right_container_jobs_cancelled")}
              //       </span>
              //     </li>
              //     <li>
              //       <p className="text-xs text-gray-400">
              //         {t("worker.dashboard.member_since")}{" "}
              //         {i18n.language === "en"
              //           ? HandleMonthName(clientScore?.date_joined?.slice(5, 7))
              //           : HandleMonthNameES(
              //               clientScore?.date_joined?.slice(5, 7)
              //             )}{" "}
              //         {clientScore?.date_joined?.slice(0, 4)}
              //       </p>
              //     </li>
              //   </ul>
              // </div>
              <div className="flex flex-col items-center space-y-4 bg-white p-2 r">
                <img
                  loading="lazy"
                  src={`${BASE_URL_IMG}${clientData?.company_logo}`}
                  alt="Company Logo"
                  className="h-24 w-24 object-cover rounded-full border-2 border-gray-200 "
                />
                <p className="text-lg font-semibold text-gray-800">
                  {clientData?.company_name}
                </p>
                <div className="flex items-center space-x-2">
                  <Check
                    className={`h-5 ${
                      clientData?.company_validated === "1"
                        ? "fill-gray-400"
                        : "fill-azulBonico"
                    }`}
                  />
                  <p
                    className={`text-sm font-medium ${
                      clientData?.company_validated === "1"
                        ? "text-gray-400"
                        : "text-azulBonico"
                    }`}
                  >
                    {clientData?.company_validated === "1"
                      ? t("employer.dashboard.not_validated")
                      : t("employer.dashboard.validated")}
                  </p>
                </div>
                <div className="flex flex-col items-center mt-4">
                  <RatingStars
                    size="14"
                    activeStar={clientScore?.score_avg}
                    score={clientScore?.score_avg}
                    evaluation={false}
                    reviews={true}
                    showHalf={true}
                    info={{ totalScores: clientScore?.totalScores }}
                  />
                  <div className="bg-white rounded-lg  p-6 border border-gray-100">
                    <h3 className="text-lg font-semibold text-gray-800 mb-4">
                      {t("client.dashboard.statistics")}
                    </h3>

                    <div className="space-y-5">
                      {/* Jobs Count */}
                      <div className="flex items-center justify-between border-b border-gray-100 pb-3">
                        <div className="flex flex-col">
                          <span className="text-sm text-gray-500">
                            {t("admin.analytics.jobs")}
                          </span>
                          <span className="text-xl font-bold text-indigo-600 mt-1">
                            {clientScore?.totalConnections || 0}
                          </span>
                        </div>
                        <div className="bg-indigo-50 p-2 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-indigo-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                          </svg>
                        </div>
                      </div>

                      {/* Cancelled Jobs */}
                      <div className="flex items-center justify-between border-b border-gray-100 pb-3">
                        <div className="flex flex-col">
                          <span className="text-sm text-gray-500">
                            {t("dashboard_right_container_jobs_cancelled")}
                          </span>
                          <span className="text-xl font-bold text-rose-600 mt-1">
                            {clientScore?.totalConnectionsTerminated || 0}
                          </span>
                        </div>
                        <div className="bg-rose-50 p-2 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-rose-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>

                      {/* Member Since */}
                      <div className="flex items-center pt-1">
                        <div className="bg-green-50 p-2 rounded-full mr-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-green-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-sm text-gray-500">
                            {t("worker.dashboard.member_since")}
                          </span>
                          <span className=" text-sm font-medium text-gray-700">
                            {i18n.language === "en"
                              ? HandleMonthName(
                                  clientScore?.date_joined?.slice(5, 7)
                                )
                              : HandleMonthNameES(
                                  clientScore?.date_joined?.slice(5, 7)
                                )}{" "}
                            {clientScore?.date_joined?.slice(0, 4)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <ul className="flex flex-col gap-3 mt-4 text-gray-700 w-full">
                    <li className="flex justify-between items-center">
                      <span className="font-medium">
                        {clientScore?.totalConnections}
                      </span>
                      <span className="text-sm text-gray-500">
                        {t("admin.analytics.jobs")}
                      </span>
                    </li>
                    <li className="flex justify-between items-center">
                      <span className="font-medium">
                        {clientScore?.totalConnectionsTerminated}
                      </span>
                      <span className="text-sm text-gray-500">
                        {t("dashboard_right_container_jobs_cancelled")}
                      </span>
                    </li>
                    <li className="text-sm text-gray-400">
                      {t("worker.dashboard.member_since")}{" "}
                      {i18n.language === "en"
                        ? HandleMonthName(clientScore?.date_joined?.slice(5, 7))
                        : HandleMonthNameES(
                            clientScore?.date_joined?.slice(5, 7)
                          )}{" "}
                      {clientScore?.date_joined?.slice(0, 4)}
                    </li>
                  </ul> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Suspense fallback={null}>
        <StepsRightContainer
          showInstructionsCollapsed={showInstructionsCollapsed}
          setShowInstructionsCollapsed={setShowInstructionsCollapsed}
        />
      </Suspense>
    </div>
  );
};

export default DashboardRightContainer;
