import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import Select from "react-select";

import "./HeaderUser.css";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import Navbar from "./Navbar";
import NavItem from "./NavItem";
import DropdownMenu from "./DropdownMenuEmployer";
import {
  BASE_URL,
  BASE_URL_IMG,
  CHAT,
  NOTIFICATIONS,
} from "../../Config/api.config";
import {
  allNotifications,
  // dataMessagesProcessOne,
  isEmployerOnline,
  messages4JobId,
  notificationsState,
  process1WithParamNew,
  selectedTabHeaderEmployer,
} from "../../Atoms/jotaiAtoms";

import { ReactComponent as Bell } from "../../assets/img/bell-blank.svg";
import { ReactComponent as Circle } from "../../assets/img/circle.svg";
import flexi from "../../assets/img/flexihours.png";
import { ReactComponent as LogoTwo } from "../../assets/img/logo_2.svg";
import NavBarBurger from "../SmallDevices/NavBarBurger";
import Hamburger from "hamburger-react";
import { useTranslation } from "react-i18next";
import { NotificationsTypes } from "src/types/types";
import { useGetNotifications } from "src/Hooks/useGetNotifications";
import { userProfileStore } from "src/Routing/useProfileStore";

const statusOptions = [
  {
    value: "Online",
    label: (
      <div className="flex items-center gap-4">
        <Circle className="h-3  fill-green-500" />
        Online
      </div>
    ),
  },
  {
    value: "Invisible",
    label: (
      <div className="flex items-center gap-4">
        <Circle className="h-3 fill-slate-400" />
        Invisible
      </div>
    ),
  },
];

interface HeaderUserProps {
  visibilityStatus?: any;
  setVisibilityStatus?: any;
  setRefreshJobPosts?: any;
}
function HeaderUser({
  visibilityStatus,
  setVisibilityStatus,
  setRefreshJobPosts,
}: HeaderUserProps) {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [showJobsDropdown, setShowJobsDropdown] = useState(false);
  const [showEye, setShowEye] = useState(-1);
  const [showTalentDropdown, setShowTalentDropdown] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [isJobsOpen, setIsJobsOpen] = useState(false);
  const [notifications, setNotifications] = useAtom(allNotifications);
  const [notificationsData, setNotificationsData] = useAtom(notificationsState);
  const [isTalentOpen, setIsTalentOpen] = useState(false);
  const [loggedStatus, setLoggedStatus] = useAtom(isEmployerOnline);
  const [selectedTab, setSelectedTab] = useAtom(selectedTabHeaderEmployer);
  const [unreadMessages, setUnreadMessages] = useState("0");
  // const [dataProcess1, setDataProcess1] = useAtom(dataMessagesProcessOne);
  const [messagesJobId, setMessagesJobId] = useAtom(messages4JobId);
  const [useParamsForStartConversation, setUseParamsForStartConversation] =
    useAtom(process1WithParamNew);
  const [showNotificationsPopover, setShowNotificationsPopover] =
    useState(false);
  const [jobTitle, setJobTitle] = useLocalStorage("jobTitle", "");
  const [selectedCategory, setSelectedCategory] = useLocalStorage(
    "selectedCategory",
    ""
  );
  const [clientSkills, setClientSkills] = useLocalStorage("clientSkills", "");
  const [jobType, setJobType] = useLocalStorage("jobType", "");
  const [budget, setBudget] = useLocalStorage("budget", "");
  const [isOpen, setOpen] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const navigate = useNavigate();
  const { t } = useTranslation();

  const jobsRef = useRef<HTMLDivElement>(null);
  const talentRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<HTMLDivElement>(null);
  const profileType = userProfileStore((s) => s.profileTypeToken);

  //clean local storage

  useEffect(() => {
    if (window.location.pathname !== "/welcome-client") {
      setJobTitle();
      setSelectedCategory();
      setClientSkills();
      setJobType();
      setBudget();
    }
  }, []);

  //Notifications

  const INTERVAL_NOTIFICATIONS = 10000;

  const { isNotificationsLoading, notificationData, refetch } =
    useGetNotifications();

  useEffect(() => {
    if (
      notificationData?.notifications?.[0] &&
      notificationData?.notifications?.[0].created === "0 minutes" &&
      notificationData?.notifications?.[0].detail === "Applied to job"
    ) {
      if (setRefreshJobPosts) {
        setRefreshJobPosts(true);
      }
    }
    setNotifications(notificationData?.notifications);
    setNotificationsData(notificationData?.paginate?.[0]);
    setUnreadMessages(notificationData?.messages);
  }, [notificationData]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, INTERVAL_NOTIFICATIONS);
    return () => clearInterval(interval);
  }, []);

  //SET ONLINE STATUS FROM MESSAGES TAB OFF
  const data = new FormData();
  const setOnlineStatusOff = () => {
    data.append("_method", "PUT");
    data.append("client_id", userID);
    data.append("status", "LOGOUT");
    fetch(`${BASE_URL}${CHAT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (window.location.pathname !== "/messages-em" && loggedStatus === true) {
      setLoggedStatus(false);
      setOnlineStatusOff();
      setUseParamsForStartConversation("");
      setMessagesJobId("");
    }
  }, []);

  const readNotification = new FormData();
  readNotification.append("_method", "PUT");
  readNotification.append("client_id", userID);

  const checkNotificationAsRead = (id: string) => {
    if (id) {
      readNotification.append("notification_id_list", id);
      fetch(`${BASE_URL}${NOTIFICATIONS}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: readNotification,
      })
        .then((res) => res.json())
        .catch((err) => console.log(err));
    } else {
      fetch(`${BASE_URL}${NOTIFICATIONS}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: readNotification,
      })
        .then((res) => res.json())
        .catch((err) => console.log(err));
    }
  };

  const handleClickOnNotification = (not: { notification_id: string }) => {
    checkNotificationAsRead(not.notification_id);
    navigate("/notifications");
  };
  const handleMouseEnter = (props: string) => {
    if (props === "notifications") setShowNotificationsPopover(true);
  };
  const handleMouseLeave = (props: string) => {
    if (props === "notifications") setShowNotificationsPopover(false);
  };

  const statusData = new FormData();

  const changeStatus = (props: string) => {
    statusData.append("_method", "PUT");
    statusData.append("client_id", userID);
    statusData.append("status", "ONLINE");
    statusData.append("online_mode", props === "Online" ? "1" : "2");
    fetch(`${BASE_URL}${CHAT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: statusData,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const colourStyles = {
    control: () => ({
      border: "1px solid #2abeeb",
      "&:hover": {
        borderColor: "#1d90b3",
      },
      display: "flex",
      fontWeight: "600",
      letterSpacing: "0.05em",
      backgroundColor: "white",

      padding: "0rem",
      width: "120px",
      paddingLeft: "0.5rem",
      // paddingRight: "0.8rem",
      borderRadius: "100px",
      height: "40px",
    }),
  };

  useEffect(() => {
    const checkIfClickedOutsideJobsDropdown = (e: any) => {
      if (
        isJobsOpen &&
        showJobsDropdown &&
        jobsRef.current &&
        !jobsRef.current.contains(e.target)
      ) {
        setShowJobsDropdown(false);
        setIsJobsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutsideJobsDropdown);
    return () => {
      document.removeEventListener(
        "mousedown",
        checkIfClickedOutsideJobsDropdown
      );
    };
  }, [showJobsDropdown]);

  useEffect(() => {
    const checkIfClickedOutsideTalentDropdown = (e: any) => {
      if (
        isTalentOpen &&
        showTalentDropdown &&
        talentRef.current &&
        !talentRef.current.contains(e.target)
      ) {
        setShowTalentDropdown(false);
        setIsTalentOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutsideTalentDropdown);
    return () => {
      document.removeEventListener(
        "mousedown",
        checkIfClickedOutsideTalentDropdown
      );
    };
  }, [showTalentDropdown]);

  useEffect(() => {
    const checkIfClickedOutsideNotificationDropdown = (e: any) => {
      if (
        showNotifications &&
        notificationRef.current &&
        !notificationRef.current.contains(e.target)
      ) {
        setShowNotifications(false);
      }
    };
    document.addEventListener(
      "mousedown",
      checkIfClickedOutsideNotificationDropdown
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        checkIfClickedOutsideNotificationDropdown
      );
    };
  }, [showNotifications]);

  //To get the inner width of the window, and close the nav bar mobile if it happens to be open

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.innerWidth > 989) setOpen(false);
  }, [windowSize]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

 

  return (
    <>
      <div className="bg-[white] border-b border-blue-200 items-center w-full">
        <div className="absolute top-0 left-0 mb-24 w-full bg-white border-b border-gray-200 z-40">
          <div className="flex laptop:hidden w-full px-12 mx-auto justify-between h-[80px] items-center">
            <LogoTwo
              className="h-11 cursor-pointer"
              onClick={() => {
                setSelectedTab("dashboard");
                if (profileType === "employer") {
                  navigate("/dashboard");
                  setOpen(false);
                } else {
                  navigate("/dashboard-worker");
                  setOpen(false);
                }
              }}
            />
            <Hamburger toggled={isOpen} toggle={setOpen} size={28} />
          </div>
        </div>
        <div className="laptop:!flex hidden w-full px-12 mx-auto justify-between h-[80px] items-center ">
          <div className="flex items-center gap-12">
            <LogoTwo
              className="h-14 cursor-pointer"
              onClick={() => {
                setSelectedTab("dashboard");
                if (profileType === "employer") {
                  navigate("/dashboard");
                  setOpen(false);
                } else {
                  navigate("/dashboard-worker");
                  setOpen(false);
                }
              }}
            />
            <div className="flex gap-4 font-bold tracking-wider text-[15px] mt-2">
              <div className="relative" ref={jobsRef}>
                <p
                  className={`cursor-pointer ${
                    selectedTab === "dashboard"
                      ? "text-azulBonico"
                      : "hover:text-azulBonico"
                  } relative`}
                  onClick={() => {
                    setShowJobsDropdown(!showJobsDropdown);
                    setIsJobsOpen(!isJobsOpen);
                  }}
                >
                  {t("employer.leftbar.menu.item1.title")}
                </p>
                {showJobsDropdown === true && isJobsOpen === true && (
                  <ul
                    className={` absolute top-10 z-40 -left-2 py-[10px] bg-white text-start border  rounded w-[200px]`}
                  >
                    <li
                      className="px-2 py-1 cursor-pointer hover:bg-[#f7f4f4]"
                      onClick={() => {
                        setSelectedTab("dashboard");
                        if (profileType === "employer") {
                          navigate("/dashboard");
                          setOpen(false);
                        } else {
                          navigate("/dashboard-worker");
                          setOpen(false);
                        }
                      }}
                    >
                      {t("employer.leftbar.menu.item1.subitem1.title")}
                    </li>
                    <li
                      className="px-2 py-1 cursor-pointer hover:bg-[#f7f4f4]"
                      onClick={() => {
                        setSelectedTab("dashboard");
                        navigate("/all-jobs");
                        setOpen(false);
                      }}
                    >
                      {t("employer.leftbar.menu.item1.subitem2.title")}
                    </li>

                    <li
                      className="px-2 py-1 cursor-pointer hover:bg-[#f7f4f4]"
                      onClick={() => {
                        setSelectedTab("dashboard");
                        navigate("/welcome-client");
                        setOpen(false);
                      }}
                    >
                      {t("employer.leftbar.menu.item1.subitem3.title")}
                    </li>
                  </ul>
                )}
                <div
                  className={`bg-white z-50 border-l-2 border-t-2 w-4 h-4 rotate-45 absolute -bottom-[23px] left-3 ${
                    showJobsDropdown === false ? "hidden" : "visible"
                  } `}
                ></div>
              </div>
              <div className="relative" ref={talentRef}>
                <p
                  className={`cursor-pointer ${
                    selectedTab === "talent"
                      ? "text-azulBonico"
                      : "hover:text-azulBonico"
                  } `}
                  onClick={() => {
                    setShowTalentDropdown(!showTalentDropdown);
                    setIsTalentOpen(!isTalentOpen);
                  }}
                >
                  Talent
                </p>
                {showTalentDropdown === true && isTalentOpen === true && (
                  <ul
                    className={`absolute top-10 z-40 py-[10px] bg-white text-start border rounded w-[200px]`}
                  >
                    <li
                      className="px-2 py-1 cursor-pointer hover:bg-[#f7f4f4]"
                      onClick={() => {
                        setSelectedTab("talent");
                        navigate("/my-hires");
                        setOpen(false);
                      }}
                    >
                      {t("employer.alljobs.HiresMessagesIcon")}
                    </li>
                  </ul>
                )}
                <div
                  className={`bg-white z-50 border-l-2 border-t-2 w-4 h-4 rotate-45 absolute -bottom-[23px] left-5 
              ${showTalentDropdown === false ? "hidden" : "visible"}
              `}
                />
              </div>
              <p
                className={`cursor-pointer ${
                  selectedTab === "messages"
                    ? "text-azulBonico"
                    : "hover:text-azulBonico"
                } `}
                onClick={() => {
                  setSelectedTab("messages");
                  navigate("/messages-em");
                  setOpen(false);
                }}
              >
                {t("employer.alljobs.MessagesIcon")}
              </p>
              {unreadMessages !== "0" && (
                <p className="flex mt-[1px] justify-center text-sm text-white font-bold bg-[#B91919] w-[22px] h-fit rounded-full border border-[#B91919]">
                  {unreadMessages}
                </p>
              )}
            </div>
          </div>
          <div className="flex gap-12 items-center">
            {window.location.pathname === "/messages-em" ? (
              <div className="text-sm font-bold tracking-wider">
                <Select
                  isSearchable={false}
                  closeMenuOnSelect={true}
                  styles={colourStyles}
                  options={statusOptions}
                  controlShouldRenderValue={true}
                  hideSelectedOptions={true}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={{
                    value: visibilityStatus?.value,
                    label: visibilityStatus?.label,
                  }}
                  onChange={(e: any) => {
                    setVisibilityStatus(e);
                    changeStatus(e.value);
                  }}
                />
              </div>
            ) : null}
            <div className="relative" ref={notificationRef}>
              {showNotifications === false ? (
                <Bell
                  className="h-6 -mr-8 w-fit cursor-pointer"
                  onMouseEnter={() => handleMouseEnter("notifications")}
                  onMouseLeave={() => handleMouseLeave("notifications")}
                  onClick={() => {
                    setShowNotifications(true);
                    setShowNotificationsPopover(false);
                  }}
                />
              ) : (
                <Bell
                  className="h-6 -mr-8 w-fit cursor-pointer"
                  fill={"#2abeeb"}
                  onClick={() => setShowNotifications(false)}
                />
              )}
              {notificationsData?.unread_notifications !== "0" && (
                <Circle
                  className="h-2 absolute top-1 left-[18px]"
                  fill={"#B91919"}
                />
              )}
              <div
                className={`inline-block absolute top-[42px] -left-[68px] z-10 font-light text-sm rounded-lg border border-gray-200 border dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 bg-white

              ${showNotificationsPopover === false ? "hidden" : "visible"}
            `}
              >
                <div className="py-2 px-3 tracking-wider text-[15px] w-fit">
                  {t("worker.leftbar.menu.item5.title")}
                </div>
              </div>
              <div
                className={`bg-white border-l-2 border-t-2 z-20 w-4 h-4 rotate-45 absolute -bottom-[25px] left-0.5 ${
                  showNotificationsPopover === false ? "hidden" : "visible"
                } `}
              ></div>
              {showNotifications && (
                <>
                  <div
                    className={`inline-block w-max absolute top-[42px] -right-[48px]  z-10 font-light text-sm rounded-lg border border-gray-200 border bg-white`}
                  >
                    <div className="py-2 text-start tracking-wider text-[15px] w-fit">
                      {notifications &&
                        notifications
                          ?.slice(0, 3)
                          ?.map((not: NotificationsTypes, i) => {
                            return (
                              <div
                                key={not.notification_id}
                                className={` ${
                                  not.unread === "1" &&
                                  "bg-blancoGris font-bold"
                                } flex gap-5 items-start py-2 px-3 pr-6 text-sm cursor-pointer  border-b border-slate-200 hover:bg-[#f7f4f4]`}
                                onClick={() => handleClickOnNotification(not)}
                              >
                                {not.sender_title === "Admin" ? (
                                  <img
                                    src={flexi}
                                    alt="flexi"
                                    className="h-7 w-fit"
                                  />
                                ) : not.sender_title === "Employer" ? (
                                  <p className="border font-bold rounded-full text-xs border-azulBonico text-azulBonico h-[30px] w-[30px] p-1 flex justify-center items-center">
                                    {not.img_profile}
                                  </p>
                                ) : (
                                  <img
                                    src={`${BASE_URL_IMG}${not.img_profile}`}
                                    alt="worker"
                                    className="w-[32px] h-[32px]"
                                  />
                                )}
                                <div className="flex flex-col">
                                  <p className={`w-[220px]`}>
                                    {not.client_fullname}
                                  </p>
                                  <p>{not.detail.toLowerCase()}</p>
                                  <p className="italic whitespace-nowrap">
                                    {not?.job_title?.length > 30
                                      ? not?.job_title?.slice(0, 30) + "..."
                                      : not.job_title}
                                  </p>
                                  <p className="text-xs text-slate-400">
                                    {not.created.includes("day")
                                      ? not.created.split(" ")[0] +
                                        " " +
                                        not.created.split(" ")[1]
                                      : not.created}{" "}
                                    ago
                                  </p>
                                </div>
                                <div className="relative h-full w-full"></div>
                              </div>
                            );
                          })}
                      <p
                        className="text-sm pt-2 text-azulBonico hover:text-azulOscuro hover:bg-[#f7f4f4] cursor-pointer font-bold rounded py-2 px-3"
                        onClick={() => {
                          checkNotificationAsRead("");
                          navigate("/notifications");
                          setOpen(false);

                          setShowNotifications(false);
                        }}
                      >
                        {t("worker.header.see_notifications")}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`bg-white border-l-2 border-t-2 z-20 w-4 h-4 rotate-45 absolute -bottom-[25px] left-0.5 `}
                  ></div>
                </>
              )}
            </div>
            <Navbar>
              <NavItem visibilityStatus={visibilityStatus}>
                <DropdownMenu setOnlineStatusOff={setOnlineStatusOff} />
              </NavItem>
            </Navbar>
          </div>
        </div>

        {isOpen && (
          <NavBarBurger
            setOnlineStatusOff={setOnlineStatusOff}
            setOpen={setOpen}
          />
        )}
      </div>
    </>
  );
}

export default HeaderUser;
