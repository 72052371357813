import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useAtom } from "jotai";
import { Spiral as Hamburger } from "hamburger-react";

import "../HeaderUser/HeaderUser.css";
import {
  BASE_URL,
  BASE_URL_IMG,
  CHAT,
  NOTIFICATIONS,
} from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import Navbar from "../HeaderUser/Navbar";
import NavItem from "../HeaderUser/NavItem";
import DropdownMenu from "../HeaderUser/DropdownMenu";
import {
  allNotifications,
  isWorkerOnline,
  myCurrentPage,
  notificationsState,
  numberOffersPerPage,
  onlineStatusMode,
  selectedTabHeaderWorker,
  showSavedJobsWorker,
} from "../../Atoms/jotaiAtoms";
import { ReactComponent as Bell } from "../../assets/img/bell-blank.svg";
import { ReactComponent as Circle } from "../../assets/img/circle.svg";
import { ReactComponent as LogoTwo } from "../../assets/img/logo_2.svg";
import flexi from "../../assets/img/flexihours.png";
import NavBarBurger from "../SmallDevices/NavBarBurger";
import { NotificationsTypes } from "src/types/types";
import { useTranslation } from "react-i18next";
import { useGetNotifications } from "src/Hooks/useGetNotifications";
import { userProfileStore } from "src/Routing/useProfileStore";

export const colourStyles = {
  control: () => ({
    border: "1px solid #2abeeb",
    "&:hover": {
      borderColor: "#1d90b3",
    },
    display: "flex",
    fontWeight: "600",
    constterSpacing: "0.05em",
    backgroundColor: "white",

    padding: "0rem",
    width: "120px",
    paddingLeft: "0.5rem",
    // paddingRight: "0.8rem",
    borderRadius: "100px",
    height: "40px",
  }),
};

const statusOptions = [
  {
    value: "Online",
    label: (
      <div className="flex items-center gap-4">
        <Circle className="h-3  fill-green-500" />
        Online
      </div>
    ),
  },
  {
    value: "Invisible",
    label: (
      <div className="flex items-center gap-4">
        <Circle className="h-3 fill-slate-400" />
        Invisible
      </div>
    ),
  },
];
function HeaderWorker() {
  const { t } = useTranslation();
  const [visibilityStatus, setVisibilityStatus] = useAtom(onlineStatusMode);

  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [showWorkDropdown, setShowWorkDropdown] = useState(false);
  const [showJobsDropdown, setShowJobsDropdown] = useState(false);
  const [showEye, setShowEye] = useState(-1);
  const [showNotificationsPopover, setShowNotificationsPopover] =
    useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [isJobsOpen, setIsJobsOpen] = useState(false);
  const [isWorkOpen, setIsWorkOpen] = useState(false);
  const [notifications, setNotifications] = useAtom(allNotifications);
  const [notificationsData, setNotificationsData] = useAtom(notificationsState);
  const [isOpen, setOpen] = useState(false);
  const [showSavedJobs, setShowSavedJobs] = useAtom(showSavedJobsWorker);
  const [loggedStatus, setLoggedStatus] = useAtom(isWorkerOnline);
  const [selectedTab, setSelectedTab] = useAtom(selectedTabHeaderWorker);
  const [unreadMessages, setUnreadMessages] = useState("0");
  const [currentPageJobsOffer, setCurrentPageJobsOffer] =
    useAtom(myCurrentPage);
  const [offersPerPage, setOffersPerPage] = useAtom(numberOffersPerPage);
  // const [reset, setReset] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const navigate = useNavigate();
  const jobsRef = useRef<HTMLDivElement>(null);
  const workRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<HTMLDivElement>(null);
  const profileType = userProfileStore((s) => s.profileTypeToken);

  //Notifications

  const INTERVAL_NOTIFICATIONS = 10000;

  const { isNotificationsLoading, notificationData, refetch } =
    useGetNotifications();

  useEffect(() => {
    setNotifications(notificationData?.notifications);
    setNotificationsData(notificationData?.paginate?.[0]);
    setUnreadMessages(notificationData?.messages);
  }, [notificationData]);

  // const checkNotifications = () => {
  //   fetch(`${BASE_URL}notifications?client_id=${userID}&limit=10`, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${loggedInUserUid}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setNotifications(data.result?.notifications);
  //       setNotificationsData(data.result.paginate?.[0]);
  //       setUnreadMessages(data.result.messages);
  //       // if (data.result.paginate[0].unread_notifications !== "0") {
  //       //   setShowNotifications(true);
  //       // }
  //     })
  //     .catch((err) => console.log(err));
  // };
  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, INTERVAL_NOTIFICATIONS);
    return () => clearInterval(interval);
  }, []);

  //SET ONLINE STATUS FROM MESSAGES TAB OFF
  const data = new FormData();
  const setOnlineStatusOff = () => {
    data.append("_method", "PUT");
    data.append("client_id", userID);
    data.append("status", "LOGOUT");
    fetch(`${BASE_URL}${CHAT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // checkNotifications();
    if (window.location.pathname !== "/messages" && loggedStatus === true) {
      setLoggedStatus(false);
      setOnlineStatusOff();
    }
    if (window.location.pathname !== "/jobs-search") {
      setShowSavedJobs(false);
      setOffersPerPage(10);
      setCurrentPageJobsOffer(1);
    }
  }, []);

  const readNotification = new FormData();
  readNotification.append("_method", "PUT");
  readNotification.append("client_id", userID);

  const checkNotificationAsRead = (id: string) => {
    if (id) {
      readNotification.append("notification_id_list", id);
      fetch(`${BASE_URL}${NOTIFICATIONS}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: readNotification,
      })
        .then((res) => res.json())
        .catch((err) => console.log(err));
    } else {
      fetch(`${BASE_URL}${NOTIFICATIONS}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: readNotification,
      })
        .then((res) => res.json())
        .catch((err) => console.log(err));
    }
  };

  const handleClickOnNotification = (not: { notification_id: string }) => {
    checkNotificationAsRead(not.notification_id);
    navigate("/notifications");
  };

  const handleMouseEnter = (props: string) => {
    if (props === "notifications") {
      setShowNotificationsPopover(true);
    }
  };

  const handleMouseLeave = (props: string) => {
    if (props === "notifications") {
      setShowNotificationsPopover(false);
    }
  };

  //CHANGE STATUS CHAT VISIBILITY
  const statusData = new FormData();
  const changeStatus = (props: string) => {
    statusData.append("_method", "PUT");
    statusData.append("client_id", userID);
    statusData.append("status", "ONLINE");
    statusData.append("online_mode", props === "Online" ? "1" : "2");
    fetch(`${BASE_URL}${CHAT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: statusData,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const checkIfClickedOutsideWorkDropdown = (e: any) => {
      if (
        isWorkOpen &&
        showWorkDropdown &&
        workRef.current &&
        !workRef.current.contains(e.target)
      ) {
        setIsWorkOpen(false);
        setShowWorkDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutsideWorkDropdown);
    return () => {
      document.removeEventListener(
        "mousedown",
        checkIfClickedOutsideWorkDropdown
      );
    };
  }, [showWorkDropdown]);
  useEffect(() => {
    const checkIfClickedOutsideJobsDropdown = (e: any) => {
      if (
        isJobsOpen &&
        showJobsDropdown &&
        jobsRef.current &&
        !jobsRef.current.contains(e.target)
      ) {
        setIsJobsOpen(false);
        setShowJobsDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutsideJobsDropdown);
    return () => {
      document.removeEventListener(
        "mousedown",
        checkIfClickedOutsideJobsDropdown
      );
    };
  }, [showJobsDropdown]);
  useEffect(() => {
    const checkIfClickedOutsideNotificationDropdown = (e: any) => {
      if (
        showNotifications &&
        notificationRef.current &&
        !notificationRef.current.contains(e.target)
      ) {
        setShowNotifications(false);
      }
    };
    document.addEventListener(
      "mousedown",
      checkIfClickedOutsideNotificationDropdown
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        checkIfClickedOutsideNotificationDropdown
      );
    };
  }, [showNotifications]);

  //To get the inner width of the window, and close the nav bar mobile if it happens to be open

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.innerWidth > 989) setOpen(false);
  }, [windowSize]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

 

  return (
    <>
      <div className="bg-[white]  border-b border-blue-200 items-center">
        <div className="absolute top-0 left-0 mb-24 w-full bg-white border-b border-gray-200 overflow-visible z-[1000]">
          <div className="flex laptop:hidden w-full text-center px-12 mx-auto justify-between h-[80px] items-center">
            <LogoTwo
              className="h-9 cursor-pointer"
              onClick={() => {
                if (window.location.pathname !== "/dashboard") {
                  if (profileType === "employer") {
                    setOpen(false);
                    navigate("/dashboard");
                  } else {
                    setOpen(false);
                    navigate("/dashboard-worker");
                  }
                } else if (window.location.pathname === "/dashboard") {
                  setOpen(false);
                  navigate("/");
                }
                // setSelectedTab("dashboard");
                // navigate("/find-work");
              }}
            />
            <Hamburger toggled={isOpen} toggle={setOpen} size={28} />
          </div>
        </div>

        <div className="laptop:!flex hidden w-full px-12 mx-auto justify-between h-[80px] items-center ">
          <div className="flex items-center gap-12">
            <LogoTwo
              className="h-14 cursor-pointer"
              onClick={() => {
                setSelectedTab("dashboard");
                if (profileType === "employer") {
                  navigate("/dashboard");
                  setOpen(false);
                } else {
                  navigate("/dashboard-worker");
                  setOpen(false);
                }
              }}
            />
            <div className="flex gap-4 font-bold tracking-wider text-[15px] mt-2">
              <div className="relative" ref={workRef}>
                <p
                  className={`cursor-pointer ${
                    selectedTab === "dashboard"
                      ? "text-azulBonico"
                      : "hover:text-azulBonico"
                  }  relative`}
                  onClick={() => {
                    setShowWorkDropdown(!showWorkDropdown);
                    setIsWorkOpen(!isWorkOpen);
                  }}
                >
                  {t("worker.leftbar.menu.item1.subitem1.title")}
                </p>
                {showWorkDropdown === true && isWorkOpen === true && (
                  <ul
                    className={` absolute top-10 z-40 -left-2 py-[10px] bg-white text-start border  rounded w-[200px]`}
                  >
                    <li
                      className="px-2 py-1 cursor-pointer hover:bg-[#f7f4f4]"
                      onClick={() => {
                        setSelectedTab("dashboard");
                        if (profileType === "employer") {
                          setOpen(false);
                          navigate("/dashboard");
                        } else {
                          setOpen(false);
                          navigate("/dashboard-worker");
                        }
                      }}
                    >
                      {t("worker.leftbar.menu.item1.subitem1.title")}
                    </li>
                    <li
                      className="px-2 py-1 cursor-pointer hover:bg-[#f7f4f4]"
                      onClick={() => {
                        setOpen(false);
                        setShowSavedJobs(true);
                        setSelectedTab("dashboard");
                        navigate("/jobs-search");
                      }}
                    >
                      {t("worker.leftbar.menu.item1.subitem2.title")}
                    </li>

                    <li
                      className="px-2 py-1 cursor-pointer hover:bg-[#f7f4f4]"
                      onClick={() => {
                        setOpen(false);
                        setSelectedTab("dashboard");
                        navigate("/profile");
                      }}
                    >
                      {t("worker.leftbar.menu.item1.subitem3.title")}
                    </li>
                  </ul>
                )}
                <div
                  className={`bg-white z-50 border-l-2 border-t-2 w-4 h-4 rotate-45 absolute -bottom-[23px] left-3 ${
                    showWorkDropdown === false ? "hidden" : "visible"
                  } `}
                ></div>
              </div>
              <div className="relative" ref={jobsRef}>
                <p
                  className={`cursor-pointer ${
                    selectedTab === "jobs"
                      ? "text-azulBonico"
                      : "hover:text-azulBonico"
                  }`}
                  onClick={() => {
                    setShowJobsDropdown(!showJobsDropdown);
                    setIsJobsOpen(!isJobsOpen);
                  }}
                >
                  {t("worker.leftbar.menu.item2.subitem1.title")}
                </p>
                {showJobsDropdown === true && isJobsOpen === true && (
                  <ul
                    className={`absolute  top-10 z-40 py-[10px] bg-white text-start border rounded w-[200px]`}
                  >
                    <li
                      className="px-2 py-1 cursor-pointer hover:bg-[#f7f4f4]"
                      onClick={() => {
                        setOpen(false);
                        setSelectedTab("jobs");
                        navigate("/my-jobs");
                      }}
                    >
                      {t("worker.leftbar.menu.item2.subitem1.title")}
                    </li>
                    <li
                      className="px-2 py-1 cursor-pointer hover:bg-[#f7f4f4]"
                      onClick={() => {
                        setOpen(false);
                        setSelectedTab("jobs");
                        navigate("/applied-jobs");
                      }}
                    >
                      {t("worker.leftbar.menu.item2.title")}
                    </li>
                  </ul>
                )}
                <div
                  className={`bg-white z-50 border-l-2 border-t-2 w-4 h-4 rotate-45 absolute -bottom-[23px] left-5 ${
                    showJobsDropdown === false ? "hidden" : "visible"
                  } `}
                ></div>
              </div>
              {/* <p className="cursor-pointer hover:text-azulBonico">Stats</p> */}
              <div className="flex gap-1 items-center">
                <button
                  onClick={() => {
                    setOpen(false);
                    setSelectedTab("messages");
                    navigate("/messages");
                  }}
                >
                  <p
                    className={`cursor-pointer ${
                      selectedTab === "messages"
                        ? "text-azulBonico"
                        : "hover:text-azulBonico"
                    } `}
                  >
                    {t("worker.leftbar.menu.item3.title")}
                  </p>
                  {unreadMessages !== "0" && (
                    <p className="flex mt-[1px] justify-center text-sm text-white font-bold bg-[#B91919] w-[22px] h-fit rounded-full border border-[#B91919]">
                      {unreadMessages}
                    </p>
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className=" flex gap-12 items-center">
            {
              window.location.pathname === "/messages" ? (
                <div className="text-sm font-bold tracking-wider cursor-pointer">
                  <Select
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    styles={colourStyles}
                    options={statusOptions}
                    controlShouldRenderValue={true}
                    hideSelectedOptions={true}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={{
                      value: visibilityStatus?.value,
                      label: visibilityStatus?.label,
                    }}
                    onChange={(e: any) => {
                      setVisibilityStatus(e);
                      changeStatus(e.value);
                    }}
                  />
                </div>
              ) : null
              // <div className="relative ">
              //   <input
              //     className="px-2 pl-14 rounded-full border-azulOscuro hover:border-azulBonico  border text-sm  w-full h-10 outline-none focus:border-azulOscuro transition duration-300"
              //     placeholder="Search..."
              //   ></input>
              //   <div className="cursor-pointer w-full">
              //     <Search className="h-4 absolute left-[1rem] top-[13px] cursor-pointer" />
              //     <ArrowDown className="h-2 absolute left-[2.3rem] top-[17px] cursor-pointer" />
              //   </div>
              // </div>
            }
            <div className="relative" ref={notificationRef}>
              {showNotifications === false ? (
                <Bell
                  className="h-6 -mr-8 w-fit cursor-pointer"
                  onMouseEnter={() => handleMouseEnter("notifications")}
                  onMouseLeave={() => handleMouseLeave("notifications")}
                  onClick={() => {
                    setShowNotifications(true);
                    setShowNotificationsPopover(false);
                  }}
                />
              ) : (
                <Bell
                  fill={"#2abeeb"}
                  className="h-6  -mr-8 w-fit cursor-pointer"
                  onClick={() => setShowNotifications(false)}
                />
              )}
              {(notificationsData?.unread_notifications != "0" ||
                notificationsData?.unread_notifications != 0) && (
                <Circle
                  className="h-2 absolute top-1 left-[18px]"
                  fill={"#B91919"}
                />
              )}

              <div
                className={`inline-block absolute top-[42px] -left-[68px] z-10 font-light text-sm rounded-lg border border-gray-200 border dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 bg-white

              ${
                showNotificationsPopover === false
                  ? "opacity-0 transition-opacity duration-300"
                  : " opacity-1  transition-opacity duration-300"
              }
            `}
              >
                <div className="py-2 px-3 tracking-wider text-[15px] w-fit">
                  {t("worker.leftbar.menu.item5.title")}
                </div>
              </div>
              <div
                className={`bg-white border-l-2 border-t-2 z-20 w-4 h-4 rotate-45 absolute -bottom-[25px] left-0.5 ${
                  showNotificationsPopover === false
                    ? "opacity-0 transition-opacity duration-300"
                    : " opacity-1  transition-opacity duration-300"
                } `}
              ></div>
              {showNotifications && (
                <>
                  <div
                    className={`inline-block w-max absolute top-[42px] -right-[48px] z-10 font-light text-sm rounded-lg border border-gray-200 border dark:text-gray-400  dark:border-gray-600 dark:bg-gray-800 bg-white`}
                  >
                    <div className="py-2 text-start tracking-wider text-[15px] w-fit">
                      {notifications &&
                        notifications
                          ?.slice(0, 3)
                          ?.map((not: NotificationsTypes, i) => {
                            return (
                              <div
                                key={not.notification_id}
                                className={` ${
                                  not.unread === "1" &&
                                  "bg-blancoGris font-bold"
                                } flex gap-5 items-start py-2 px-3 pr-6 text-sm cursor-pointer hover:text-azulBonico border-b border-slate-200 hover:bg-[#f7f4f4]`}
                                // onMouseEnter={() => {
                                //   if (not.unread === "1") setShowEye(i);
                                // }}
                                // onMouseLeave={() => {
                                //   if (not.unread === "1") setShowEye(-1);
                                // }}
                                onClick={() => handleClickOnNotification(not)}
                              >
                                {not.sender_title === "Admin" ? (
                                  <img
                                    src={flexi}
                                    alt="flexi"
                                    className="h-7 w-fit"
                                  />
                                ) : not.sender_title === "Employer" ? (
                                  <p className="border font-bold rounded-full text-xs border-azulBonico text-azulBonico h-[30px] w-[30px] p-1 flex justify-center items-center">
                                    {not.img_profile}
                                  </p>
                                ) : (
                                  <img
                                    src={`${BASE_URL_IMG}${not.img_profile}`}
                                    alt="worker"
                                    className="w-[32px] h-[32px]"
                                  />
                                )}
                                <div className="flex flex-col">
                                  <div className="relative h-full w-full">
                                    <p className={`w-[220px]`}>
                                      {/* {not.client_fullname} */}
                                      {not.client_fullname.split(" ")[0]}{" "}
                                      {not.client_fullname?.slice(0, 1)}.
                                    </p>
                                    <p>{not.detail.toLowerCase()}</p>
                                    <p className="italic whitespace-nowrap">
                                      {not.job_title.length > 30
                                        ? not?.job_title?.slice(0, 30) + "..."
                                        : not.job_title}
                                    </p>
                                    <p className="text-xs text-slate-400">
                                      {not.created.includes("day")
                                        ? not.created.split(" ")[0] +
                                          " " +
                                          not.created.split(" ")[1]
                                        : not.created}{" "}
                                      ago
                                    </p>
                                    {/* {showEye === i && (
                                    <Eye className="absolute h-3 w-fit top-1 right-0" />
                                  )} */}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      <button
                        onClick={() => {
                          setOpen(false);
                          checkNotificationAsRead("");
                          navigate("/notifications");
                          setShowNotifications(false);
                        }}
                      >
                        <p className="text-sm text-azulBonico hover:text-azulOscuro hover:bg-[#f7f4f4] cursor-pointer font-bold rounded py-2 px-3">
                          {t("worker.header.see_notifications")}
                        </p>
                      </button>
                    </div>
                  </div>
                  <div
                    className={`bg-white border-l-2 border-t-2 z-10 w-4 h-4 rotate-45 absolute -bottom-[25px] left-0.5 `}
                  ></div>
                </>
              )}
            </div>
            <Navbar>
              <NavItem visibilityStatus={visibilityStatus}>
                <DropdownMenu setOnlineStatusOff={setOnlineStatusOff} />
              </NavItem>
            </Navbar>
          </div>
        </div>
        {isOpen && (
          <NavBarBurger
            setOnlineStatusOff={setOnlineStatusOff}
            setOpen={setOpen}
          />
        )}
      </div>
    </>
  );
}

export default HeaderWorker;
